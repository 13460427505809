import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp } from '@fortawesome/pro-regular-svg-icons'
import { VStack, Center, Text } from '@chakra-ui/react'

type Props = {
  buttonTitle: string
}

const FilePlaceholder: FC<Props> = ({ buttonTitle }) => {
  return (
    <VStack
      borderWidth={0.5}
      w='56'
      h='32'
      rounded={'base'}
      _hover={{ bg: 'gray.50', cursor: 'pointer' }}
      align='center'
      justify={'center'}
      outline='none'
    >
      <Center w={6} h={6} rounded='base' bg='gray.100' color='gray.600'>
        <FontAwesomeIcon icon={faCloudArrowUp} fontSize='0.6em' />
      </Center>
      <VStack spacing={0}>
        <Text fontSize={'xs'} color='gray.500'>
          <Text as='span' color='blue.400'>
            Click to upload
          </Text>{' '}
          or drag and drop
        </Text>
        <Text fontSize={'xs'} color='gray.400'>
          {buttonTitle}
        </Text>
      </VStack>
    </VStack>
  )
}
export default FilePlaceholder
