import { FC, useMemo } from 'react'
import _ from 'lodash'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import { useSelector } from 'model/hooks'
import { getAppLoaded } from 'model/selectors/base'
import NavWrapper from 'navigation/NavWrapper'
import EditTour from 'pages/EditTour'
import SignIn from 'pages/auth/SignIn'
import SignUp from 'pages/auth/SignUp'
import Loading from 'pages/Loading'
import Tours from 'pages/Tours'
import DashboardWrapper from 'pages/dashboard/DashboardWrapper'
import AuthPageLayout from 'pages/auth/AuthPageLayout'
import ForgotPassword from 'pages/auth/ForgotPassword'
import TourModel from 'pages/TourModel'
import OnboardingLayout from 'pages/onboarding/OnboardingLayout'
import OnboardingMPUrl from 'pages/onboarding/OnboardingMPUrl'
import OnboardingUInfo from 'pages/onboarding/OnboardingUInfo'
import OnboardingDesign from 'pages/onboarding/OnboardingDesign'
import SettingsLayout from 'pages/settings/SettingsLayout'
import SettingsAccount from 'pages/settings/SettingsAccount'
import SettingsProfile from 'pages/settings/SettingsProfile'
import Team from 'pages/settings/Team'
import Billing from 'pages/settings/Billing'

const AppRouter: FC<{}> = () => {
  const authData = useSelector(state => state.authData)
  const appLoaded = useSelector(getAppLoaded)

  const publicRoutes = (
    <>
      <Route path='/auth' element={<AuthPageLayout />}>
        <Route path='signin' element={<SignIn />} />
        <Route path='signup' element={<SignUp />} />
        <Route path='reset_password' element={<ForgotPassword />} />
      </Route>
    </>
  )

  const onboardingRoutes = (
    <Route path='/onboarding' element={<OnboardingLayout />}>
      <Route path='mpurl' element={<OnboardingMPUrl />} />
      <Route path='uinfo' element={<OnboardingUInfo />} />
      <Route path='design' element={<OnboardingDesign />} />
      <Route path='' element={<OnboardingMPUrl />} />
    </Route>
  )

  const privateRoutes = useMemo(() => {
    if (_.isNil(authData) || !_.isNil(appLoaded)) {
      return <Route path='*' element={<Loading />} />
    } else {
      return (
        <>
          <Route path='/' element={<DashboardWrapper />}>
            {/* <Route path='/designs' element={<Designs />} /> */}
            <Route path='/settings' element={<SettingsLayout />}>
              <Route path='account' element={<SettingsAccount />} />
              <Route path='profile' element={<SettingsProfile />} />
              <Route path='team' element={<Team />} />
              <Route path='billing' element={<Billing />} />
              <Route
                path=''
                element={<Navigate replace to='/settings/account' />}
              />
            </Route>
            <Route path='/tours' element={<Tours />} />
            <Route path='/tour/:tourModelId' element={<TourModel />} />
            <Route path='/' element={<Navigate replace to='/tours' />} />
            <Route path='*' element={<Navigate replace to='/tours' />} />
          </Route>
          <Route path='/design/:tourId' element={<EditTour />} />
        </>
      )
    }
  }, [authData, appLoaded])

  return (
    <BrowserRouter basename='/'>
      <NavWrapper>
        <Routes>
          {publicRoutes}
          {onboardingRoutes}
          {privateRoutes}
        </Routes>
      </NavWrapper>
    </BrowserRouter>
  )
}

export default AppRouter
