// import _ from 'lodash'
import {
  SceneComponent,
  ComponentInteractionType
} from 'shared/components/SceneComponent'
import { Scene } from 'shared/bundle/sdk'
import { makeTransformContols } from './TransformControls'
import { MathUtils, Object3D } from 'three'
// import { Quaternion } from 'three'

export interface IMRotateControlInputs extends Record<string, unknown> {
  itemKey: string | null
  size: number
  modelRotate: number
}

class RotateControl extends SceneComponent {
  private transformControls: any = null
  private collider = null
  private o: Object3D | null = null
  // private quaternionStart: Quaternion = undefined

  inputs: IMRotateControlInputs = {
    itemKey: null,
    size: 1,
    modelRotate: 0
  }

  events = {
    [ComponentInteractionType.DRAG]: true,
    [ComponentInteractionType.HOVER]: false,
    [ComponentInteractionType.CLICK]: false,
    [ComponentInteractionType.ROTATE_END]: true
  }

  onMouseDown = () => {
    console.log('onMouseDown')
    // quaternionStart =
  }

  onMouseUp = () => {
    // console.log('onMouseUp')
    this.notify(ComponentInteractionType.ROTATE_END)
  }

  onRotationChanged = (r: any) => {
    console.log('onRotationChanged v', r)
    const scene = this.context.scene
    if (this.inputs.itemKey) {
      const itemNodeName = this.inputs.itemKey.substring(
        4,
        this.inputs.itemKey.length
      )
      console.log(
        'this.inputs.itemKey',
        this.inputs.itemKey,
        'itemNodeName',
        itemNodeName
      )
      const itemNode = scene.getObjectByName(itemNodeName)
      if (itemNode && this.o) {
        console.log('itemNode', itemNode)
        const boxObject = this.o as Object3D
        itemNode.setRotationFromEuler(boxObject.rotation)
        if (this.inputs.modelRotate) {
          itemNode.rotateY(MathUtils.degToRad(this.inputs.modelRotate))
        }
      }
    }
  }

  updateItemKey = () => {
    // FIXME: uncomment this and fix
    // const scene = this.context.scene
    // this.transformControls?.detach()
    // this.outputs.collider = null
    // if (this.inputs.itemKey) {
    //   console.log('scene', scene)
    //   const o = scene.getObjectByName(this.inputs.itemKey)
    //   console.log('search by item name', this.inputs.itemKey, 'res:', o)
    //   if (o) {
    //     console.log(
    //       'attach object to Transform Controls',
    //       this.transformControls,
    //       o
    //     )
    //     this.o = o
    //     this.transformControls.attach(o)
    //     this.outputs.collider = this.collider
    //     // console.log('tc position', this.tc?.position)
    //     // this.tc.addEventListener('mouseDown', this.onMouseDown)
    //     this.transformControls.addEventListener('mouseUp', this.onMouseUp)
    //     this.transformControls.addEventListener(
    //       'objectChange',
    //       this.onRotationChanged
    //     )
    //   } else {
    //     // this.tc?.detach()
    //   }
    // } else {
    //   // this.tc?.detach()
    // }
  }

  onInputsUpdated = (oldInputs: IMRotateControlInputs) => {
    if (oldInputs.itemKey !== this.inputs.itemKey) {
      this.updateItemKey()
    }

    if (oldInputs.size !== this.inputs.size) {
      console.log('update size', this.inputs.size)
      this.transformControls.size = this.inputs.size
    }
  }

  onEvent = () => {}

  onDestroy = () => {
    this.outputs.objectRoot = null
    this.transformControls.dispose()
  }

  onInit = () => {
    const z = makeTransformContols(this.context.three)
    this.transformControls = new z.TransformControls(
      this.context.camera,
      this.context.renderer.domElement
    )
    this.transformControls.enabled = true
    this.transformControls.mode = 'rotate'
    this.transformControls.showX = false
    this.transformControls.showZ = false
    this.outputs.objectRoot = this.transformControls
    this.collider = this.transformControls.children[0]
    this.onInputsUpdated(this.inputs)

    // const THREE = this.context.three
    // const camera = this.context.camera as PerspectiveCamera
    // const THREE = this.context.three
    // console.log('camte')

    // const r = makeTransformContols(this.context.three)

    // this.tc = new r.TransformControls(camera, this.context.renderer.domElement)
    // this.outputs.objectRoot = this.tc
    // this.outputs.collider = this.tc.children[0]
    // this.tc.space = 'local'
    // this.tc.mode = 'rotate'
    // this.tc.showX = false
    // this.tc.showZ = false
    // this.tc.rotationSnap = Math.PI / 18
    // this.tc.size = 5
    // this.outputs.objectRoot = this.tc
    // this.outputs.collider = this.tc
    // console.log('tc', this.tc)
    // this.context.scene.add(this.tc)
  }

  // moveToPosition = async () => {
  //   console.log('moveToPosition', this.started)
  //   if (this.started) {
  //     this.inputs
  //       .moveTo('mode.inside' as Mode.Mode.INSIDE, {
  //         position: this.toPos,
  //         rotation: this.toRot,
  //         transition: 'transition.instant' as Mode.TransitionType.INSTANT
  //       })
  //       .then(() => {
  //         console.log('moved')
  //         // setTimeout(this.moveToPosition, 100)
  //       })
  //   }
  // }

  // onTick = () => {
  //   console.log('this.started', this.started, 'to', this.toPos, this.toRot)
  //   if (this.started) {
  //     this.inputs.moveTo('mode.inside' as Mode.Mode.INSIDE, {
  //       position: this.toPos,
  //       rotation: this.toRot,
  //       transition: 'transition.instant' as Mode.TransitionType.INSTANT
  //     })
  //   }
  // }
}

export const rotateControlType = 'mp.customRotateControl'
export const makeRotateControl = (): Scene.IComponent => {
  return new RotateControl()
}
