import {
  Alert,
  HStack,
  VStack,
  AlertTitle,
  AlertDescription,
  Box,
  CloseButton
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FC } from 'react'

type Props = {
  errorMessage: string
  onReset: () => void
}

const AuthGeneralError: FC<Props> = ({ errorMessage, onReset }) => {
  return (
    <Alert status='error' rounded={'md'} mb={8}>
      <HStack color='red.500' spacing={4}>
        <FontAwesomeIcon icon={faCircleExclamation} fontSize='1.5em' />
        <VStack align='flex-start' color='gray.700' spacing={1}>
          <AlertTitle>An error has occurred.</AlertTitle>
          <AlertDescription fontSize={'sm'} lineHeight={1.4}>
            {errorMessage}
          </AlertDescription>
        </VStack>
        <Box>
          <CloseButton color='gray.600' onClick={onReset} />
        </Box>
      </HStack>
    </Alert>
  )
}

export default AuthGeneralError
