import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getRoomTypes, getTemplates } from 'model/selectors/base'
import { DictT, TemplatesT } from 'shared/types/model'

export const getRoomTypesWithTemplates = createSelector(
  [getRoomTypes, getTemplates],
  (roomTypes, templates) => {
    const res: DictT<TemplatesT> = {}
    _.forEach(templates, t => {
      if (!_.has(res, t.roomTypeId)) {
        res[t.roomTypeId] = {
          roomType: roomTypes[t.roomTypeId],
          templates: {}
        }
      }
      res[t.roomTypeId].templates[t.id] = t
    })
    return res
  }
)
