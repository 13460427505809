import { FC } from 'react'
import { Stack, Text } from '@chakra-ui/react'

type Props = {
  title: string
  description: string
}

const SettingsCardTitle: FC<Props> = ({ title, description }) => {
  return (
    <Stack
      flex={1}
      direction='column'
      spacing='2'
      display={{ base: 'none', lg: 'block' }}
    >
      <Text fontSize='2xl' lineHeight='8' fontWeight='bold' color='gray.900'>
        {title}
      </Text>
      <Text fontSize='md' lineHeight='6' fontWeight='normal' color='gray.600'>
        {description}
      </Text>
    </Stack>
  )
}

export default SettingsCardTitle
