import { Stack, Button, HStack, Flex, Text } from '@chakra-ui/react'
import { signInWithGoogle } from 'controllers/auth'
import { ReactComponent as GoogleIcon } from 'shared/assets/logo_googleg_48dp.svg'
import { FC } from 'react'

type Props = {
  title?: string
}

const GoogleAuthButton: FC<Props> = ({ title = 'Sign in with Google' }) => {
  return (
    <Stack w='full' align={'center'} pt={5}>
      <Button
        variant='outline'
        w='full'
        maxW={'96'}
        onClick={() => signInWithGoogle()}
      >
        <HStack w='full'>
          <Flex flex={1} justify='center'>
            <GoogleIcon />
          </Flex>
          <Flex flex={2} justify='center'>
            <Text>{title}</Text>
          </Flex>
          <Flex flex={1} />
        </HStack>
      </Button>
    </Stack>
  )
}

export default GoogleAuthButton
