import {
  onSnapshot,
  collection,
  query,
  setDoc,
  doc,
  updateDoc,
  deleteField,
  where
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveTours } from 'model/actions'
import { TourSlotT, TourT } from 'shared/types/model'

export const fetchTours = (accountId: string) => {
  try {
    console.log('fetch tours accountId', accountId)
    const state = store.getState()
    const partnerId = _.get(state, 'partner.id', null)
    const q = query(
      collection(db, 'tours'),
      where('accountId', '==', accountId),
      where('deleted', '==', 0),
      where('tourModelDeleted', '==', 0),
      where('partnerId', '==', partnerId)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        console.log('tours received, amount', _.size(res))
        store.dispatch(receiveTours(res))
      },
      err => {
        console.log(`fetchTours error: ${err}`)
        Sentry.captureException(err)
      }
    )
    addListener('tours', unsubscribe)
  } catch (e) {
    console.log('fetchTours error', e)
    Sentry.captureException(e)
  }
}

export const dbCreateTour = async (tour: TourT) => {
  try {
    const state = store.getState()
    const partnerId = _.get(state, 'partner.id', null)
    const tourWithPartner = { ...tour, partnerId }
    console.log('createTour', tourWithPartner)
    const ref = doc(db, `tours/${tourWithPartner.id}`)
    setDoc(ref, _.omitBy(tourWithPartner, _.isUndefined))
    // @ts-ignore
    // eslint-disable-next-line no-undef
    StonlyWidget('identify', _.get(state, 'authData.uid'), {
      'design-complete': 'true'
    })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateTourSlot = async (
  tourId: string,
  tourSlotId: string,
  params: Partial<TourSlotT>
) => {
  try {
    const ref = doc(db, `tours/${tourId}`)
    const update = {
      [`slots.${tourSlotId}`]: params,
      updatedAt: _.now()
    }
    await updateDoc(ref, update)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbDeleteTourSlot = async (tourId: string, tourSlotId: string) => {
  try {
    const ref = doc(db, `tours/${tourId}`)
    const update = {
      [`slots.${tourSlotId}`]: deleteField()
    }
    await updateDoc(ref, update)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbDeleteAllItems = async (tourId: string) => {
  try {
    const ref = doc(db, `tours/${tourId}`)
    const update = {
      slots: deleteField(),
      updatedAt: _.now()
    }
    await updateDoc(ref, update)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateTourPath = async (
  tourId: string,
  path: string,
  value: any
) => {
  try {
    const ref = doc(db, `tours/${tourId}`)
    console.log('db update tour path', path, 'value', value)
    const update = {
      [path]: value,
      updatedAt: _.now()
    }
    await updateDoc(ref, update)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const updateTour = (tourId: string, update: object) => {
  try {
    const ref = doc(db, `tours/${tourId}`)
    updateDoc(ref, { ...update, updatedAt: _.now() })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbDeleteTour = (tourId: string) => {
  try {
    updateTour(tourId, { deleted: _.now() })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const handleShareTour = () => {
  try {
    const state = store.getState()
    const noTourAccountData = _.get(state, 'noTourAccount')
    if (!_.isNil(noTourAccountData)) {
      if (!_.get(noTourAccountData, 'shared')) {
        const accountId = _.get(state, 'account.id')
        updateDoc(doc(db, `noTourAccounts/${accountId}`), { shared: true })
      }
    }
  } catch (e) {
    Sentry.captureException(e)
  }
}
