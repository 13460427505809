const conf = {
  apiKey: 'AIzaSyD3QhSIc-atMEZFMCDJoVdkrqslCBXrK-0',
  authDomain: 'app.dev.upstager.co',
  databaseURL: 'https://upstager-dev.firebaseio.com',
  projectId: 'upstager-dev',
  storageBucket: 'upstager-dev.appspot.com',
  messagingSenderId: '817534177303',
  appId: '1:817534177303:web:e6e4ed1c028aedefec8c52',
  measurementId: 'G-MCE1BQ0F2K',
  applicationKey: '9wbhwezsa3eh1yg6a7akc8ssd',
  customerAppUrl: 'https://customer.dev.upstager.co',
  adminAppUrl: 'https://admin.dev.upstager.co',
  internalVersion: '5',
  isDev: true,
  backendUrl: 'https://us-central1-upstager-dev.cloudfunctions.net',
  userAppUrl: 'https://app.dev.upstager.co',
  designerAppUrl: 'https://designer.dev.upstager.co',
  sentryCustomerDSN:
    'https://2d081fe1778e4ee4ba0808daf7054c2b@o4505011503759360.ingest.sentry.io/4505011536330752',
  sentryDesignerDSN:
    'https://f2257e297d334dd9acbb4d641f748641@o4505011503759360.ingest.sentry.io/4505016607244288',
  sentryUserDSN:
    'https://03a704cf801346fdb8d1a3af017222bb@o4505011503759360.ingest.sentry.io/4505016671797248',
  sentryAdminDSN:
    'https://432c2a7c29204b26a36ca20113400a0a@o4505011503759360.ingest.sentry.io/4505016687591424'
}

export default conf
