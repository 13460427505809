import _ from 'lodash'
import { useEffect, FC, useRef, useMemo } from 'react'

import { MpSdk, Scene } from 'shared/bundle/sdk'
import { Vector3, Vector3Tuple, MathUtils, Euler, Vector2 } from 'three'
import { IOrientedBoxInputs, OrientedBox } from 'shared/components/OrientedBox'
import { DictT } from 'shared/types/model'
import { ComponentInteractionType } from 'shared/components/SceneComponent'

type Props = {
  id: string
  position?: Vector3
  rotation?: Vector3Tuple
  size?: Vector3Tuple
  onClick?: () => void
  showFrontSide?: boolean
  visible?: boolean
  canMove?: boolean
  onMove?: (toPosition: Vector3) => void
  sceneObject: MpSdk.Scene.IObject
  itemOffset: Vector3
  sdk: MpSdk
  showFloorLines?: boolean
  color?: string
}

const BoxSimple: FC<Props> = ({
  id,
  position = new Vector3(0, 0, 0),
  rotation = [0, 0, 0],
  size = [1, 1, 1],
  onClick,
  showFrontSide = false,
  visible = true,
  canMove = false,
  onMove = () => null,
  sceneObject,
  itemOffset,
  sdk,
  showFloorLines = false,
  color = 'white'
}) => {
  const nodesRef = useRef<DictT<MpSdk.Scene.INode>>({})
  const subscriptionsRef = useRef<DictT<MpSdk.ISubscription>>({})
  const componentRef = useRef<MpSdk.Scene.IComponent | null>(null)
  const transformControlsComponentRef = useRef<MpSdk.Scene.IComponent | null>(
    null
  )

  const nodePositionV3 = useMemo(() => {
    const p = position.clone()
    p.setY(position.y + size[1] / 2)
    // console.log('nodePositionV3', p)
    return p
  }, [size, position])

  useEffect(() => {
    if (!_.isNil(componentRef.current)) {
      const inputs = componentRef.current.inputs as
        | IOrientedBoxInputs
        | undefined
      if (inputs) {
        inputs.size = new Vector3(size[0], size[1], size[2])
      }
    }
  }, [size])

  useEffect(() => {
    if (!_.isNil(componentRef.current)) {
      const inputs = componentRef.current.inputs as
        | IOrientedBoxInputs
        | undefined
      if (inputs) {
        inputs.visible = visible
      }
    }

    if (!_.isNil(transformControlsComponentRef.current)) {
      const inputs = transformControlsComponentRef.current.inputs as
        | IOrientedBoxInputs
        | undefined
      if (inputs) {
        _.set(inputs, 'showX', visible && canMove)
        _.set(inputs, 'showZ', visible && canMove)
      }
    }
  }, [visible])

  useEffect(() => {
    if (!_.isNil(nodesRef.current.boxNode)) {
      const node = nodesRef.current.boxNode
      node.position.copy(nodePositionV3)
    }
  }, [nodePositionV3])

  useEffect(() => {
    if (!_.isNil(nodesRef.current.boxNode)) {
      const node = nodesRef.current.boxNode
      const eulerUpdatedRotation = new Euler(
        MathUtils.degToRad(rotation[0]),
        MathUtils.degToRad(rotation[1]),
        MathUtils.degToRad(rotation[2]),
        'XYZ'
      )
      node.quaternion.setFromEuler(eulerUpdatedRotation)
    }
    if (!_.isNil(componentRef.current)) {
      const oBox = componentRef.current as unknown as OrientedBox
      oBox.onUpdateEdgesAndLines()
    }
  }, [rotation])

  const addOnClickListener = (
    node: Scene.INode,
    component: Scene.IComponent
  ) => {
    if (onClick) {
      const evPath = sceneObject.addPath({
        // id: `box_click_${id}`,
        id: _.now().toString(),
        type: 'emit' as Scene.PathType.EMIT,
        node,
        component,
        property: ComponentInteractionType.CLICK
      })
      const spy = {
        path: evPath,
        onEvent: () => {
          onClick()
        }
      }
      sceneObject.spyOnEvent(spy)
    }
  }

  const handleDrag = (clientPosition: Vector2, itemNode: Scene.INode) => {
    sdk.Renderer.getWorldPositionData(clientPosition).then(data => {
      // console.log('worldPosition data', data)

      if (!_.isNil(nodesRef.current.boxNode) && data.position) {
        const node = nodesRef.current.boxNode
        const diff = Math.abs(nodePositionV3.y - size[1] / 2 - data.position.y)
        // console.log('diff', diff)
        if (diff < 0.1) {
          node.position.copy(
            new Vector3(
              data.position.x,
              data.position.y + size[1] / 2,
              data.position.z
            )
          )

          const p = new Vector3(
            data.position.x,
            data.position.y,
            data.position.z
          )
          p.add(itemOffset)
          itemNode.position.copy(p)
        }
      }
    })
  }

  const addTransformControls = (
    node: MpSdk.Scene.INode,
    component: Scene.IComponent
  ) => {
    const nIterator = sceneObject.nodeIterator()
    let itemNode: Scene.INode | undefined
    for (const n of nIterator) {
      if (n.id === 'item_preview') {
        itemNode = n
      }
    }

    if (itemNode) {
      const emitPathDrag = sceneObject.addPath({
        id: 'ev' + _.now().toString(),
        type: 'emit' as Scene.PathType.EMIT,
        node,
        component,
        property: ComponentInteractionType.DRAG
      })
      const dragSpy = {
        path: emitPathDrag,
        onEvent (eventData: any) {
          itemNode && handleDrag(eventData.input.clientPosition, itemNode)
        }
      }
      sceneObject.spyOnEvent(dragSpy)

      const emitPathDragEnd = sceneObject.addPath({
        id: 'ev_drag_end' + _.now().toString(),
        type: 'emit' as Scene.PathType.EMIT,
        node,
        component,
        property: ComponentInteractionType.DRAG_END
      })
      const dragEndSpy = {
        path: emitPathDragEnd,
        onEvent (eventData: any) {
          console.log('Drag end event', eventData)
          const toPosition = new Vector3(
            node.position.x,
            node.position.y - size[1] / 2,
            node.position.z
          )
          onMove(toPosition)
        }
      }
      sceneObject.spyOnEvent(dragEndSpy)
    }
  }

  useEffect(() => {
    const run = async () => {
      // console.log('box init', position)
      if (!_.isEmpty(nodesRef.current)) {
        _.forEach(nodesRef.current, n => {
          n.stop()
        })
      }
      // const node = sceneObject.addNode(`item-box-${id}`)
      const node = sceneObject.addNode()
      const params = {
        size: new Vector3().fromArray(size),
        showFrontSide,
        visible: false,
        color
      }
      const component: any = node.addComponent('mp.orientedBox', params)
      component.inputs.showFloorLines = showFloorLines
      component.emits = {
        [ComponentInteractionType.DRAG]: true,
        [ComponentInteractionType.DRAG_END]: true,
        [ComponentInteractionType.CLICK]: true
      }
      componentRef.current = component
      nodesRef.current.boxNode = node
      node.position.copy(nodePositionV3)
      node.name = id

      if (!_.isNil(componentRef.current)) {
        const inputs = componentRef.current.inputs as
          | IOrientedBoxInputs
          | undefined
        if (inputs) {
          setTimeout(() => {
            inputs.visible = visible
          }, 10)
        }
      }
      if (canMove) {
        addTransformControls(node, component)
      }

      addOnClickListener(node, component)
      if (rotation) {
        const eulerUpdatedRotation = new Euler(
          MathUtils.degToRad(rotation[0]),
          MathUtils.degToRad(rotation[1]),
          MathUtils.degToRad(rotation[2]),
          'XYZ'
        )
        node.quaternion.setFromEuler(eulerUpdatedRotation)
      }

      node.start()
    }

    run()

    return () => {
      if (!_.isNil(componentRef.current)) {
        const inputs = componentRef.current.inputs as
          | IOrientedBoxInputs
          | undefined
        if (inputs) {
          inputs.visible = false
        }
      }
      setTimeout(() => {
        _.forEach(nodesRef.current, n => n.stop())
        _.forEach(subscriptionsRef.current, s => s.cancel())
      }, 200)
    }
  }, [])

  return null
}

export default BoxSimple
