import { FC, useEffect, useState, useMemo, useRef } from 'react'
import _ from 'lodash'
import { MpSdk, Mode } from 'shared/bundle/sdk'
import { MathUtils, Vector3, Vector3Tuple } from 'three'
import SceneClickListener from 'shared/components/SceneClickListener'
import { ItemT, DictT, TourSlotT, SlotT } from 'shared/types/model'
import ItemsSet from 'shared/components/ItemsSet'
import Item from 'shared/components/Item'
import RotateControlSlider from 'shared/components/RotateControlSlider'
import AddingItemPanel from 'shared/components/AddingItemPanel'
import ModelIntersection from 'shared/components/ModelIntersection'

type Props = {
  setId: string
  items: DictT<ItemT>
  tourSlots: DictT<TourSlotT>
  sdk: MpSdk
  onAdd: (p: Vector3, rotation: number) => void
  viewMode: Mode.Mode
  slots: DictT<SlotT>
  onCancel: () => void
}

const AddingSet: FC<Props> = ({
  setId,
  sdk,
  onAdd,
  items,
  tourSlots,
  viewMode,
  onCancel
}) => {
  const poseRef = useRef<MpSdk.Camera.Pose>()
  const [slotPosition, setSlotPosition] = useState<Vector3 | undefined>()
  const slotPositionRef = useRef(slotPosition)
  const [sceneObject, setSceneObject] = useState<MpSdk.Scene.IObject | null>(
    null
  )
  // const pointerIntersectionUnsubscribeRef = useRef<MpSdk.ISubscription>(null)
  const [isAdded, setIsAdded] = useState(false)
  // const model = _.get(item, 'model')
  const viewModeRef = useRef(viewMode)
  const [rotation, setRotation] = useState(0)

  useEffect(() => {
    viewModeRef.current = viewMode
  }, [viewMode])

  const centerPosition = useMemo(() => {
    const pX = _.sum(_.map(tourSlots, ts => ts.position[0])) / _.size(tourSlots)
    const pZ = _.sum(_.map(tourSlots, ts => ts.position[2])) / _.size(tourSlots)
    return new Vector3(pX, 0, pZ)
  }, [tourSlots])

  const bottomPosition = useMemo(() => {
    return _.min(_.map(tourSlots, ts => ts.position[1]))
  }, [tourSlots])

  // console.log('slotPosition', slotPosition)

  useEffect(() => {
    const run = async () => {
      const [so]: Array<MpSdk.Scene.IObject> = await sdk.Scene.createObjects(1)
      setSceneObject(so)
    }
    run()
    return () => sceneObject?.stop()
  }, [])

  useEffect(() => {
    sdk.Camera.pose.subscribe(function (pose) {
      poseRef.current = pose
    })
  }, [])

  // const maxS = 2

  // const maxS = useMemo(() => {
  //   const { minX, maxX, minZ, maxZ } = _.reduce(
  //     _.values(tourSlots),
  //     (res, ts) => {
  //       const item = items[ts.itemId]
  //       const s = slots[item.kind]
  //       return {
  //         minX: _.min([
  //           ts.position[0] - centerPosition.x - s.size[0] / 2,
  //           res.minX
  //         ]),
  //         maxX: _.max([
  //           ts.position[0] - centerPosition.x + s.size[0] / 2,
  //           res.maxX
  //         ]),
  //         minZ: _.min([
  //           ts.position[2] - centerPosition.z - s.size[2] / 2,
  //           res.minZ
  //         ]),
  //         maxZ: _.max([
  //           ts.position[2] - centerPosition.z + s.size[2] / 2,
  //           res.maxZ
  //         ])
  //       }
  //     },
  //     { minX: 0, maxX: 0, minZ: 0, maxZ: 0 }
  //   )
  //   return _.max(_.map([minX, maxX, minZ, maxZ], Math.abs))
  //   // return _.max([slot.size[0], slot.size[2]]) || 1
  // }, [slots, tourSlots, centerPosition])

  // const multK = useMemo(() => {
  //   let res = 1.2
  //   if (maxS) {
  //     res = maxS
  //   }
  //   return res
  // }, [maxS])

  const updateSlotPosition = (p: Vector3 | null) => {
    // console.log('update slot position', p)
    setSlotPosition(p)
    slotPositionRef.current = p
  }

  // useEffect(() => {
  //   const unsubscribe = sdk.Pointer.intersection.subscribe(
  //     (intersectionData: MpSdk.Pointer.Intersection) => {
  //       if (poseRef.current) {
  //         switch (viewModeRef.current) {
  //           case sdk.Mode.Mode.INSIDE: {
  //             const intersP = new Vector3(
  //               intersectionData.position.x,
  //               intersectionData.position.y,
  //               intersectionData.position.z
  //             )
  //             const v = new Vector3(
  //               poseRef.current.position.x,
  //               intersectionData.position.y,
  //               poseRef.current.position.z
  //             )
  //             // console.log('cameraFloorPosition orig', v)
  //             v.sub(intersP)
  //             // console.log('cameraFloorPosition after sub', v)
  //             v.normalize()
  //             // console.log('cameraFloorPosition after normalization', v)
  //             // console.log('multK')
  //             v.multiplyScalar(multK)
  //             intersP.sub(v)
  //             updateSlotPosition(intersP)
  //             break
  //           }
  //           case sdk.Mode.Mode.FLOORPLAN: {
  //             // console.log('intersectionData', intersectionData)
  //             // console.log('poseRef.current', poseRef.current)
  //             const intersP = new Vector3(
  //               intersectionData.position.x,
  //               intersectionData.position.y,
  //               intersectionData.position.z - maxS / 2
  //             )
  //             console.log('intersP', intersP, 'object', intersectionData.object)
  //             updateSlotPosition(intersP)
  //             break
  //           }
  //         }
  //       }
  //     }
  //   )
  //   pointerIntersectionUnsubscribeRef.current = unsubscribe
  //   return () => unsubscribe.cancel()
  // }, [])

  // const onSceneClick = useCallback(() => {
  //   console.log('onSceneClick', slotPosition)
  //   if (slotPosition) {
  //     console.log('call onAddItem')
  //     onAdd(slotPosition)
  //   }
  // }, [slotPosition, onAdd])

  const onApply = () => {
    onAdd(slotPositionRef.current, rotation)
  }

  const sceneClickListener = () => {
    if (slotPositionRef.current) {
      // onAdd(slotPositionRef.current)
      setIsAdded(true)
      // pointerIntersectionUnsubscribeRef.current.cancel()
    }
  }

  const onIntersect = (p: Vector3 | null) => {
    updateSlotPosition(p)
  }

  if (sceneObject) {
    return (
      <>
        {isAdded && (
          <ItemsSet
            sdk={sdk}
            sceneObject={sceneObject}
            position={slotPosition}
            onMove={updateSlotPosition}
            viewMode={viewMode}
          />
        )}
        {isAdded && (
          <RotateControlSlider
            onRotated={v => setRotation(v)}
            modelRotate={rotation}
          />
        )}
        {_.map(tourSlots, ts => {
          const item = items[ts.itemId]
          if (!item) return null

          const uniqKey = 'add_item_' + setId + '_' + ts.id

          const modelRotation = () => {
            const model = item?.model
            // const res: Vector3Tuple = _.get(model, 'rotation', [0, 0, 0])
            const mr = _.get(model, 'rotation', [0, 0, 0])
            const rotationAxis = _.get(model, 'rotationAxis', 1)
            const res: Vector3Tuple = [mr[0], mr[1], mr[2]]
            res[rotationAxis] =
              res[rotationAxis] + (ts.rotation || 0) + rotation
            return res
          }

          const offsetV3 = () => {
            const model = item?.model
            const position = _.get(model, 'offset', [0, 0, 0])
            const v3 = new Vector3()
            v3.fromArray(position)
            return v3
          }

          const itemPosition = () => {
            const p = slotPosition
              ? slotPosition.clone()
              : new Vector3(1000, 0, 0)
            const inSetX = ts.position[0] - centerPosition.x
            const inSetZ = ts.position[2] - centerPosition.z
            const r = -MathUtils.degToRad(rotation)
            const rX = inSetX * Math.cos(r) - inSetZ * Math.sin(r)
            const rZ = inSetX * Math.sin(r) + inSetZ * Math.cos(r)
            p.setX(p.x + rX)
            p.setZ(p.z + rZ)
            p.setY(p.y + (ts.position[1] - bottomPosition))
            return p
          }

          return (
            <Item
              key={uniqKey}
              id={uniqKey}
              sdk={sdk}
              url={item.model.glb}
              // scale={model.scale}
              position={itemPosition()}
              offset={offsetV3()}
              rotation={modelRotation()}
              // onClick={onSlotClick}
              isSelected={false}
              sceneObject={sceneObject}
              showLoadingIndicator={true}
              viewMode={viewMode}
            />
          )
        })}
        {!isAdded && (
          <SceneClickListener sdk={sdk} onClick={sceneClickListener} />
        )}
        <AddingItemPanel
          onCancel={onCancel}
          onApply={onApply}
          canApply={isAdded}
        />
        {sdk && !isAdded && (
          <ModelIntersection
            sdk={sdk}
            enabled
            onIntersect={onIntersect}
            viewMode={viewMode}
          />
        )}
        {/* <ModelIntersection
          sdk={sdk}
          enabled={viewMode === sdk.Mode.Mode.FLOORPLAN}
        /> */}
      </>
    )
  } else {
    return null
  }
}

export default AddingSet
