import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getRequestsDesign } from 'model/selectors/base'
import { DictT, RequestDesignT } from 'shared/types/model'

export const getRequestsDesignByTourModelId = createSelector(
  [getRequestsDesign],
  rds => {
    const res: DictT<DictT<RequestDesignT>> = {}
    _.forEach(rds, rd => {
      _.set(res, [rd.tourModelId, rd.id], rd)
    })
    return res
  }
)
