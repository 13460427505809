import { FC } from 'react'
import {
  VStack,
  useBreakpointValue,
  Flex,
  Button,
  Heading,
  CloseButton,
  HStack,
  Text,
  Tabs,
  TabList,
  Tab
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { DictT } from 'shared/types/model'
import _ from 'lodash'
import { useSelector } from 'model/hooks'

type TabItemT = {
  label: string
  path: string
  tabIndex: number
}

const mobileTabs: DictT<TabItemT> = {
  '/settings/account': {
    label: 'Account',
    path: '/settings/account',
    tabIndex: 0
  },
  '/settings/profile': {
    label: 'Profile',
    path: '/settings/profile',
    tabIndex: 1
  },
  '/settings/team': { label: 'Team', path: '/settings/team', tabIndex: 2 },
  '/settings/billing': {
    label: 'Billing',
    path: '/settings/billing',
    tabIndex: 3
  }
  // '/settings/notifications': {
  //   label: 'Notifications',
  //   path: '/settings/notifications',
  //   tabIndex: 4
  // }
}

const desktopTabs = (withBilling: boolean) => {
  const res: {
    [key: string]: {
      label: string,
      path: string,
      tabIndex: number
    }
  } = {
    '/settings/account': {
      label: 'Account',
      path: '/settings/account',
      tabIndex: 0
    },
    '/settings/profile': {
      label: 'Profile',
      path: '/settings/profile',
      tabIndex: 1
    },
    '/settings/team': {
      label: 'Team',
      path: '/settings/team',
      tabIndex: 2
    }
  }
  if (withBilling) {
    res['/settings/billing'] = {
      label: 'Plan & Billing',
      path: '/settings/billing',
      tabIndex: 3
    }
  }
  return res
}

const SettingsNavBar: FC = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const unlimitedPlans = useSelector(state => state.partner?.unlimitedPlans || false)
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname

  const renderHeading = () => {
    if (isMobile) {
      return (
        <Flex
          alignItems='center'
          justifyContent='space-between'
          pl='4'
          pr={2}
          pt='6'
          pb='4'
          w='full'
        >
          <Button
            variant='outline'
            size='sm'
            leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            color='gray.700'
            onClick={() => navigate('/')}
          >
            Back
          </Button>
          <Heading size='sm'>Settings</Heading>
          <CloseButton
            variant='ghost'
            size='lg'
            onClick={() => navigate('/')}
          />
        </Flex>
      )
    } else {
      return (
        <HStack justify={'flex-start'} w='full' bg='gray.50' pl='8' pt='6'>
          <Text fontSize={'2xl'} fontWeight='bold' color='gray.700'>
            Settings
          </Text>
        </HStack>
      )
    }
  }

  const renderTabs = () => {
    if (isMobile) {
      return (
        <Tabs
          index={_.get(mobileTabs, [pathname, 'tabIndex'], -1)}
          overflow='auto'
          w='full'
        >
          <TabList
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            borderBottom='none'
            p='4'
          >
            {_.map(mobileTabs, item => (
              <Tab
                key={item.path}
                px='0'
                color='gray.600'
                onClick={() => navigate(item.path)}
              >
                {item.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      )
    } else {
      return (
        <Tabs
          index={_.get(desktopTabs(!unlimitedPlans), [pathname, 'tabIndex'])}
          w='full'
          bg='gray.50'
          py={4}
          px={8}
          borderColor='transparent'
        >
          <TabList>
            {_.map(desktopTabs(!unlimitedPlans), item => (
              <Tab
                key={item.path}
                color='gray.600'
                onClick={() => navigate(item.path)}
                _selected={{
                  color: 'blue.600',
                  borderBottomColor: 'blue.600',
                  borderBottomWidth: 2
                }}
              >
                {item.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      )
    }
  }

  return (
    <VStack w='full' spacing={0}>
      {renderHeading()}
      {renderTabs()}
    </VStack>
  )
}

export default SettingsNavBar
