import {
  MeshBasicMaterial,
  LineSegments,
  Object3D,
  BoxGeometry,
  Mesh,
  LineBasicMaterial,
  EdgesGeometry,
  AnimationMixer,
  AnimationClip,
  AnimationAction,
  Vector3,
  Line
} from 'three'
import _ from 'lodash'

import {
  SceneComponent,
  ComponentInteractionType,
  ISceneNode,
  ComponentOutput
} from 'shared/components/SceneComponent'
import { Scene } from 'shared/bundle/sdk'
export interface IInteractionEvent {
  type: ComponentInteractionType
  node: ISceneNode
  component: SceneComponent
}

export interface IOrientedBoxInputs extends Record<string, unknown> {
  size: { x: number; y: number; z: number }
  color: string
  visible: boolean
  opacity: number
  transitionTime: number
  lineOpacity: number
  lineColor: number
  showFrontSide: boolean
}

export type IOrientedBoxOutputs = {
  position: Vector3 | null
} & ComponentOutput

const makeMaterialOpacityClip = function (
  THREE: any,
  time: number,
  startOpacity: number,
  endOpacity: number,
  property: string
): AnimationClip {
  const track = new THREE.NumberKeyframeTrack(
    property,
    [0, time],
    [startOpacity, endOpacity]
  )
  return new THREE.AnimationClip(null, time, [track])
}

const playAnimation = function (
  THREE: any,
  mixer: AnimationMixer,
  clip: AnimationClip,
  root?: any
) {
  const action: AnimationAction = mixer.clipAction(clip, root)
  action.loop = THREE.LoopOnce
  action.clampWhenFinished = true
  action.play()
}

export class OrientedBox extends SceneComponent {
  private root: Object3D | null = null
  private box: Mesh | null = null
  private edges: LineSegments | null = null
  private floorLines: Array<Line> | null = null
  private boxMixer: AnimationMixer | null = null
  private clipVisible: AnimationClip | null = null
  private clipNotVisible: AnimationClip | null = null
  private edgesClipVisible: AnimationClip | null = null
  private edgesClipNotVisible: AnimationClip | null = null

  inputs: IOrientedBoxInputs = {
    size: { x: 1, y: 1, z: 1 },
    color: 'white',
    visible: true,
    // opacity: 0.05,
    opacity: 0.2,
    lineOpacity: 1,
    lineColor: 0xffffff,
    transitionTime: 0.4,
    showFrontSide: false,
    showFloorLines: false
  }

  outputs = {
    position: null
  } as IOrientedBoxOutputs

  events = {
    // [ComponentInteractionType.DRAG_BEGIN]: true,
    [ComponentInteractionType.DRAG]: true,
    [ComponentInteractionType.DRAG_END]: true,
    // [ComponentInteractionType.ROTATE]: true,
    // [ComponentInteractionType.ROTATE_END]: true,
    // [ComponentInteractionType.HOVER]: true,
    [ComponentInteractionType.CLICK]: true
  }

  onInit() {
    const THREE = this.context.three
    this.root = new THREE.Object3D()
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root

    if (this.inputs.visible) {
      const obj3D = (this.context.root as any).obj3D as Object3D
      const worldPos = new this.context.three.Vector3()
      obj3D.getWorldPosition(worldPos)
      this.outputs.position = worldPos
    } else {
      this.outputs.position = null
    }

    this.makeBox()

    // must be done after the box is created
    if (this.box) {
      this.boxMixer = new THREE.AnimationMixer(this.box)
    }
    this.clipVisible = makeMaterialOpacityClip(
      THREE,
      this.inputs.transitionTime,
      0,
      this.inputs.opacity,
      '.material[0].opacity'
    )
    this.clipNotVisible = makeMaterialOpacityClip(
      THREE,
      this.inputs.transitionTime,
      this.inputs.opacity,
      0,
      '.material[0].opacity'
    )
    this.edgesClipVisible = makeMaterialOpacityClip(
      THREE,
      this.inputs.transitionTime,
      0,
      this.inputs.lineOpacity,
      '.material.opacity'
    )
    this.edgesClipNotVisible = makeMaterialOpacityClip(
      THREE,
      this.inputs.transitionTime,
      this.inputs.lineOpacity,
      0,
      '.material.opacity'
    )
  }

  onEvent(interactionType: ComponentInteractionType, eventData: unknown): void {
    if (interactionType === ComponentInteractionType.CLICK) {
      this.notify(ComponentInteractionType.CLICK, {
        type: interactionType,
        node: this.context.root,
        component: this
      })
    }
    if (interactionType === ComponentInteractionType.HOVER) {
      this.notify(ComponentInteractionType.HOVER, {
        hover: (<{ hover: boolean }>eventData).hover
      })
    }
    if (interactionType === ComponentInteractionType.DRAG) {
      const event = eventData as DragEvent
      // console.log('drag event', event)
      this.notify(ComponentInteractionType.DRAG, event)
      // this.controlMap.onMouseMove(event.delta.x, event.delta.y);
    }

    if (interactionType === ComponentInteractionType.DRAG_END) {
      const event = eventData as DragEvent
      this.notify(ComponentInteractionType.DRAG_END, event)
    }
  }

  onDestroy() {
    const edges: any = this.edges
    const floorLines: any = this.floorLines
    this.root?.remove(edges)
    if (_.size(floorLines) > 0) {
      this.root?.remove(floorLines[0])
      this.root?.remove(floorLines[1])
    }
  }

  makeBox() {
    const THREE = this.context.three
    if (this.box && this.root) {
      this.root.remove(this.box)
      _.forEach(this.box.material, (m: MeshBasicMaterial) => {
        if (m) m.dispose()
      })
      const bg: BoxGeometry = this.box.geometry as BoxGeometry
      if (bg) bg.dispose()
      this.box = null
    }
    if (this.edges && this.root) {
      this.root.remove(this.edges)
      const lb: LineBasicMaterial = this.edges.material as LineBasicMaterial
      if (lb) lb.dispose()
      const eg: EdgesGeometry = this.edges.geometry as EdgesGeometry
      if (eg) eg.dispose()
      this.edges = null
    }

    const boxGeometry: BoxGeometry = new THREE.BoxGeometry(
      this.inputs.size.x,
      this.inputs.size.y,
      this.inputs.size.z
    )

    _.forEach(boxGeometry.groups, (group, index) => {
      boxGeometry.groups[index] = {
        ...group,
        materialIndex: index === 4 && this.inputs.showFrontSide ? 1 : 0
      }
    })
    // const boxMaterial: MeshBasicMaterial = new THREE.MeshBasicMaterial({
    //   color: this.inputs.color,
    //   opacity: this.inputs.opacity,
    //   depthWrite: false
    // })

    const boxMaterial = [
      new THREE.MeshBasicMaterial({
        color: this.inputs.color,
        colorWrite: true,
        opacity: this.inputs.visible ? this.inputs.opacity : 0,
        transparent: true,
        side: THREE.BackSide
      }),
      new THREE.MeshBasicMaterial({
        color: 'blue',
        opacity: this.inputs.visible ? this.inputs.opacity : 0,
        transparent: true,
        side: THREE.DoubleSide
      })
    ]

    // boxMaterial.transparent = true
    // boxMaterial.side = THREE.BackSide
    // boxMaterial.blending = THREE.AdditiveBlending

    this.box = new THREE.Mesh(boxGeometry, boxMaterial)
    if (this.root) {
      this.root.add(this.box)
      // this.root.position.copy(this.inputs.position)
    }

    const edgesGeometry = new THREE.EdgesGeometry(boxGeometry)
    this.edges = new THREE.LineSegments(
      edgesGeometry,
      new THREE.LineBasicMaterial({
        transparent: true,
        color: this.inputs.lineColor,
        linewidth: 1,
        opacity: this.inputs.visible ? this.inputs.lineOpacity : 0
      })
    )

    // put the edges object directly in the scene graph so that they dont intercept
    // raycasts. The edges object will need to be removed if this component is destroyed.
    // const obj3D = (this.context.root as any).obj3D as Object3D
    // const worldPos = new this.context.three.Vector3()
    // obj3D.getWorldPosition(worldPos)
    // this.edges.position.copy(worldPos)
    this.root?.add(this.edges)
    // this.edges.position.copy(this.inputs.position)
    // this.context.scene.add(this.edges)
    if (this.inputs.showFloorLines) {
      this.onUpdateFloorLines()
    }
  }

  onUpdateFloorLines() {
    const THREE = this.context.three
    const floorLines: any = this.floorLines
    if (_.size(floorLines) > 0) {
      this.root?.remove(floorLines[0])
      this.root?.remove(floorLines[1])
    }
    const material = new THREE.LineBasicMaterial({
      color: 0xffffff,
      linewidth: 1
    })
    const pointsX = []
    const pointsZ = []
    pointsX.push(
      new THREE.Vector3(-this.inputs.size.x / 2, -this.inputs.size.y / 2, 0)
    )
    pointsX.push(
      new THREE.Vector3(this.inputs.size.x / 2, -this.inputs.size.y / 2, 0)
    )
    pointsZ.push(
      new THREE.Vector3(0, -this.inputs.size.y / 2, -this.inputs.size.z / 2)
    )
    pointsZ.push(
      new THREE.Vector3(0, -this.inputs.size.y / 2, this.inputs.size.z / 2)
    )
    const geometryX = new THREE.BufferGeometry().setFromPoints(pointsX)
    const geometryZ = new THREE.BufferGeometry().setFromPoints(pointsZ)

    this.floorLines = [
      new THREE.Line(geometryX, material),
      new THREE.Line(geometryZ, material)
    ]
    this.root?.add(this.floorLines[0])
    this.root?.add(this.floorLines[1])
  }

  onUpdateEdgesAndLines() {
    const THREE = this.context.three
    const edges: any = this.edges
    this.root?.remove(edges)

    const boxGeometry: BoxGeometry = new THREE.BoxGeometry(
      this.inputs.size.x,
      this.inputs.size.y,
      this.inputs.size.z
    )
    const edgesGeometry = new THREE.EdgesGeometry(boxGeometry)
    this.edges = new THREE.LineSegments(
      edgesGeometry,
      new THREE.LineBasicMaterial({
        transparent: true,
        color: this.inputs.lineColor,
        linewidth: 1,
        opacity: this.inputs.visible ? this.inputs.lineOpacity : 0
      })
    )
    this.root?.add(this.edges)

    if (this.inputs.showFloorLines) {
      this.onUpdateFloorLines()
    }
  }

  onInputsUpdated(oldInputs: IOrientedBoxInputs) {
    const THREE = this.context.three

    if (oldInputs.visible !== this.inputs.visible) {
      this.boxMixer?.stopAllAction()
      console.log(
        'visible changed, oldInputs.visible',
        oldInputs.visible,
        'this.inputs.visible',
        this.inputs.visible
      )
      if (
        this.boxMixer &&
        this.clipVisible &&
        this.edgesClipVisible &&
        this.clipNotVisible &&
        this.edgesClipNotVisible
      ) {
        // console.log('Visible changed', this.inputs.visible)
        // console.log('this.root', this.root)
        // console.log('this.box', this.box)
        // console.log('this.edges', this.edges)
        if (this.inputs.visible) {
          playAnimation(THREE, this.boxMixer, this.clipVisible)
          playAnimation(THREE, this.boxMixer, this.edgesClipVisible, this.edges)
        } else {
          playAnimation(THREE, this.boxMixer, this.clipNotVisible)
          playAnimation(
            THREE,
            this.boxMixer,
            this.edgesClipNotVisible,
            this.edges
          )
        }
      }
    }

    if (
      oldInputs.size.x !== this.inputs.size.x ||
      oldInputs.size.y !== this.inputs.size.y ||
      oldInputs.size.z !== this.inputs.size.z
    ) {
      this.makeBox()
      return
    }

    if (oldInputs.color !== this.inputs.color) {
      ;(this.box?.material as MeshBasicMaterial).color.set(this.inputs.color)
    }

    if (oldInputs.opacity !== this.inputs.opacity) {
      ;(this.box?.material as MeshBasicMaterial).opacity = this.inputs.opacity
    }

    if (oldInputs.lineOpacity !== this.inputs.lineOpacity) {
      ;(this.edges?.material as LineBasicMaterial).opacity =
        this.inputs.lineOpacity
    }

    if (oldInputs.lineColor !== this.inputs.lineColor) {
      ;(this.edges?.material as LineBasicMaterial).color = new THREE.Color(
        this.inputs.lineColor
      )
    }
  }

  onTick(delta: number) {
    this.boxMixer?.update(delta / 1000)
    if (this.inputs.visible) {
      // console.log('oriented box update inputs.position')
      const obj3D = (this.context.root as any).obj3D as Object3D
      const worldPos = new this.context.three.Vector3()
      obj3D.getWorldPosition(worldPos)
      this.outputs.position = worldPos
    } else {
      this.outputs.position = null
    }
  }
}

export const orientedBoxType = 'mp.orientedBox'
export const makeOrientedBox = (): Scene.IComponent => {
  return new OrientedBox()
}
