import { Provider } from 'react-redux'
import AppRouter from 'navigation/AppRouter'
import store from 'model/store'
import { ChakraProvider, theme } from '@chakra-ui/react'
import 'react-base-table/styles.css'

const App = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <AppRouter />
      </ChakraProvider>
    </Provider>
  )
}

export default App
