import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getTourModels, getSubscription } from 'model/selectors/base'
// import { DictT, TourModelT } from 'shared/types/model'

export const getTourModelsAlive = createSelector(
  [getTourModels],
  tourModels => {
    const aliveTMs = _.filter(tourModels, tm => !tm.deleted || tm.deleted === 0)
    return _.orderBy(aliveTMs, 'createdAt', 'desc')
  }
)

export const getAllTourModelsAmount = createSelector(
  [getTourModels],
  tourModels => {
    return _.size(tourModels)
  }
)

export const getTourModelsUsed = createSelector(
  [getTourModels, getSubscription, getAllTourModelsAmount],
  (tours, subscription, allToursAmount) => {
    if (_.isNil(subscription)) {
      return allToursAmount
    } else {
      return _.size(tours)
    }
  }
)
