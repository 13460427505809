import { FC, useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  Button,
  VStack,
  HStack,
  WrapItem,
  Image,
  Wrap,
  Text,
  Badge,
  Tooltip,
  // Alert,
  // AlertIcon,
  // CloseButton,
  // useDisclosure,
  useToast,
  Checkbox,
  Spinner
} from '@chakra-ui/react'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { ItemT, SlotT, DictT, SupplierT } from 'shared/types/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { getItemPhotoUrl } from 'shared/utils/itemsUtils'
import numeral from 'numeral'

type Props = {
  toCatalog: () => void
  closeCatalog: () => void
  addToRoom: (itemId: string) => void
  selectedItemId: string | null
  openProductCard: (item: ItemT) => void
  slot: SlotT
  // items: DictT<ItemT>
  suppliers?: DictT<SupplierT>
  getItems?: (
    slotId: string,
    amount: number,
    offset: number
  ) => Promise<ItemT[]>
  pricesVisible: boolean
}

const CategoryItems: FC<Props> = ({
  toCatalog,
  closeCatalog,
  addToRoom,
  selectedItemId,
  openProductCard,
  slot,
  // items,
  suppliers,
  getItems,
  pricesVisible
}) => {
  const toast = useToast()
  const [items, setItems] = useState<ItemT[]>([])
  // const { onClose, isOpen } = useDisclosure({ defaultIsOpen: true })
  const tipName = 'tip_how_to_add_item'
  const [loading, setLoading] = useState(false)
  const [endReached, setEndReached] = useState(false)

  const loadItems = async () => {
    const amount = 10
    const offset = items.length > 0 ? _.last(items).displayOrder : -1
    setLoading(true)
    const newItems = await getItems(slot.id, amount, offset)
    if (_.isEmpty(newItems)) {
      setEndReached(true)
    } else {
      setItems(currentItems => [...currentItems, ...newItems])
      const lastItemReached =
        !_.isNil(slot.lastItemId) && _.last(newItems).id === slot.lastItemId
      if (_.size(newItems) < amount || lastItemReached) {
        setEndReached(true)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    loadItems()
  }, [slot.id])

  const showToast = () => {
    const hideTip = localStorage.getItem(tipName)
    if (_.isNil(hideTip) && _.isNil(selectedItemId)) {
      toast({
        title: 'Tip',
        variant: 'left-accent',
        isClosable: true,
        status: 'info',
        position: 'top',
        description: (
          <VStack w='full' align='flex-start'>
            <Text>
              When an item is added to room it will “stick” to your cursor. Move
              the cursor around the space until it appears in the right spot and
              then click to place it.
            </Text>
            <Checkbox
              colorScheme={'blackAlpha'}
              size='sm'
              borderColor='black'
              onChange={e =>
                localStorage.setItem(tipName, _.toString(e.target.checked))
              }
            >
              Do not show this again
            </Checkbox>
          </VStack>
        ),
        duration: 10000
      })
    }
  }

  const onAddToRoomClick = (itemId: string) => {
    showToast()
    addToRoom(itemId)
  }

  const renderButtonMore = () => {
    if (!endReached) {
      return (
        <HStack w='full' justify={'center'} pb={4}>
          <Button
            size='sm'
            isLoading={loading}
            isDisabled={loading}
            onClick={loadItems}
            variant='outline'
          >
            Show more
          </Button>
        </HStack>
      )
    }
  }

  const onOpenProductCardClick = (item: ItemT) => {
    console.log('onOpenProductCardClick', item)
    openProductCard(item)
  }

  const renderItems = () => {
    if (_.isEmpty(items)) {
      return (
        <VStack w='full' h='full' align={'center'} justify='center'>
          <Spinner
            thickness='2px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='sm'
          />
        </VStack>
      )
    }
    return (
      <Wrap
        spacing={4}
        h='full'
        overflowY='auto'
        // scrollSnapType={'y proximity'}
        sx={{
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {_.map(items, (item: ItemT) => {
          return (
            <WrapItem key={item.id} scrollSnapAlign='start'>
              <VStack
                align={'flex-start'}
                pb={4}
                w={'164px'}
                spacing={1}
                opacity={item.enabled ? 1 : 0.6}
              >
                <Box
                  as={item.id !== selectedItemId ? 'button' : undefined}
                  onClick={() => onOpenProductCardClick(item)}
                >
                  <Image
                    boxSize={164}
                    alt={item.title}
                    src={getItemPhotoUrl(item)}
                  />
                </Box>

                <Text
                  fontSize={'xs'}
                  color='gray.600'
                  noOfLines={2}
                  h='8'
                  lineHeight={1.2}
                >
                  {item.title}
                </Text>
                {pricesVisible && item.price && (
                  <Text fontWeight={'semibold'} fontSize='xs'>
                    {numeral(item.price).format('$0,0.00')}
                  </Text>
                )}

                <Flex align='center' justify={'space-between'} w='full' pb={2}>
                  {suppliers && item.supplierId && (
                    <Tooltip
                      label={_.get(suppliers, [item.supplierId, 'name'])}
                    >
                      <Badge colorScheme='green'>
                        {_.truncate(
                          _.get(suppliers, [item.supplierId, 'name']),
                          {
                            length: 14
                          }
                        )}
                      </Badge>
                    </Tooltip>
                  )}
                  <Button
                    size='xs'
                    color='blue.500'
                    variant={'unstyled'}
                    onClick={() => onOpenProductCardClick(item)}
                  >
                    more info
                  </Button>
                </Flex>
                <Button
                  size='sm'
                  colorScheme='blue'
                  w='full'
                  onClick={() => onAddToRoomClick(item.id)}
                  disabled={item.id === selectedItemId}
                >
                  {selectedItemId ? 'Select' : 'Add to tour'}
                </Button>
              </VStack>
            </WrapItem>
          )
        })}
        {renderButtonMore()}
      </Wrap>
    )
  }

  return (
    <RightPanelWrapper show width={380}>
      <Flex flex={1} maxH='full' direction='column'>
        <Box pb={8} bg='white' p={4}>
          <Flex align='flex-start' justify={'space-between'}>
            <HStack align='flex-start' spacing={4}>
              <Box as='button' onClick={() => toCatalog()}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Box>
              <VStack align={'flex-start'}>
                <Heading size='md' color='black'>
                  {slot?.title}
                </Heading>
              </VStack>
            </HStack>
            <Button
              size='xs'
              variant={'ghost'}
              color='blue.500'
              onClick={() => closeCatalog()}
            >
              Done
            </Button>
          </Flex>
        </Box>
        <Flex
          direction='column'
          align={'flex-start'}
          justify='flex-start'
          flex={1}
          overflow='hidden'
          px={4}
        >
          {renderItems()}
        </Flex>
      </Flex>
    </RightPanelWrapper>
  )
}

export default CategoryItems
