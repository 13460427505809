import {
  query,
  collection,
  getDocs,
  where,
  limit,
  orderBy,
  startAfter
} from 'firebase/firestore'
import _ from 'lodash'

import { db } from 'controllers/db'
import store from 'model/store'
import { receiveItems } from 'model/actions'
import { ItemT } from 'types/model'
import * as Sentry from '@sentry/react'

export const dbGetItemsBySlot = async (
  slotId: string,
  amount: number,
  offset: number
) => {
  try {
    const state = store.getState()
    const tags = _.get(state, 'partner.tags')
    console.log('dbGetItemsBySlot', slotId, amount, offset, tags)
    let q
    if (!_.isEmpty(tags)) {
      q = query(
        collection(db, 'furnitures'),
        where('kind', '==', slotId),
        where('enabled', '==', true),
        where('tags', 'array-contains-any', tags),
        orderBy('displayOrder', 'asc'),
        startAfter(offset),
        limit(amount)
      )
    } else {
      q = query(
        collection(db, 'furnitures'),
        where('kind', '==', slotId),
        where('enabled', '==', true),
        orderBy('displayOrder', 'asc'),
        startAfter(offset),
        limit(amount)
      )
    }
    const sn = await getDocs(q)
    const items = _.map(sn.docs, doc => doc.data() as ItemT)
    console.log('dbGetItemsBySlot return ', items)
    return items
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return []
  }
}

export const dbGetLastItemBySlot = async (slotId: string) => {
  const state = store.getState()
  const tags = _.get(state, 'partner.tags')
  console.log('dbGetItemsBySlot', slotId, tags)
  let q
  if (!_.isEmpty(tags)) {
    q = query(
      collection(db, 'furnitures'),
      where('kind', '==', slotId),
      where('enabled', '==', true),
      where('tags', 'array-contains-any', tags),
      orderBy('displayOrder', 'desc'),
      limit(1)
    )
  } else {
    q = query(
      collection(db, 'furnitures'),
      where('kind', '==', slotId),
      where('enabled', '==', true),
      orderBy('displayOrder', 'desc'),
      limit(1)
    )
  }
  const sn = await getDocs(q)
  if (sn.empty) {
    return null
  } else {
    return sn.docs[0].id
  }
}

export const dbFetchItemsList = async (itemsIds: string[]) => {
  try {
    const chunks = _.chunk(itemsIds, 10)
    // console.log('chunks', chunks)
    const itemsAr = await Promise.all(
      _.map(chunks, async ids => {
        const q = query(collection(db, 'furnitures'), where('id', 'in', ids))
        const itemsSN = await getDocs(q)
        return _.map(itemsSN.docs, doc => {
          const p = doc.data() as ItemT
          return p
        })
      })
    )
    console.log('itemsAr', itemsAr)
    const items = _.keyBy(_.flatten(itemsAr), 'id')
    store.dispatch(receiveItems(items))
    const itemsNotFound = _.difference(itemsIds, _.keys(items))
    return itemsNotFound
  } catch (e) {
    Sentry.captureException(e)
    return []
  }
}
