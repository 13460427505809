import { createReducer } from '@reduxjs/toolkit'
import { DictT, ItemsTemplateT } from 'types/model'
import { receiveTemplates } from 'model/actions'

const initialState = {} as DictT<ItemsTemplateT>

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveTemplates, (state, action) => action.payload)
  // .addCase(clear, () => initialState)
  // .addCase(logout, () => initialState)
})

export default reducer
