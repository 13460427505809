import { useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import { dbDeleteTourSlot, dbUpdateTourPath } from 'controllers/tours'
import TourView from 'shared/components/TourView'
import { useDispatch, useSelector } from 'model/hooks'
import { generateId } from 'controllers/db'
import { AddItemT, TourSlotT, TourT } from 'shared/types/model'
import { addMattertag, clearItems, removeMattertag } from 'model/actions'
import EditTourNavBar from 'pages/editTour/EditTourNavBar'
import UserItemsPanel from 'pages/editTour/tourView/UserItemsPanel'
import { getRoomTypesWithTemplates } from 'model/selectors/templatesSelector'
import { dbFetchItemsList, dbGetItemsBySlot } from 'controllers/items'

const EditTour = () => {
  const { tourId } = useParams()
  const tour: TourT | undefined = useSelector(state =>
    _.get(state, ['tours', tourId || ''])
  )
  const partner = useSelector(state => state.partner)
  const tourModels = useSelector(state => state.tourModels)
  const items = useSelector(state => state.items)
  const slots = useSelector(state => state.slots)
  const suppliers = useSelector(state => state.suppliers)
  const mattertags = useSelector(state => state.mattertags)
  const roomTypesWithTemplates = useSelector(getRoomTypesWithTemplates)
  const dispatch = useDispatch()
  const itemsNotFoundRef = useRef<string[]>([])

  useEffect(() => {
    const run = async () => {
      if (tour?.slots) {
        const itemsIdsToLoad = _.uniq(_.map(tour.slots, ts => ts.itemId))
        console.log('itemsIdsToLoad', itemsIdsToLoad)
        const currentIds = _.keys(items)
        console.log('currentIds', currentIds)
        const diff = _.difference(itemsIdsToLoad, currentIds)
        console.log('diff', diff)
        const finalDiff = _.difference(diff, itemsNotFoundRef.current)
        console.log('finalDiff', finalDiff)
        if (!_.isEmpty(finalDiff)) {
          const nf = await dbFetchItemsList(finalDiff)
          itemsNotFoundRef.current = _.uniq([
            ...itemsNotFoundRef.current,
            ...nf
          ])
        }
      }
    }
    run()
  }, [items, tour?.slots])

  useEffect(() => {
    return () => {
      dispatch(clearItems())
    }
  }, [])

  const removeTourSlot = (slotId: string) => {
    dbDeleteTourSlot(tour.id, slotId)
    dispatch(removeMattertag({ tourSlotId: slotId }))
  }

  const updateTourPath = async (pth: string, value: any) => {
    dbUpdateTourPath(tour.id, pth, value)
  }

  const updateTourSlot = async (
    slotId: string,
    key: keyof TourSlotT,
    value: any
  ) => {
    updateTourPath(`slots.${slotId}.${key}`, value)
  }

  const onResetDesign = () => {
    updateTourPath('slots', {})
  }

  const addItems = (aItems: AddItemT[]) => {
    _.forEach(aItems, ({ itemId, position, rotation }: AddItemT) => {
      const id = generateId()
      const ts: TourSlotT = {
        id,
        itemId,
        position,
        createdAt: _.now(),
        rotation
      }
      console.log('add item', ts)
      updateTourPath(`slots.${ts.id}`, ts)
    })
  }

  const onMattertagCreated = (tourSlotId: string, mtId: string) => {
    console.log('onMattertagCreated', tourSlotId, mtId)
    dispatch(addMattertag({ tourSlotId, tagId: mtId }))
    // dispatch(receiveMattertag(tourSlotId, mtId))
  }

  const renderTourView = () => {
    if (tour && tourModels) {
      const tourModel = tourModels[tour.tourModelId]
      if (tourModel) {
        return (
          <TourView
            key={tour.id}
            tourId={tour.id}
            tourModelId={tourModel.id}
            tourSlots={tour.slots}
            modelId={tourModel.modelId}
            deleteSlot={removeTourSlot}
            updateTourSlot={updateTourSlot}
            onResetDesign={onResetDesign}
            addItems={addItems}
            getItems={dbGetItemsBySlot}
            items={items}
            slots={slots}
            suppliers={suppliers}
            mattertags={mattertags}
            onMattertagCreated={onMattertagCreated}
            EditTourNavBar={EditTourNavBar}
            UserItemsPanel={UserItemsPanel}
            designName={tour.name}
            roomTypesWithTemplates={roomTypesWithTemplates}
            loadItems={(itemsIds: string[]) => dbFetchItemsList(itemsIds)}
            canEdit={!tour.isCustom}
            pricesVisible={_.get(partner, 'pricesVisible', false)}
          />
        )
      }
    }
  }

  return (
    <Box position='relative' w='full' h='full'>
      {renderTourView()}
    </Box>
  )
}

export default EditTour
