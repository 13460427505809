import {
  SceneComponent,
  ComponentOutput
} from 'shared/components/SceneComponent'
import { MpSdk, Mode } from 'shared/bundle/sdk'
import {
  Object3D,
  Raycaster,
  Vector2,
  // Event,
  // Intersection,
  Vector3
} from 'three'
import _ from 'lodash'

export interface IMModelIntersectionInputs extends Record<string, unknown> {
  enabled: boolean
  cameraPosition: Vector3
  mode: Mode.Mode
}

interface Outputs extends ComponentOutput {
  intersect: Vector3 | null
}

class MModelIntersection extends SceneComponent {
  private root: Object3D | null = null
  private raycaster: Raycaster | null = null
  // private roomObjects: Object3D[] = []
  // private prevIntersects: Intersection<Object3D<Event>>[] | undefined
  private rayDirection: Vector3 = new Vector3(0, -1, 0)
  private pointer = new Vector2(0, 0)

  outputs = {
    intersect: null
  } as Outputs

  inputs: IMModelIntersectionInputs = {
    enabled: false,
    cameraPosition: new Vector3(),
    mode: 'mode.inside' as Mode.Mode
  }

  onPointerMove (event) {
    if (this.inputs.mode !== 'mode.floorplan') {
      // console.log('onPointerMove', event)
      const newX = (event.clientX / window.innerWidth) * 2 - 1
      const newY = -(event.clientY / window.innerHeight) * 2 + 1
      // if (newX && newY && _.isObject(this.pointer)) {
      // console.log('newX', newX, 'newY', newY)
      this.pointer = new Vector2(newX, newY)
      // console.log('new pointer', this.pointer)
      // this.raycaster?.set(this.pointer, this.rayDirection)
      this.raycaster.setFromCamera(this.pointer, this.context.camera)
      // console.log('this.raycaster', this.raycaster)
      const intersects = this.raycaster?.intersectObjects(
        this.context.scene.children,
        true
      )
      // console.log('intersects', intersects)
      this.outputs.intersect = _.get(intersects, [0, 'point'], null)
    }
  }

  onInputsUpdated (oldInputs: IMModelIntersectionInputs) {
    // if (oldInputs.enabled !== this.inputs.enabled) {
    //   const renderer = this.context.renderer
    //   if (this.inputs.enabled) {
    //     renderer.domElement.addEventListener('pointermove', this.onPointerMove)
    //   } else {
    //     renderer.domElement.removeEventListener(
    //       'pointermove',
    //       this.onPointerMove
    //     )
    //   }
    // }
    if (this.inputs.mode === 'mode.floorplan') {
      if (oldInputs.cameraPosition !== this.inputs.cameraPosition) {
        // console.log('this.pointer', this.pointer)
        // console.log('this.inputs.cameraPosition', this.inputs.cameraPosition)
        this.raycaster?.set(this.inputs.cameraPosition, this.rayDirection)
        // console.log('this.pointer', this.pointer)
        // this.raycaster.setFromCamera(this.pointer, this.context.camera)
        const intersects = this.raycaster?.intersectObjects(
          this.context.scene.children,
          true
        )
        // console.log('intersects', intersects)
        const int = _.find(
          intersects,
          i => i.object.visible && i.object.opacity === 1
        )
        this.outputs.intersect = int ? int.point : null
      }
    }
  }

  // private getFurnitureMain = (obj: Object3D<Event>): Object3D<Event> | null => {
  //   const isFurnitureMain = _.get(obj, 'userData.isFurnitureMain', false)
  //   if (isFurnitureMain) {
  //     if (this.inputs.excludeItem && this.inputs.excludeItem === obj.name) {
  //       return null
  //     } else {
  //       // console.log('furniture main', obj.name, obj.userData)
  //       return obj
  //     }
  //   } else if (obj && obj.parent) {
  //     return this.getFurnitureMain(obj.parent)
  //   } else {
  //     return null
  //   }
  // }

  onInit () {
    // console.log('%MModelIntersection onInit', 'color: red;')
    // console.log('this.context.scene', this.context.scene)
    // console.log('this.context', this.context)

    const THREE = this.context.three
    // const renderer = this.context.renderer
    this.root = new THREE.Object3D()
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root
    this.raycaster = new this.context.three.Raycaster()
    this.raycaster.setFromCamera(this.pointer, this.context.camera)
    this.raycaster.layers.set(2)
    const onpmove = this.onPointerMove.bind(this)
    this.context.renderer.domElement.addEventListener('pointermove', onpmove)
  }

  onDestroy () {
    this.raycaster = null
    this.root = null
    // this.roomObjects = null
    // const renderer = this.context.renderer
    this.context.renderer.domElement.removeEventListener(
      'pointermove',
      this.onPointerMove
    )
  }
}

export const modelIntersectionType = 'mp.modelIntersection'
export const makeModelIntersection = (): MpSdk.Scene.IComponent => {
  return new MModelIntersection()
}
