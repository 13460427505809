import { RootState } from 'model/store'
import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'

export const getItems = (state: RootState) => state.items
export const getTours = (state: RootState) => state.tours
export const getSlots = (state: RootState) => state.slots
export const getUser = (state: RootState) => state.user
export const getSettings = (state: RootState) => state.settings
export const getTourModels = (state: RootState) => state.tourModels
export const getProducts = (state: RootState) => state.products
export const getPrices = (state: RootState) => state.prices
export const getSubscription = (state: RootState) => state.subscription
export const getRequestsDesign = (state: RootState) => state.requestsDesign
export const getRoomTypes = (state: RootState) => state.roomTypes
export const getTemplates = (state: RootState) => state.templates
export const getProfiles = (state: RootState) => state.profiles
export const getUserAccounts = (state: RootState) => state.userAccounts
export const getAccount = (state: RootState) => state.account
export const getAccountProfile = (state: RootState) => state.accountProfile
export const getInvitations = (state: RootState) => state.invitations
export const getPartner = (state: RootState) => state.partner

export const getAppLoaded = createSelector(
  [
    getItems,
    getTours,
    getSlots,
    getUser,
    getTourModels,
    getAccount,
    getAccountProfile
  ],
  (items, tours, slots, user, tourModels, account, accountProfile) => {
    const lst = [items, tours, slots, user, tourModels, account, accountProfile]
    const doneAmount = _.reduce(
      lst,
      (res, elt) => {
        return _.isNil(elt) ? res : res + 1
      },
      0
    )
    // console.table([
    //   ['items', !_.isNil(items)],
    //   ['tours', !_.isNil(tours)],
    //   ['slots', !_.isNil(slots)],
    //   ['user', !_.isNil(user)],
    //   ['tourModels', !_.isNil(tourModels)],
    //   ['account', !_.isNil(account)],
    //   ['accountProfile', !_.isNil(accountProfile)]
    // ])
    return doneAmount === _.size(lst) ? null : (doneAmount / _.size(lst)) * 100
  }
)
