import { FC, useState, useRef, useEffect } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  InputRightElement,
  IconButton,
  Select
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import validator from 'validator'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'

import { dbSignUp } from 'controllers/auth'
import GoogleAuthButton from 'pages/auth/GoogleAuthButton'
import AuthDivider from 'pages/auth/AuthDivider'
import AuthGeneralError from 'pages/auth/AuthGeneralError'
import { authErrorToString } from 'shared/utils/stringFirebaseError'
import AuthHeader from 'pages/auth/AuthHeader'
import { useSelector } from 'model/hooks'
import { InvitationT } from 'types/model'

const ROLES = [
  'Real estate agent / Realtor / Broker',
  'Interior designer',
  'Real estate photographer',
  'Property marketing',
  'Other'
]

const SignUp: FC = () => {
  const location = useLocation()
  const onboarding = useSelector(state => state.onboarding)
  const [name, setName] = useState<string>(_.get(onboarding, 'name', ''))
  const [email, setEmail] = useState<string>(_.get(onboarding, 'email', ''))
  const [password, setPassword] = useState<string>('')
  const [role, setRole] = useState<string>('placeholder')
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [generalError, setGeneralError] = useState<string | null>(null)
  const [nameError, setNameError] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  const invitation: InvitationT | undefined = _.get(
    location.state,
    'invitation'
  )

  useEffect(() => {
    const tourModelId = onboarding?.tourModelId
    if (!_.isNil(tourModelId)) {
      navigate({
        pathname: '/auth/signup',
        search: `?onboarding=${tourModelId}`
      })
    }
  }, [onboarding?.tourModelId])

  const submit = async (): Promise<void> => {
    setGeneralError(null)
    let eError = null
    let pError = null
    let nError = null
    console.log('name, email', name, email)
    if (_.isEmpty(email)) {
      eError = 'Please enter your email'
    } else if (!validator.isEmail(email)) {
      eError =
        'Please enter a valid email address using the following format: name@example.com'
    }
    if (_.isEmpty(_.toString(password))) {
      pError = 'Please enter your password'
    } else if (_.size(password) < 8) {
      pError = 'Passwords must be 8 or more characters.'
    }

    if (_.isEmpty(_.toString(name))) {
      nError = 'Please enter your name'
    }

    if (_.isNil(eError) && _.isNil(pError) && _.isNil(nError)) {
      setLoading(true)
      const gtag: any = _.get(window, 'gtag')
      if (!_.isNil(gtag)) {
        gtag('event', 'conversion', {
          send_to: 'AW-11192522219/1x8CCOWn8aMYEOurgdkp',
          event_callback: () => null
        })
      }
      const errorCode = await dbSignUp(name, email, password, role)
      if (!_.isNil(errorCode)) {
        switch (errorCode) {
          case 'auth/email-already-in-use':
            eError =
              'Another account with this email already exists. Would you like to sign in?'
            break
          default:
            setGeneralError(authErrorToString(errorCode))
        }
      }
      setLoading(false)
    }
    setNameError(nError)
    setEmailError(eError)
    setPasswordError(pError)
  }

  const pageHeader = () => {
    if (invitation) {
      let desc = `${_.get(
        invitation,
        'createdBy'
      )} has invited you to join UpStager`
      if (!_.isEmpty(invitation?.accountName)) {
        desc = `${desc} at ${invitation?.accountName}`
      }
      return (
        <AuthHeader title='Create an account to join team' description={desc} />
      )
    } else {
      return (
        <AuthHeader
          title='Let’s create an account'
          description='Enhance unfurnished virtual tours'
        />
      )
    }
  }

  const resetErrors = () => {
    setNameError(null)
    setEmailError(null)
    setPasswordError(null)
  }

  const togglePasswordVisibility = () => setShow(show => !show)

  const renderHelperText = () => {
    if (_.isNil(passwordError)) {
      return <FormHelperText>At least 8 characters long</FormHelperText>
    } else {
      return <FormErrorMessage>{passwordError}</FormErrorMessage>
    }
  }

  const renderInputs = () => {
    return (
      <VStack spacing={5} w='full'>
        <FormControl isRequired isInvalid={!_.isNil(nameError)}>
          <FormLabel htmlFor='name'>Name</FormLabel>
          <Input
            id='name'
            type='text'
            value={name}
            onFocus={resetErrors}
            color={_.isNil(nameError) ? undefined : 'red.500'}
            onChange={e => setName(e.target.value)}
            onKeyPress={async e => {
              if (e.key === 'Enter') {
                emailInputRef.current?.focus()
              }
            }}
          />
          <FormErrorMessage>{nameError}</FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='role'>Role</FormLabel>
          <Select
            id='role'
            value={role}
            onChange={e => setRole(e.target.value)}
          >
            <option hidden disabled value='placeholder'>Choose what better describes your role</option>
            {_.map(ROLES, (r, i) => <option key={`role_${i}`}>{r}</option>)}
          </Select>
        </FormControl>
        <FormControl isRequired isInvalid={!_.isNil(emailError)}>
          <FormLabel htmlFor='email'>Email</FormLabel>
          <Input
            id='email'
            type='email'
            value={email}
            ref={emailInputRef}
            color={_.isNil(emailError) ? undefined : 'red.500'}
            onFocus={resetErrors}
            onChange={e => setEmail(e.target.value)}
            onKeyPress={async e => {
              if (e.key === 'Enter') {
                passwordInputRef.current?.focus()
              }
            }}
          />
          <FormErrorMessage>{emailError}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!_.isNil(passwordError)}>
          <FormLabel htmlFor='password'>Password</FormLabel>
          <InputGroup size='md'>
            <Input
              id='password'
              type={show ? 'text' : 'password'}
              value={password}
              onFocus={resetErrors}
              color={_.isNil(passwordError) ? undefined : 'red.500'}
              onChange={e => setPassword(e.target.value)}
              ref={passwordInputRef}
              onKeyPress={async e => {
                if (e.key === 'Enter') {
                  submit()
                }
              }}
            />
            <InputRightElement>
              <IconButton
                aria-label='show/hide password'
                variant={'unstyled'}
                icon={<FontAwesomeIcon icon={show ? faEyeSlash : faEye} />}
                onClick={togglePasswordVisibility}
              />
            </InputRightElement>
          </InputGroup>
          {renderHelperText()}
        </FormControl>
      </VStack>
    )
  }

  const renderGeneralError = () => {
    if (!_.isNil(generalError)) {
      return (
        <AuthGeneralError
          errorMessage={generalError}
          onReset={() => setGeneralError(null)}
        />
      )
    }
  }

  const renderChangeMode = () => {
    return (
      <HStack spacing='2' justify='center' align='center' pt={4}>
        <Text color='gray.600'>Already have an account?</Text>
        <Button
          variant='link'
          color='blue.600'
          onClick={() => navigate('/auth/signin', { state: { invitation } })}
        >
          Log in
        </Button>
      </HStack>
    )
  }

  const renderCTA = () => {
    return (
      <Box w='full' pt={4}>
        <Button
          isLoading={loading}
          variant='solid'
          colorScheme={'teal'}
          onClick={submit}
          w='full'
          size='lg'
        >
          Create account
        </Button>
      </Box>
    )
  }

  return (
    <VStack spacing='2' w='full' h='full' overflow={'auto'}>
      {pageHeader()}
      <VStack
        bgColor='white'
        p='10'
        w='full'
        maxW='2xl'
        rounded='lg'
        boxShadow='sm'
      >
        {renderGeneralError()}
        {renderInputs()}
        {renderCTA()}
        {renderChangeMode()}
        <AuthDivider />
        <GoogleAuthButton title='Sign up with Google' />
      </VStack>
    </VStack>
  )
}

export default SignUp
