import { FC, useState, useMemo } from 'react'
import _ from 'lodash'
import { DictT, ItemsTemplateT, TemplatesT } from 'shared/types/model'
import RoomTypes from 'shared/components/templatesPanel/RoomTypes'
import TemplatesList from 'shared/components/templatesPanel/TemplatesList'

type Props = {
  onClose: () => void
  addToRoom: (t: ItemsTemplateT) => void
  visible: boolean
  roomTypesWithTemplates: DictT<TemplatesT>
}

const TemplatesPanel: FC<Props> = ({
  onClose,
  addToRoom,
  visible,
  roomTypesWithTemplates
}) => {
  const [selectedRoomTypeId, setSelectedRoomTypeId] = useState<string | null>(
    null
  )

  const roomTypes = useMemo(
    () =>
      _.sortBy(
        _.map(roomTypesWithTemplates, rtwt => rtwt.roomType),
        'displayOrder'
      ),
    [roomTypesWithTemplates]
  )

  const onCloseClick = () => {
    setSelectedRoomTypeId(null)
    onClose()
  }

  const selectRoomType = (rtId: string) => {
    setSelectedRoomTypeId(rtId)
  }

  if (!visible) {
    return null
  } else if (selectedRoomTypeId) {
    console.log('Category Items')
    return (
      <TemplatesList
        goBack={() => setSelectedRoomTypeId(null)}
        closePanel={onCloseClick}
        addToRoom={addToRoom}
        roomType={roomTypesWithTemplates[selectedRoomTypeId].roomType}
        templates={roomTypesWithTemplates[selectedRoomTypeId].templates}
      />
    )
  } else {
    console.log('return Catalog')
    return (
      <RoomTypes
        goBack={onCloseClick}
        roomTypes={roomTypes}
        selectRoomType={selectRoomType}
      />
    )
  }
}

export default TemplatesPanel
