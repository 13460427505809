import { useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import _ from 'lodash'

import TourView from 'shared/components/TourView'
import { useDispatch, useSelector } from 'model/hooks'
import { generateId } from 'controllers/db'
import { AddItemT, DictT, TourSlotT } from 'shared/types/model'
import {
  addMattertag,
  clearItems,
  oboardingAddTourSlots,
  onboardingDeleteTourSlot,
  onboardingResetDesign,
  onboardingUpdateTourSlot,
  removeMattertag
} from 'model/actions'
// import EditTourNavBar from 'pages/editTour/EditTourNavBar'
import UserItemsPanel from 'pages/editTour/tourView/UserItemsPanel'
import { getRoomTypesWithTemplates } from 'model/selectors/templatesSelector'
import OnboardingDesignNavBar from 'pages/onboarding/onboardingDesign/OnboardingDesignNavBar'
import { dbFetchItemsList, dbGetItemsBySlot } from 'controllers/items'

const OnboardingDesign = () => {
  const onboarding = useSelector(state => state.onboarding)
  const items = useSelector(state => state.items)
  const slots = useSelector(state => state.slots)
  const suppliers = useSelector(state => state.suppliers)
  const mattertags = useSelector(state => state.mattertags)
  const roomTypesWithTemplates = useSelector(getRoomTypesWithTemplates)
  const dispatch = useDispatch()
  const itemsNotFoundRef = useRef<string[]>([])
  const partner = useSelector(state => state.partner)

  useEffect(() => {
    const run = async () => {
      if (onboarding && onboarding.tour.slots) {
        const itemsIdsToLoad = _.uniq(
          _.map(onboarding?.tour.slots, ts => ts.itemId)
        )
        console.log('itemsIdsToLoad', itemsIdsToLoad)
        const currentIds = _.keys(items)
        console.log('currentIds', currentIds)
        const diff = _.difference(itemsIdsToLoad, currentIds)
        console.log('diff', diff)
        const finalDiff = _.difference(diff, itemsNotFoundRef.current)
        console.log('finalDiff', finalDiff)
        if (!_.isEmpty(finalDiff)) {
          const nf = await dbFetchItemsList(finalDiff)
          itemsNotFoundRef.current = _.uniq([
            ...itemsNotFoundRef.current,
            ...nf
          ])
        }
      }
    }
    run()
  }, [items, onboarding?.tour.slots])

  useEffect(() => {
    return () => {
      dispatch(clearItems())
    }
  }, [])

  const dbDeleteTourSlot = (tourSlotId: string) => {
    dispatch(onboardingDeleteTourSlot(tourSlotId))
  }

  const removeTourSlot = (slotId: string) => {
    dbDeleteTourSlot(slotId)
    dispatch(removeMattertag({ tourSlotId: slotId }))
  }

  const updateTourSlot = async (
    slotId: string,
    key: keyof TourSlotT,
    value: any
  ) => {
    dispatch(onboardingUpdateTourSlot({ slotId, key, value }))
  }

  const onResetDesign = () => {
    dispatch(onboardingResetDesign())
  }

  const addItems = (addItems: AddItemT[]) => {
    if (onboarding) {
      const newTourSlots: DictT<TourSlotT> = {}
      _.forEach(addItems, ({ itemId, position, rotation }: AddItemT) => {
        const id = generateId()
        const ts: TourSlotT = {
          id,
          itemId,
          position,
          createdAt: _.now(),
          rotation
        }
        newTourSlots[ts.id] = ts
      })
      dispatch(oboardingAddTourSlots(newTourSlots))
    }
  }

  const onMattertagCreated = (tourSlotId: string, mtId: string) => {
    console.log('onMattertagCreated', tourSlotId, mtId)
    dispatch(addMattertag({ tourSlotId, tagId: mtId }))
    // dispatch(receiveMattertag(tourSlotId, mtId))
  }

  const renderTourView = () => {
    // if (tour) {
    if (onboarding && onboarding.modelId) {
      return (
        <TourView
          key={'onboarding'}
          tourId={'onboarding'}
          tourModelId={'test'}
          tourSlots={onboarding.tour.slots}
          modelId={onboarding.modelId}
          deleteSlot={removeTourSlot}
          updateTourSlot={updateTourSlot}
          onResetDesign={onResetDesign}
          addItems={addItems}
          // itemsBySlot={itemsBySlot}
          getItems={dbGetItemsBySlot}
          items={items}
          slots={slots}
          suppliers={suppliers}
          mattertags={mattertags}
          onMattertagCreated={onMattertagCreated}
          EditTourNavBar={OnboardingDesignNavBar}
          UserItemsPanel={UserItemsPanel}
          designName={onboarding.tour.name}
          roomTypesWithTemplates={roomTypesWithTemplates}
          loadItems={(itemsIds: string[]) => dbFetchItemsList(itemsIds)}
          canEdit
          pricesVisible={_.get(partner, 'pricesVisible', false)}
        />
      )
    }
    // }
  }

  return (
    <Box w='full' h='full'>
      {renderTourView()}
    </Box>
  )
}

export default OnboardingDesign
