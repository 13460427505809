import { FC, ReactElement } from 'react'
import { HStack, VStack, Text, Box } from '@chakra-ui/react'

type Props = {
  title: string
  description: string
  icon?: ReactElement
  onClick: () => void
  id?: string
}

const RightPanelButton: FC<Props> = ({ title, description, icon, onClick, ...rest }) => {
  return (
    <HStack
      _hover={{ bg: 'gray.50' }}
      w='full'
      py={2}
      as='button'
      onClick={onClick}
      spacing={4}
      {...rest}
    >
      <HStack
        w='10'
        justify={'flex-end'}
        align='flex-start'
        h='full'
        fontSize={'1.2em'}
        // pt={2}
        flexShrink={0}
      >
        <Box>{icon}</Box>
      </HStack>
      <VStack align='flex-start' spacing={0} pr={8}>
        <Text fontSize='xl' fontWeight={'semibold'} textAlign={'start'}>
          {title}
        </Text>
        <Text
          fontSize={'sm'}
          fontWeight='normal'
          color='gray.600'
          textAlign={'start'}
        >
          {description}
        </Text>
      </VStack>
    </HStack>
  )
}

export default RightPanelButton
