import { DictT } from 'shared/types/model'

export const mapObject = <TValue, TNewValue>(
  obj: DictT<TValue>,
  mapFunc: (value: TValue, key: string) => TNewValue
): DictT<TNewValue> => {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [key]: mapFunc(obj[key], key)
    }),
    {} as DictT<TNewValue>
  )
}
