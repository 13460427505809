import { FC, useMemo, useState } from 'react'
import { Button, Flex, Box, Text, VStack } from '@chakra-ui/react'
import _ from 'lodash'

import PlanSelect from 'components/stripe/PlanSelect'
import { useSelector } from 'model/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenLine } from '@fortawesome/pro-solid-svg-icons'
import { getCurrentPlanUsage } from 'model/selectors/paymentPlans'
import { openPaymentPage } from 'controllers/stripe'

const Billing: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const subscription = useSelector(state => state.subscription)
  const products = useSelector(state => state.products)
  const planUsage = useSelector(getCurrentPlanUsage)

  const currentProduct = useMemo(() => {
    if (subscription) {
      const productId = subscription.productId
      return products[productId]
    }
  }, [products, subscription])

  const onManageSubscription = async () => {
    setLoading(true)
    await openPaymentPage()
    setLoading(false)
  }

  return (
    <VStack
      align='center'
      justify='start'
      direction='column'
      w='full'
      overflowX={'hidden'}
      overflowY={'auto'}
      py={{ lg: '4', base: '0' }}
      spacing={8}
    >
      <Box
        w='100%'
        maxW={'4xl'}
        bg='white'
        boxShadow={{
          lg: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }}
        rounded='xl'
        position='relative'
        borderTopWidth={{ lg: 2, base: 0 }}
        borderTopColor='blue.500'
      >
        <Flex p={{ base: 4, lg: 6 }} direction='row' justify='space-between'>
          <Box>
            <Text fontWeight={700} fontSize='lg' color='gray.900'>
              Your plan
            </Text>
            <Text fontWeight={400} fontSize='sm' color='gray.600'>
              {_.get(currentProduct, 'name', '')}
            </Text>
          </Box>
          {!_.isNil(subscription) && (
            <Box>
              <Button
                variant='outline'
                leftIcon={<FontAwesomeIcon icon={faPenLine} size='xs' />}
                onClick={onManageSubscription}
                isLoading={loading}
              >
                Manage my subscription
              </Button>
            </Box>
          )}
        </Flex>
        <Flex px={{ base: 4, lg: 6 }} py='4' backgroundColor='gray.50'>
          <Box w='3xs'>
            <Text fontWeight={400} fontSize='md' color='gray.800'>
              Tours included
            </Text>
          </Box>
          <Box>
            <Text fontWeight={600} fontSize='md' color='gray.900'>
              {`${planUsage.toursIncluded} ${
                planUsage.isFree ? 'tour' : 'tours'
              }`}
            </Text>
          </Box>
        </Flex>
        <Flex px={{ base: 4, lg: 6 }} py='4' backgroundColor='gray.50'>
          <Box w='3xs'>
            <Text fontWeight={400} fontSize='md' color='gray.800'>
              Users
            </Text>
          </Box>
          <Box>
            <Text fontWeight={600} fontSize='md' color='gray.900'>
              {`${planUsage.usersIncluded} user${
                planUsage.usersIncluded > 1 ? 's' : ''
              }`}
            </Text>
          </Box>
        </Flex>
        <Flex px={{ base: 4, lg: 6 }} py='4'>
          <Box w='3xs'>
            <Text fontWeight={400} fontSize='md' color='gray.800'>
              Current plan
            </Text>
          </Box>
          <Box>{planUsage.name}</Box>
        </Flex>
      </Box>
      <Box
        w='100%'
        maxW={'4xl'}
        bg='white'
        boxShadow={{
          lg: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }}
        rounded='xl'
        position='relative'
        borderTopWidth={{ lg: 2, base: 0 }}
        borderTopColor='blue.500'
      >
        <VStack
          w='full'
          align='flex-start'
          spacing={{ base: 6, lg: 0 }}
          p={{ base: 4, lg: 6 }}
        >
          <Box>
            <Text color='gray.900' fontWeight={700} fontSize='lg'>
              Upgrade your plan
            </Text>
          </Box>
          <PlanSelect />
        </VStack>
      </Box>
    </VStack>
  )
}

export default Billing
