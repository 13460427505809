import { query, collection, getDocs } from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { receiveSlots } from 'model/actions'
import { SlotT } from 'shared/types/model'
import { dbGetLastItemBySlot } from 'controllers/items'

export const fetchSlots = async () => {
  try {
    const q = query(collection(db, 'slots'))
    const sn = await getDocs(q)
    const slotsWithLastItems = await Promise.all(
      _.map(sn.docs, async doc => {
        const s = doc.data() as SlotT
        const lastItemId = await dbGetLastItemBySlot(s.id)
        return { ...s, lastItemId }
      })
    )
    store.dispatch(receiveSlots(_.keyBy(slotsWithLastItems, 'id')))
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchSlots error', e)
  }
}
