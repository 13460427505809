import {
  Alert,
  HStack,
  VStack,
  AlertTitle,
  AlertDescription,
  Box,
  CloseButton
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FC } from 'react'

type Props = {
  email: string
  onClose: () => void
}

const PasswordResetedAlert: FC<Props> = ({ email, onClose }) => {
  return (
    <Alert status='info' rounded={'md'} mb={8}>
      <HStack color='blue.600' spacing={4}>
        <FontAwesomeIcon icon={faInfoCircle} fontSize='1.5em' />
        <VStack align='flex-start' color='gray.700' spacing={1}>
          <AlertTitle>Your password has been reset</AlertTitle>
          <AlertDescription fontSize={'sm'} lineHeight={1.4}>
            Please check the email address {email} for instructions to reset
            your password.
          </AlertDescription>
        </VStack>
        <Box>
          <CloseButton color='gray.600' onClick={onClose} />
        </Box>
      </HStack>
    </Alert>
  )
}

export default PasswordResetedAlert
