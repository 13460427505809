import { Flex, Box } from '@chakra-ui/react'
import _ from 'lodash'
import { Outlet, useSearchParams } from 'react-router-dom'
import DashboardSidebar from 'pages/dashboard/DashboardSidebar'
import Loading from 'pages/Loading'

const DashboardWrapper = () => {
  const [searchParams] = useSearchParams()
  const priceId = searchParams.get('p')
  if (_.isNil(priceId)) {
    return (
      <Flex h='full' w='full' bg={'gray.100'} direction='row'>
        <Box h='full' w={72} flexShrink={0}>
          <DashboardSidebar />
        </Box>
        <Flex h='full' w='full' bg='gray.100' overflow={'hidden'}>
          <Outlet />
        </Flex>
      </Flex>
    )
  } else {
    return <Loading />
  }
}

export default DashboardWrapper
