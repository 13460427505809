import { FC, useState } from 'react'
import {
  Avatar,
  HStack,
  VStack,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import {
  faArrowsRepeat,
  faChevronDown,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons'
import { faBolt, faPowerOff, faGear } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { useSelector } from 'model/hooks'
import { useNavigate } from 'react-router-dom'
import { dbSignOut, switchAccount } from 'controllers/auth'
// import { auth } from 'controllers/db'
import { AccountProfileT } from 'types/model'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import AccountProfileButton from 'components/sidebar/AccountProfileButton'

type Props = {
  onUpgradeClick: () => void
}

const AccountButton: FC<Props> = ({ onUpgradeClick }) => {
  const account = useSelector(state => state.account)
  const userAccounts = useSelector(state => state.userAccounts)
  const userProfile = useSelector(getCurrentUserProfile)
  const accountProfile = useSelector(state => state.accountProfile)
  const subscription = useSelector(state => state.subscription)
  const unlimitedPlans = useSelector(state => state.partner?.unlimitedPlans || false)
  const navigate = useNavigate()
  const [menuKey, setMenuKey] = useState(_.now())

  const switchAccountPopover = () => {
    return (
      <Menu placement='right-end'>
        <MenuButton _hover={{ bg: 'gray.100' }} w='full'>
          <MenuItem
            icon={<FontAwesomeIcon icon={faArrowsRepeat} fixedWidth />}
            fontWeight='semibold'
            as='div'
          >
            <HStack justify={'space-between'}>
              <Text>Switch account</Text>
              <FontAwesomeIcon icon={faChevronRight} />
            </HStack>
          </MenuItem>
        </MenuButton>
        <MenuList color={'gray.500'}>
          {_.map(userAccounts, (v: AccountProfileT) => (
            <MenuItem
              key={v.id}
              onClick={() => switchAccount(v.id, navigate)}
              isDisabled={v.id === account?.id}
            >
              <AccountProfileButton
                label={v.name || 'Unnamed'}
                avatar={v.logoUrl}
              />
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    )
  }

  const menuItems = (
    <MenuList color={'gray.500'}>
      {_.isNil(subscription) && !unlimitedPlans && (
        <MenuItem
          icon={<FontAwesomeIcon icon={faBolt} fixedWidth />}
          fontWeight='semibold'
          onClick={() => {
            setMenuKey(_.now())
            onUpgradeClick()
          }}
        >
          Upgrade
        </MenuItem>
      )}
      <MenuItem
        icon={<FontAwesomeIcon icon={faGear} fixedWidth />}
        fontWeight='semibold'
        onClick={() => navigate('/settings')}
      >
        Settings
      </MenuItem>
      {_.size(userProfile?.accounts) > 1 && switchAccountPopover()}
      <MenuItem
        icon={<FontAwesomeIcon icon={faPowerOff} fixedWidth />}
        fontWeight='semibold'
        onClick={() => dbSignOut(navigate)}
      >
        Log out
      </MenuItem>
    </MenuList>
  )

  return (
    <Menu key={menuKey}>
      <MenuButton w={'full'} px={4} my={0}>
        <HStack w='full' flexGrow={0} flexShrink={0}>
          <Avatar
            size='sm'
            name={userProfile?.name}
            src={userProfile?.avatar}
            color='white'
            bg='teal.700'
          />
          <VStack align='flex-start' spacing={0} w='full' overflow={'hidden'}>
            <Text
              fontSize={'sm'}
              fontWeight='medium'
              color='black'
              noOfLines={1}
              wordBreak='break-all'
            >
              {_.get(userProfile, 'name')}{' '}
              {accountProfile && _.size(accountProfile.name) > 0 && (
                <Text color='gray.500' as='span'>
                  @{_.get(accountProfile, 'name', '')}
                </Text>
              )}
            </Text>
            <Text
              fontSize={'xs'}
              color='gray.500'
              noOfLines={1}
              wordBreak='break-all'
            >
              {_.trim(_.get(userProfile, 'email', ''))}
            </Text>
          </VStack>
          <Box color='gray.500' fontSize={'0.8em'}>
            <FontAwesomeIcon icon={faChevronDown} />
          </Box>
        </HStack>
      </MenuButton>
      {menuItems}
    </Menu>
  )
}

export default AccountButton
