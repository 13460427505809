import { FC, useEffect, useRef, useState, ChangeEvent } from 'react'
import {
  HStack,
  VStack,
  Input,
  Text,
  Tooltip,
  useClipboard,
  Button,
  Menu,
  MenuList,
  MenuItem,
  IconButton,
  MenuButton
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLink,
  faEllipsisH,
  faInputText,
  faTrashCan,
  faCouch,
  faShare
} from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router'

import { useSelector } from 'model/hooks'
import config from 'shared/config'
import { TourModelT } from 'types/model'
import { dbDeleteTourModel, dbUpdateTourModel } from 'controllers/tourModels'
import { getAddress } from 'shared/utils/addressUtils'
import { dbUpdateUser } from 'controllers/user'

type Props = {
  tm: TourModelT
  onCreateDesign: () => void
  onShare: () => void
}

const TourModelNavBar: FC<Props> = ({ tm, onCreateDesign, onShare }) => {
  const [_label, _setLabel] = useState<string>(tm.name || '')
  const labelRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const { onCopy, setValue } = useClipboard(`${config.userAppUrl}/${tm.id}`)
  const user = useSelector(state => state.user)

  useEffect(() => {
    _setLabel(tm.name || '')
  }, [tm.name])

  useEffect(() => {
    setValue(`${config.userAppUrl}/${tm.id}`)
  }, [tm.id])

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    // if (error) setError(false)
    _setLabel(e.target.value)
  }

  const saveLabel = () => {
    dbUpdateTourModel(tm.id, { name: _label })
  }

  const onDeleteTourModel = () => {
    console.log('onDeleteTour')
    dbDeleteTourModel(tm.id)
    navigate('/tours')
  }

  const handleCopy = () => {
    onCopy()
    if (user?.id) {
      const heapCounters = { ...user?.heapCounters, linkCopied: (user?.heapCounters?.linkCopied || 0) + 1 }
      dbUpdateUser(user.id, { heapCounters })
    }
  }

  const menuList = () => {
    return (
      <MenuList zIndex='101'>
        <MenuItem
          color={'gray.500'}
          icon={<FontAwesomeIcon icon={faLink} fixedWidth />}
          onClick={handleCopy}
        >
          <Text color='gray.700' fontWeight={'semibold'}>
            Copy link
          </Text>
        </MenuItem>
        <MenuItem
          color={'gray.500'}
          icon={<FontAwesomeIcon icon={faInputText} fixedWidth />}
          onClick={() => labelRef.current?.focus()}
        >
          <Text color='gray.700' fontWeight={'semibold'}>
            Rename tour
          </Text>
        </MenuItem>
        <MenuItem
          color={'red.500'}
          icon={<FontAwesomeIcon icon={faTrashCan} fixedWidth />}
          onClick={onDeleteTourModel}
        >
          <Text color='red.500' fontWeight={'semibold'}>
            Delete tour
          </Text>
        </MenuItem>
      </MenuList>
    )
  }

  const renderMenu = () => {
    return (
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FontAwesomeIcon icon={faEllipsisH} />}
          variant={'ghost'}
          size='lg'
        />
        {menuList()}
      </Menu>
    )
  }

  const renderButtons = () => {
    return (
      <HStack spacing={4}>
        <Tooltip label={'Share the tour with customers'} hasArrow>
          <Button
            variant={'outline'}
            size='lg'
            onClick={onShare}
            leftIcon={<FontAwesomeIcon icon={faShare} />}
          >
            Share
          </Button>
        </Tooltip>
        <Tooltip label={'Create new design'} hasArrow>
          <Button
            size='lg'
            variant='solid'
            colorScheme='blue'
            onClick={onCreateDesign}
            leftIcon={<FontAwesomeIcon icon={faCouch} />}
          >
            Upstage
          </Button>
        </Tooltip>
        {renderMenu()}
      </HStack>
    )
  }

  return (
    <HStack
      px='4'
      w='full'
      align='center'
      justify='space-between'
      h='28'
      flexShrink={0}
      bg='white'
      borderLeftWidth={1}
      borderLeftColor='gray.50'
      borderBottomWidth={1}
      borderBottomColor='gray.100'
    >
      <HStack spacing={4} w='full'>
        {/* <Image
          src={tm.photoUrl}
          rounded={'xl'}
          w='20'
          h='20'
          objectFit={'cover'}
        /> */}
        <VStack spacing={0} align='flex-start' w='full' pt={0}>
          <Input
            name='tourname'
            role='presentation'
            type='text'
            ref={labelRef}
            variant='unstyled'
            autoComplete='off'
            value={_label}
            onChange={onChange}
            placeholder='Name your tour'
            fontSize='2xl'
            fontWeight={600}
            w='100%'
            color='gray.700'
            _placeholder={{
              color: 'gray.300',
              fontSize: '2xl'
            }}
            onBlur={saveLabel}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault()
                labelRef.current?.blur()
              }
            }}
          />
          <Text fontSize={'sm'} color='gray.600'>
            {tm.address ? getAddress(tm.address) : ''}
          </Text>
        </VStack>
      </HStack>
      {renderButtons()}
    </HStack>
  )
}

export default TourModelNavBar
