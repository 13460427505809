import { ReactComponent as Logo } from 'shared/assets/logo.svg'
import { Box, VStack, Image, Heading, Text, HStack } from '@chakra-ui/react'
import { FC } from 'react'
import { useSelector } from 'model/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'

type Props = {
  title: string
  description: string
}

const AuthHeader: FC<Props> = ({ title, description }) => {
  const partner = useSelector(state => state.partner)

  const renderPartnerLogo = () => {
    if (partner && partner.logoUrl) {
      return (
        <>
          <Image src={partner.logoUrl} w='142px' mt={2} />
          <FontAwesomeIcon icon={faPlus} />
        </>
      )
    }
  }

  return (
    <VStack spacing={4} px={{ base: 4, lg: 0 }}>
      <HStack align='center' spacing={6}>
        {renderPartnerLogo()}
        {/* <Button variant='link' as='a' href='https://upstager.co/'> */}
        <Box w='162px'>
          <Logo width='162px' />
        </Box>
        {/* </Button> */}
      </HStack>
      <Heading size='lg' pt={2} textAlign='center'>
        {title}
      </Heading>
      <Text color='gray.600' fontSize='md' pb={5} textAlign='center' maxW='xl'>
        {_.get(partner, 'authPageText', description)}
      </Text>
    </VStack>
  )
}

export default AuthHeader
