import { FC } from 'react'
import { VStack } from '@chakra-ui/react'
import {
  faArrowUpRightFromSquare,
  faCouch,
  faPenRuler
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RightPanelButton from 'pages/tourModel/RightPanelButton'

type Props = {
  onCreateDesign: () => void
  onPreviewClick: () => void
  onRequestClick: () => void
}

const RightPanel: FC<Props> = ({
  onCreateDesign,
  onPreviewClick,
  onRequestClick
}) => {
  return (
    <VStack bg='white' h='full' w='80' p={2}>
      <RightPanelButton
        title='Create new design'
        description='Upstage this space yourself'
        icon={<FontAwesomeIcon icon={faCouch} size='1x' fixedWidth />}
        onClick={onCreateDesign}
        id='customer-right-panel-create-design-button'
      />
      <RightPanelButton
        title='Preview tour'
        description='View the tour the way your clients will see it'
        onClick={onPreviewClick}
        icon={
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            size='1x'
            fixedWidth
          />
        }
      />
      <RightPanelButton
        title='Request design'
        description='Interior designers will upstage this space for you'
        onClick={onRequestClick}
        icon={<FontAwesomeIcon icon={faPenRuler} size='1x' fixedWidth />}
      />
    </VStack>
  )
}

export default RightPanel
