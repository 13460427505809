import { AccountT, CustomerT, NoTourAccountDataT } from 'types/model'
import _ from 'lodash'
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where
} from 'firebase/firestore'
import * as Sentry from '@sentry/react'
import { db } from 'controllers/db'
import store from 'model/store'
import { receiveAccount, receiveNoTourAccountData, receiveUserAccounts } from 'model/actions'
import { addListener } from 'controllers/listeners'
import { getRequestHeaders } from 'controllers/headers'
import config from 'shared/config'

export const fetchAccount = async (accountId: string) => {
  console.log('fetch account', accountId)
  try {
    const unsubscribe = onSnapshot(
      doc(db, 'accounts', accountId),
      accountSN => {
        if (accountSN.exists()) {
          const account = accountSN.data() as AccountT
          store.dispatch(receiveAccount(account))
        } else {
          store.dispatch(receiveAccount(null))
        }
      },
      err => {
        console.log('fetch account error', err)
        Sentry.captureMessage('fetch account error')
        Sentry.captureException(err)
      }
    )
    addListener('account', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch account error', e)
  }
}

export const fetchNoTourData = async (accountId: string) => {
  console.log('fetch no tour account data', accountId)
  try {
    const unsubscribe = onSnapshot(
      doc(db, 'noTourAccounts', accountId),
      accountSN => {
        if (accountSN.exists()) {
          const account = accountSN.data() as NoTourAccountDataT
          store.dispatch(receiveNoTourAccountData(account))
        } else {
          store.dispatch(receiveNoTourAccountData(null))
        }
      },
      err => {
        console.log('fetch no tour account data error', err)
        Sentry.captureMessage('fetch no tour account data error')
        Sentry.captureException(err)
      }
    )
    addListener('noTourAccountData', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch no tour account data error', e)
  }
}

export const fetchUserAccounts = async (user: CustomerT): Promise<void> => {
  try {
    if (!_.isNil(user)) {
      const members = _.get(user, 'adminOfAccounts')
      const chunks = _.chunk(members, 10)
      const qAr = await Promise.all(
        _.map(chunks, async ids => {
          const qSN = await getDocs(
            query(collection(db, 'accountsProfiles'), where('id', 'in', ids))
          )
          return _.map(qSN.docs, doc => {
            const p = doc.data() as CustomerT
            return p
          })
        })
      )
      const res = _.keyBy(_.flatten(qAr), 'id')
      store.dispatch(receiveUserAccounts(res))
    } else {
      console.log('fetchUserAccounts error: adminsOfAccounts is missing')
      Sentry.captureMessage(
        'fetchUserAccounts error: adminsOfAccounts is missing'
      )
    }
  } catch (e) {
    console.log('fetchUserAccounts error', e)
    Sentry.captureException(e)
  }
}

export const resendVerificationEmail = async (): Promise<boolean> => {
  try {
    const url = `${config.backendUrl}/customer/send-verification-email`
    const headers = await getRequestHeaders()
    const req = await fetch(url, {
      method: 'GET',
      headers
    })
    if (req.status >= 300 || req.status < 200) {
      Sentry.captureException(new Error('resendVerificationEmail is not sent'))
      return false
    } else {
      return true
    }
  } catch (e) {
    console.log('resendVerificationEmail error', e)
    Sentry.captureException(e)
    return false
  }
}

export const dbRegisterUser = async (): Promise<boolean> => {
  try {
    console.log('call dbRegisterUser')
    const url = `${config.backendUrl}/customer/register_user`
    const headers = await getRequestHeaders()
    const req = await fetch(url, {
      method: 'GET',
      headers
    })
    if (req.status >= 300 || req.status < 200) {
      Sentry.captureException(new Error('dbRegisterUser is not sent'))
      return false
    } else {
      return true
    }
  } catch (e) {
    console.log('dbRegisterUser error', e)
    Sentry.captureException(e)
    return false
  }
}
