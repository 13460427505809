import { useRef, useMemo } from 'react'
import {
  Flex,
  Wrap,
  HStack,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels
} from '@chakra-ui/react'
import _ from 'lodash'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useSelector } from 'model/hooks'
import { getToursByModel } from 'model/selectors/tours'
import Loading from 'pages/Loading'
import TourModelEmptyState from 'pages/tourModel/TourModelEmptyState'
import TourModelNavBar from 'pages/tourModel/TourModelNavBar'
import CreateTourModal, { ICreateTourModal } from 'modals/CreateTourModal'
import { RequestDesignT, TourT } from 'shared/types/model'
import TourDesignCard from 'pages/tourModel/TourDesignCard'
import { getRequestsDesignByTourModelId } from 'model/selectors/requestsDesign'
import RequestDesignCard from 'pages/tourModel/RequestDesignCard'
import RequestDesignModal, {
  IRequestDesignModal
} from 'modals/RequestDesignModal'
import RightPanel from 'pages/tourModel/RightPanel'
import config from 'shared/config'
import Space from 'pages/tourModel/Space'
import ShareTourModal, { IShareTourModal } from 'modals/ShareTourModal'
import TourDetails from 'pages/tourModel/TourDetails'
import ShareDesignModal, { IShareDesignModal } from 'modals/ShareDesignModal'

const TourModel = () => {
  const { tourModelId } = useParams() as { tourModelId: string }
  const location = useLocation()
  const navigate = useNavigate()
  const shareTourModalRef = useRef<IShareTourModal>(null)
  const shareDesignModalRef = useRef<IShareDesignModal>(null)
  const tourModel = useSelector(state => _.get(state.tourModels, tourModelId))
  const toursByModel = useSelector(getToursByModel)
  const createTourModalRef = useRef<ICreateTourModal>(null)
  const requestDesignModalRef = useRef<IRequestDesignModal>(null)
  const requestsDesignByTourModelId = useSelector(
    getRequestsDesignByTourModelId
  )

  const items = useMemo(() => {
    const tours = _.values(_.get(toursByModel, tourModelId))
    const rds = _.values(_.get(requestsDesignByTourModelId, tourModelId))
    const ar: (TourT | RequestDesignT)[] = [...tours, ...rds]
    return _.orderBy(ar, 'createdAt', 'desc')
  }, [tourModelId, toursByModel, requestsDesignByTourModelId])

  const onCreateTourClick = () => {
    // console.log('onCreateTourClick')
    createTourModalRef.current?.open(tourModelId)
  }

  const onRequestDesign = () => {
    // console.log('onRequestDesign')
    requestDesignModalRef.current?.open(tourModelId)
  }

  const onShareDesign = (tourId: string) => {
    shareDesignModalRef.current?.open(tourModelId, tourId)
  }

  const renderTourCard = (t: TourT, index: number) => {
    if (tourModel) {
      return <TourDesignCard key={t.id} tm={tourModel} t={t} index={index} onShare={onShareDesign} />
    }
  }

  const renderRDCard = (rd: RequestDesignT) => {
    if (tourModel && !rd.completed) {
      return <RequestDesignCard key={rd.id} tm={tourModel} rd={rd} />
    }
  }

  const renderDesigns = () => {
    if (_.isEmpty(items)) {
      return (
        <TourModelEmptyState
          onCreate={onCreateTourClick}
          onRequest={onRequestDesign}
        />
      )
    } else {
      return (
        <Flex p={4} overflowY='auto' h='full' w='full'>
          <Wrap spacing='8' overflowY='auto'>
            {_.map(items, (item, index) => {
              if (_.has(toursByModel, [tourModelId, item.id])) {
                return renderTourCard(item as TourT, index)
              } else {
                return renderRDCard(item as RequestDesignT)
              }
            })}
          </Wrap>
        </Flex>
      )
    }
  }
  console.log('location', location)

  const renderContent = () => {
    return (
      <Tabs
        w='full'
        h='full'
        flex={1}
        overflow={'hidden'}
        display={'flex'}
        flexDir='column'
        index={_.get(location, 'state.tab', 0)}
        onChange={(index: number) =>
          navigate(`/tour/${tourModelId}`, { state: { tab: index } })
        }
        isLazy
      >
        <TabList px={4} border='none'>
          <Tab>Space</Tab>
          <Tab>Designs {_.size(_.get(toursByModel, tourModelId))}</Tab>
          <Tab>Details</Tab>
        </TabList>

        <TabPanels overflow={'hidden'} display='flex' flex={1}>
          <TabPanel flex={1} h='full' overflow={'hidden'}>
            {tourModel && <Space modelId={tourModel.modelId} />}
          </TabPanel>
          <TabPanel flex={1}>{renderDesigns()}</TabPanel>
          <TabPanel>
            {tourModel && <TourDetails tourModel={tourModel} />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    )
  }

  const renderRightPanel = () => {
    return (
      <RightPanel
        onCreateDesign={() => createTourModalRef.current?.open(tourModelId)}
        onPreviewClick={() =>
          window.open(`${config.userAppUrl}/${tourModelId}`, '_blank')
        }
        onRequestClick={() => requestDesignModalRef.current?.open(tourModelId)}
      />
    )
  }

  if (!tourModel) {
    return <Loading />
  } else {
    return (
      <Flex
        direction='column'
        h='full'
        w='full'
        bg='gray.50'
        overflow={'hidden'}
      >
        <TourModelNavBar
          tm={tourModel}
          onCreateDesign={() => createTourModalRef.current?.open(tourModelId)}
          onShare={() => shareTourModalRef.current?.open(tourModelId)}
        />
        <HStack w='full' h='full' overflow={'hidden'} spacing={0}>
          {renderContent()}
          {renderRightPanel()}
        </HStack>
        <CreateTourModal ref={createTourModalRef} />
        <RequestDesignModal ref={requestDesignModalRef} />
        <ShareTourModal ref={shareTourModalRef} />
        <ShareDesignModal ref={shareDesignModalRef} />
      </Flex>
    )
  }
}

export default TourModel
