import { FC, useEffect, useMemo, useState, useRef } from 'react'
import _ from 'lodash'

import { ModelT } from 'shared/types/model'
import { MpSdk, Mode } from 'shared/bundle/sdk'
import BoxSimple from 'shared/components/BoxSimple'
import Item from 'shared/components/Item'
import { Vector3Tuple, Vector3 } from 'three'
import SceneClickListener from 'shared/components/SceneClickListener'
// import MoveButton from 'shared/components/MoveButton'

type Props = {
  tourId: string
  tourSlotId: string
  itemId: string
  model: ModelT
  slotSize: Vector3Tuple
  sdk: MpSdk
  slotPosition: Vector3
  onClick: (slotId: string) => void
  isSelected?: boolean
  rotation?: number
  canMove?: boolean
  onMove?: (p: Vector3) => void
  mattertagId?: string
  viewMode: Mode.Mode
  isHovered: boolean
  onMattertagCreated: (tourSlotId: string, mtId: string) => void
  visible?: boolean
  onItemLoaded?: (id: string) => void
}

const TourItem: FC<Props> = ({
  tourSlotId,
  itemId,
  model,
  slotSize,
  sdk,
  slotPosition,
  // onClick,
  isSelected = false,
  rotation = 0,
  mattertagId,
  canMove = false,
  onMove,
  viewMode,
  // isHovered = false,
  onMattertagCreated,
  visible = true,
  onItemLoaded
}) => {
  // console.log('itemId', itemId, 'isSelected', isSelected)
  const poseSubscriptionRef = useRef<MpSdk.ISubscription | null>(null)
  const pointerSubscriptionRef = useRef<MpSdk.ISubscription | null>(null)
  const poseRef = useRef<MpSdk.Camera.Pose>()
  const [_slotPosition, _setSlotPosition] = useState<Vector3>(slotPosition)
  const slotPositionRef = useRef(slotPosition)
  const [boundingBox, setBoundingBox] = useState<Vector3 | undefined>(undefined)
  const [sceneObject, setSceneObject] = useState<MpSdk.Scene.IObject | null>(
    null
  )
  const viewModeRef = useRef(viewMode)
  // const [hovered, setHovered] = useState(false)
  // const hoverTimerRef = useRef<number>(0)

  useEffect(() => {
    viewModeRef.current = viewMode
  }, [viewMode])

  useEffect(() => {
    _setSlotPosition(slotPosition)
    slotPositionRef.current = slotPosition
  }, [slotPosition])

  // console.log('itemId', itemId, 'boundingBox', boundingBox)

  useEffect(() => {
    if (mattertagId && _slotPosition && boundingBox) {
      const anchorPosition = {
        x: _slotPosition.x,
        y: _slotPosition.y + _.get(boundingBox, 'y', 0) / 2,
        z: _slotPosition.z
      }
      sdk.Tag.editPosition(mattertagId, {
        anchorPosition,
        stemVector: {
          x: 0,
          y: 0.3,
          z: 0
        }
      })
    }
  }, [_slotPosition, boundingBox, mattertagId])

  const offsetV3 = useMemo(() => {
    const position = _.get(model, 'offset', [0, 0, 0])
    const v3 = new Vector3()
    v3.fromArray(position)
    return v3
  }, [model.offset])

  useEffect(() => {
    const run = async () => {
      const [so]: MpSdk.Scene.IObject[] = await sdk.Scene.createObjects(1)
      setSceneObject(so)
    }
    run()
    return () => {
      sceneObject && sceneObject.stop()
    }
  }, [])

  const maxS = useMemo(() => {
    return _.max([slotSize[0], slotSize[2]]) || 1
  }, [slotSize])

  const multK = useMemo(() => {
    let res = 1.2
    if (maxS) {
      res = (maxS / 2) * 0.8
    }
    return res
  }, [maxS])

  const updateSlotPosition = (p: Vector3) => {
    console.log('update slot position', p)
    _setSlotPosition(p)
    slotPositionRef.current = p
  }

  useEffect(() => {
    if (canMove) {
      pointerSubscriptionRef.current = sdk.Pointer.intersection.subscribe(
        (intersectionData: MpSdk.Pointer.Intersection) => {
          if (poseRef.current) {
            switch (viewModeRef.current) {
              case sdk.Mode.Mode.INSIDE: {
                const intersP = new Vector3(
                  intersectionData.position.x,
                  intersectionData.position.y,
                  intersectionData.position.z
                )
                const v = new Vector3(
                  poseRef.current.position.x,
                  intersectionData.position.y,
                  poseRef.current.position.z
                )
                v.sub(intersP)
                v.normalize()
                v.multiplyScalar(multK)
                intersP.sub(v)
                updateSlotPosition(intersP)
                break
              }
              case sdk.Mode.Mode.FLOORPLAN: {
                const intersP = new Vector3(
                  intersectionData.position.x,
                  intersectionData.position.y,
                  intersectionData.position.z - maxS / 2
                )
                updateSlotPosition(intersP)
                break
              }
            }
          }
        }
      )
    } else {
      pointerSubscriptionRef.current?.cancel()
    }
  }, [canMove])

  useEffect(() => {
    if (canMove) {
      poseSubscriptionRef.current = sdk.Camera.pose.subscribe(function (pose) {
        poseRef.current = pose
      })
    } else if (poseSubscriptionRef.current) {
      const s = poseSubscriptionRef.current
      s.cancel()
    }
  }, [canMove])

  useEffect(() => {
    const run = async () => {
      const mtIds: string[] = await sdk.Tag.add({
        label: 'Custom',
        description: 'This tag was added through the Matterport SDK',
        anchorPosition: {
          x: 0,
          y: 0,
          z: 0
        },
        stemVector: {
          // make the Tag stick straight up and make it 0.30 meters (~1 foot) tall
          x: 0,
          y: 0.3,
          z: 0
        },
        color: {
          // blue disc
          r: 0.19,
          g: 0.51,
          b: 0.81
        }
      })
      const mtId = mtIds[0]
      if (mtId) {
        onMattertagCreated(tourSlotId, mtId)
      }
    }
    run()
    return () => {
      if (mattertagId) {
        sdk.Mattertag.remove(mattertagId)
      }
    }
  }, [])

  // const onSlotClick = () => {
  //   onClick(tourSlotId)
  // }

  const sceneClickListener = () => {
    console.log('slot position', slotPosition)
    if (slotPositionRef.current && onMove) {
      onMove(slotPositionRef.current)
    }
  }

  const modelRotation = useMemo(() => {
    const mr = _.get(model, 'rotation', [0, 0, 0])
    const rotationAxis = _.get(model, 'rotationAxis', 1)
    const res: Vector3Tuple = [mr[0], mr[1], mr[2]]
    res[rotationAxis] = res[rotationAxis] + rotation
    return res
  }, [model.rotation, rotation])

  const uniqKey = tourSlotId + '_' + itemId
  const boxUniqKey = 'box_' + tourSlotId + '_' + itemId

  // console.log('item is selected', uniqKey, isSelected)

  const onMoveComplete = (p: Vector3) => {
    updateSlotPosition(p)
    sceneClickListener()
  }

  // const onItemButtonClick = () => {
  //   console.log('on item button click')
  // }

  // const renderItemMoveButton = () => {
  //   if (sdk) {
  //     const itemKey = 'move_' + tourSlotId + '_' + itemId
  //     return (
  //       <MoveButton
  //         key={itemKey}
  //         sdk={sdk}
  //         itemKey={itemKey}
  //         position={
  //           new Vector3(
  //             slotPosition.x,
  //             slotPosition.y + slotSize[1] / 3,
  //             slotPosition.z
  //           )
  //         }
  //         size={1}
  //         initialPose={poseRef.current}
  //         onStartMoving={onSlotClick}
  //         viewMode={viewMode}
  //         visible={isHovered && !isSelected}
  //       />
  //     )
  //   }
  // }

  // const onHover = () => {
  //   if (hoverTimerRef.current > 0) {
  //     clearTimeout(hoverTimerRef.current)
  //   }
  //   setHovered(true)
  // }

  // const onBlur = () => {
  //   const t = window.setTimeout(() => setHovered(false), 500)
  //   hoverTimerRef.current = t
  // }

  const onItemClick = () => {
    if (viewMode === 'mode.inside') {
      sdk.Camera.moveInDirection('FORWARD' as MpSdk.Camera.Direction.FORWARD)
    }
  }

  if (sceneObject) {
    return (
      <>
        <Item
          key={uniqKey}
          id={tourSlotId}
          sdk={sdk}
          url={model.glb}
          scale={model.scale}
          position={_slotPosition}
          offset={offsetV3}
          rotation={modelRotation}
          onClick={onItemClick}
          sceneObject={sceneObject}
          showLoadingIndicator
          isSelected={isSelected}
          onMove={onMoveComplete}
          viewMode={viewMode}
          boundingBox={boundingBox}
          setBoundingBox={setBoundingBox}
          visible={visible}
          onItemLoaded={() => {
            if (onItemLoaded) {
              onItemLoaded(itemId)
            }
          }}
          // onHover={onHover}
          // onBlur={onBlur}
        />
        {isSelected && (
          <BoxSimple
            sdk={sdk}
            key={boxUniqKey}
            id={boxUniqKey}
            size={[slotSize[0], 0.01, slotSize[1]]}
            position={_slotPosition}
            visible={false}
            rotation={[0, rotation, 0]}
            sceneObject={sceneObject}
            itemOffset={offsetV3}
          />
        )}
        {/* {renderItemMoveButton()} */}
        {canMove && (
          <SceneClickListener sdk={sdk} onClick={sceneClickListener} />
        )}
      </>
    )
  } else {
    return null
  }
}

export default TourItem
