import { createReducer } from '@reduxjs/toolkit'
import { OnboardingT } from 'types/model'
import {
  updateOnboarding,
  resetOnboarding,
  oboardingAddTourSlots,
  onboardingResetDesign,
  onboardingUpdateTourSlot,
  onboardingDeleteTourSlot,
  initOnboarding
} from 'model/actions'
import _ from 'lodash'

const initialState = null as OnboardingT | null

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(initOnboarding, (state, action) => action.payload)
    .addCase(oboardingAddTourSlots, (state, action) => {
      _.forEach(
        action.payload,
        ts => state && _.set(state, ['tour', 'slots', ts.id], ts)
      )
    })
    .addCase(onboardingResetDesign, state => {
      state && _.set(state, ['tour', 'slots'], {})
    })
    .addCase(onboardingUpdateTourSlot, (state, action) => {
      state &&
        _.set(
          state,
          ['tour', 'slots', action.payload.slotId, action.payload.key],
          action.payload.value
        )
    })
    .addCase(onboardingDeleteTourSlot, (state, action) => {
      state && delete state.tour.slots[action.payload]
    })
    .addCase(updateOnboarding, (state, action) => {
      if (state === null) {
        return state
      } else {
        return {
          ...state,
          ...action.payload
        }
      }
    })
    .addCase(resetOnboarding, () => initialState)
})

export default reducer
