import { FC, useState, useRef, useEffect, ChangeEvent } from 'react'
import {
  Flex,
  WrapItem,
  Image,
  Text,
  VStack,
  Switch,
  Button,
  HStack,
  Tooltip,
  Menu,
  MenuList,
  MenuItem,
  IconButton,
  MenuButton,
  Input,
  Badge,
  Box
} from '@chakra-ui/react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faTrashCan,
  faInputText,
  faEllipsisH,
  faEye,
  faShareNodes
} from '@fortawesome/pro-regular-svg-icons'

import { TourModelT, TourT } from 'types/model'
import { noImageUrl } from 'shared/constants/helpers'
import { dbUpdateTourPath } from 'controllers/tours'
import { useNavigate } from 'react-router'
import moment from 'moment'
// import config from 'shared/config'

type Props = {
  tm: TourModelT
  t: TourT
  index: number
  onShare: (tourId: string) => void
}

const TourDesignCard: FC<Props> = ({ tm, t, index, onShare }) => {
  const [_name, _setName] = useState<string>(t.name || '')
  const nameRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  const onDeleteTourClick = () => {
    dbUpdateTourPath(t.id, 'deleted', _.now())
  }

  const onRenameDesignClick = () => {
    nameRef.current?.focus()
  }

  // const onPreviewDesignClick = () => {
  //   window.open(`${config.userAppUrl}/${tm.id}?d=${t.id}`, '_blank')
  // }

  useEffect(() => {
    _setName(t.name || '')
  }, [t.name])

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    _setName(e.target.value)
  }

  const saveName = () => {
    dbUpdateTourPath(t.id, 'name', _name)
  }

  const renderMenu = () => {
    return (
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FontAwesomeIcon icon={faEllipsisH} />}
          variant='unstyled'
          colorScheme='blackAlpha'
          size='md'
        />
        <MenuList zIndex='101'>
          <MenuItem
            color='gray.500'
            icon={<FontAwesomeIcon icon={faShareNodes} fixedWidth />}
            onClick={() => onShare(t.id)}
          >
            <Text color='gray.700' fontWeight='semibold'>
              Share Static Design
            </Text>
          </MenuItem>
          <MenuItem
            color='gray.500'
            icon={<FontAwesomeIcon icon={faInputText} fixedWidth />}
            onClick={onRenameDesignClick}
          >
            <Text color='gray.700' fontWeight='semibold'>
              Rename design
            </Text>
          </MenuItem>
          <MenuItem
            color='red.500'
            icon={<FontAwesomeIcon icon={faTrashCan} fixedWidth />}
            onClick={onDeleteTourClick}
          >
            <Text color='red.500' fontWeight='semibold'>
              Delete design
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    )
  }

  const renderBadge = () => {
    if (t.isCustom) {
      return (
        <Badge
          variant='subtle'
          colorScheme='green'
          fontSize='0.6em'
          ml={4}
          mt={2}
        >
          Custom
        </Badge>
      )
    } else {
      return <Box />
    }
  }

  const renderRightTopElements = () => {
    if (t.isCustom) {
      return (
        <HStack pr={2} pt={2}>
          <Text color='white' fontSize='sm' fontWeight='medium'>
            {t.email}
          </Text>
        </HStack>
      )
    } else {
      return (
        <HStack justify='center' id={`tour-switch-${index}`}>
          <Tooltip
            label='Enable the design to make it available for users'
            placement='bottom'
            shouldWrapChildren
          >
            <Switch
              colorScheme='teal'
              isChecked={t.enabled}
              sx={{
                span: {
                  boxShadow: 'rgba(0, 0, 0, 0.2) 0.5px 0.5px 0.5px 0.5px'
                }
              }}
              onChange={v =>
                dbUpdateTourPath(t.id, 'enabled', v.target.checked)
              }
            />
          </Tooltip>
          {renderMenu()}
        </HStack>
      )
    }
  }

  return (
    <WrapItem key={t.id} w='80' h='60' position='relative' rounded='xl'>
      <Image
        src={tm.photoUrl || noImageUrl}
        rounded='xl'
        w='full'
        h='full'
        objectFit='cover'
      />
      <Flex
        w='full'
        h='full'
        position='absolute'
        left={0}
        top={0}
        rounded='xl'
        direction='column'
        justify='space-between'
      >
        <Flex w='full' px={2} justify='space-between' align='center'>
          {renderBadge()}
          {renderRightTopElements()}
        </Flex>
        <HStack
          flex={1}
          rounded='xl'
          bgGradient='linear(to-b, #00000000, #000000aa)'
          justify='space-between'
          align='flex-end'
          px={4}
          py={6}
        >
          <HStack justify='space-between' align='flex-end' w='full'>
            <VStack spacing={0} align='flex-start'>
              <Input
                name='tourname'
                role='presentation'
                type='text'
                ref={nameRef}
                variant='unstyled'
                autoComplete='off'
                value={_name}
                onChange={onChange}
                placeholder='Name your tour'
                fontSize='lg'
                px={2}
                w='100%'
                _focus={{ bg: 'gray.500' }}
                color='white'
                _placeholder={{
                  color: 'gray.300',
                  fontSize: '2xl'
                }}
                onBlur={saveName}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault()
                    nameRef.current?.blur()
                  }
                }}
              />
              <Text
                px={2}
                color='white'
                fontWeight='normal'
                fontSize='xs'
                textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'
              >
                Edited: {moment(t.updatedAt || t.createdAt).format('lll')}
              </Text>
            </VStack>
            <Button
              variant='link'
              color='white'
              size='sm'
              leftIcon={<FontAwesomeIcon icon={t.isCustom ? faEye : faEdit} />}
              onClick={() => navigate(`/design/${t.id}`)}
            >
              {t.isCustom ? 'View' : 'Edit'}
            </Button>
          </HStack>
        </HStack>
      </Flex>
    </WrapItem>
  )
}

export default TourDesignCard
