import { useEffect, useRef, FC } from 'react'

import { MpSdk } from 'shared/bundle/sdk'

type AmbientLightProps = {
  sdk: MpSdk
}

const AmbientLight: FC<AmbientLightProps> = ({ sdk }) => {
  const nodeRef = useRef<MpSdk.Scene.INode | null>(null)
  useEffect(() => {
    const run = async () => {
      console.log('create ambient light', sdk)
      const sceneObjs: Array<MpSdk.Scene.IObject> =
        await sdk.Scene.createObjects(1)
      console.log('Ambient light scene Objs', sceneObjs)
      if (sceneObjs) {
        const node = sceneObjs[0].addNode()
        nodeRef.current = node
        const initial = {
          enabled: true,
          color: {
            r: 1,
            g: 1,
            b: 1
          },
          intensity: 1
          // intensity: 0.2
        }

        node.addComponent('mp.ambientLight', initial)
        // node.start()
      }
    }
    run()
    return () => {
      const node = nodeRef.current
      node?.stop()
    }
  }, [])

  return null
}

export default AmbientLight
