import { FC, MouseEvent } from 'react'
import { Image, IconButton } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  url: string
  onRemove: () => void
}

const PickedFile: FC<Props> = ({ url, onRemove }) => {
  const onRemoveButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onRemove()
  }

  return (
    <>
      <Image fit='cover' w='full' h='full' src={url} position='absolute' />
      <IconButton
        position={'absolute'}
        right={-3}
        top={-3}
        aria-label='delete image'
        icon={<FontAwesomeIcon icon={faTimes} />}
        rounded='full'
        size='xs'
        onClick={onRemoveButtonClick}
      />
    </>
  )
}

export default PickedFile
