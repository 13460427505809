import { useRef } from 'react'
import { Wrap, Flex } from '@chakra-ui/react'
import _ from 'lodash'
import CreateTourModal, { ICreateTourModal } from 'modals/CreateTourModal'
import { useSelector } from 'model/hooks'
import { TourModelT } from 'shared/types/model'
// import ToursEmptyState from 'pages/tours/ToursEmptyState'
import AddTourModal, { IAddTourModal } from 'modals/AddTourModal'
import { getTourModelsAlive } from 'model/selectors/tourModels'
import TourModelCard from 'pages/tours/toursContent/TourModelCard'
import EditTourModelModal, {
  IEditTourModelModal
} from 'modals/EditTourModelModal'
import { dbDeleteTourModel } from 'controllers/tourModels'
import ToursGettingStarted from 'pages/tours/toursContent/ToursGettingStarted'

const ToursContent = () => {
  const tourModels = useSelector(getTourModelsAlive)
  const createTourModalRef = useRef<ICreateTourModal>(null)
  const addTourModalref = useRef<IAddTourModal>(null)
  const editTourModelModalRef = useRef<IEditTourModelModal>(null)

  const onDelete = (tmId: string) => {
    dbDeleteTourModel(tmId)
  }

  const renderTMCard = (tm: TourModelT) => {
    return (
      <TourModelCard
        key={tm.id}
        tm={tm}
        onEdit={() => editTourModelModalRef.current?.open(tm.id)}
        onDelete={() => onDelete(tm.id)}
      />
    )
  }

  const renderContent = () => {
    // if (_.isEmpty(tourModels)) {
    //   return <ToursEmptyState onClick={() => addTourModalref.current?.open()} />
    // } else {
    return (
      <Wrap spacing='8' overflowY='auto' w='full'>
        {_.map(tourModels, renderTMCard)}
        <ToursGettingStarted />
      </Wrap>
    )
    // }
  }

  return (
    <Flex p={4} h='full'>
      {renderContent()}
      <CreateTourModal ref={createTourModalRef} />
      <AddTourModal ref={addTourModalref} />
      <EditTourModelModal ref={editTourModelModalRef} />
    </Flex>
  )
}

export default ToursContent
