// import { motion } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { FC, ReactNode, useState } from 'react'

type Props = {
  children: ReactNode
  isOpen: boolean
  width: number
}

const HorizontalCollapse: FC<Props> = ({ children, isOpen, width }) => {
  // const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure()
  const [hidden, setHidden] = useState(!isOpen)

  const spring = {
    type: 'ease',
    damping: 1,
    stiffness: 1
  }

  return (
    <motion.div
      // {...getDisclosureProps()}
      hidden={hidden}
      initial={false}
      onAnimationStart={() => setHidden(false)}
      onAnimationComplete={() => setHidden(!isOpen)}
      animate={{ width: isOpen ? width : 0 }}
      transition={spring}
      style={{
        height: '100%'
      }}
      // style={{
      //   background: 'red',
      //   overflow: 'hidden',
      //   whiteSpace: 'nowrap',
      //   position: 'absolute',
      //   right: '0',
      //   height: '100vh',
      //   top: '0'
      // }}
    >
      {children}
    </motion.div>
  )
}

export default HorizontalCollapse
