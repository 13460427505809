// import _ from 'lodash'
import { SceneComponent } from 'shared/components/SceneComponent'
import { MpSdk } from 'shared/bundle/sdk'
import { Object3D, Mesh, MeshBasicMaterial } from 'three'
import { degToRad } from 'three/src/math/MathUtils'

export interface IMCenterPointerInputs extends Record<string, unknown> {
  color: number
  opacity: number
}

class MCenterPointer extends SceneComponent {
  private root: Object3D | null = null
  private circle: Mesh | null = null

  inputs: IMCenterPointerInputs = {
    color: 0x008080,
    opacity: 0.3
  }

  makeCircle () {
    const THREE = this.context.three
    const geometry = new THREE.CircleGeometry(0.5, 32)
    const material = new THREE.MeshBasicMaterial({
      color: this.inputs.color,
      opacity: this.inputs.opacity,
      transparent: true,
      side: THREE.DoubleSide
    })
    this.circle = new THREE.Mesh(geometry, material)
    this.circle.rotateX(degToRad(90))
    if (this.root) {
      this.root?.add(this.circle)
    }
  }

  onInit () {
    const THREE = this.context.three
    this.root = new THREE.Object3D()
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root
    this.makeCircle()
  }

  onInputsUpdated (oldInputs: IMCenterPointerInputs) {
    if (oldInputs.color !== this.inputs.color && this.circle) {
      const m = this.circle.material as MeshBasicMaterial
      m.color.set(this.inputs.color)
    }
    if (oldInputs.opacity !== this.inputs.opacity && this.circle) {
      const m = this.circle.material as MeshBasicMaterial
      m.opacity = this.inputs.opacity
    }
  }

  onDestroy () {
    if (this.circle) {
      const m = this.circle.material as MeshBasicMaterial
      m && m.dispose()
      this.root?.remove(this.circle)
    }
  }
}

export const centerPointerType = 'mp.centerPointer'
export const makeCenterPointer = (): MpSdk.Scene.IComponent => {
  return new MCenterPointer()
}
