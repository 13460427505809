import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getProfiles, getUserAccounts, getUser } from 'model/selectors/base'
import { CustomerProfileT } from 'types/model'

export const getCurrentUserProfile = createSelector(
  [getUser, getProfiles],
  (user, profiles) => {
    // console.log('user', user, 'profiles', profiles)
    return user
      ? _.get(profiles, user.id)
      : (undefined as CustomerProfileT | undefined)
  }
)

export const getCurrentAccountProfile = createSelector(
  [getUser, getUserAccounts],
  (user, accProfiles) => {
    if (user) {
      const accId = user?.currentAccountId
      if (accId) {
        return accProfiles[accId]
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }
)
