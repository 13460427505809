import { FC, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { Flex } from '@chakra-ui/react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse } from '@fortawesome/pro-regular-svg-icons'
// import {
//   faLink,
//   faArrowUpRightFromSquare
// } from '@fortawesome/pro-light-svg-icons'
import { NavButton } from 'components/sidebar/NavButton'
import { TourModelT } from 'shared/types/model'
import { getAddress } from 'shared/utils/addressUtils'
// import { getFormLabel } from 'utils/form'
// import { useSelector } from 'model/hooks'
// import { logFormLinkCopied } from 'controllers/analytics'
// import DrawerContext from 'contexts/DrawerContext'

type Props = {
  tm: TourModelT,
  id: string
}

export const SideMenuNavButton: FC<Props> = ({ tm, id }) => {
  // const { closeDrawer } = useContext(DrawerContext)
  // const url = `${process.env.REACT_APP_VIEW_FORM_URL}/${tm?.id}`
  // const { hasCopied, onCopy } = useClipboard(url || '')
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = useMemo(() => location.pathname, [location.pathname])

  // const onPreviewClick = () => {
  //   window.open(`${process.env.REACT_APP_VIEW_FORM_URL}/${tm?.id}`, '_blank')
  // }

  // const copyLinkToClipboard = () => {
  //   // logFormLinkCopied(form.id)
  //   onCopy()
  // }

  const toForm = () => {
    // closeDrawer()
    // const raiseError = true
    // if (raiseError) {
    //   throw new Error('test customer app error')
    // }
    navigate(`/tour/${tm.id}`)
  }

  return (
    <NavButton
      id={id}
      isActive={_.includes(pathname, `/${tm.id as string}`)}
      key={tm.id as string}
      label={tm.name}
      description={tm.address ? getAddress(tm.address) : ''}
      color='gray.700'
      iconColor='gray.700'
      lineHeight='5'
      fontWeight='semibold'
      icon={faHouse}
      onClick={toForm}
      options={
        <Flex alignItems='center'>
          {/* {form?.previewUrl && (
            <Tooltip
              hasArrow
              label={hasCopied ? 'Link copied!' : 'Copy link'}
              placement='top'
            >
              <IconButton
                variant='unstyled'
                visibility='hidden'
                _groupHover={{ visibility: 'visible' }}
                aria-label='Copy Link'
                h='100%'
                m='0'
                size='xs'
                icon={
                  <FontAwesomeIcon
                    onClick={e => {
                      e.stopPropagation()
                      copyLinkToClipboard()
                    }}
                    color={'#4A5568'}
                    icon={faLink}
                    size={'lg'}
                  />
                }
              />
            </Tooltip>
          )} */}
          {/* <Tooltip hasArrow label='Open FaceFile in new tab' placement='top'>
            <IconButton
              variant='unstyled'
              visibility='hidden'
              _groupHover={{ visibility: 'visible' }}
              aria-label='Open FaceFile in new tab'
              h='100%'
              m='0'
              size='xs'
              icon={
                <FontAwesomeIcon
                  onClick={onPreviewClick}
                  color={'#4A5568'}
                  icon={faArrowUpRightFromSquare}
                  size={'lg'}
                />
              }
            />
          </Tooltip> */}
        </Flex>
      }
    />
  )
}

export default SideMenuNavButton
