import { FC } from 'react'
import { Flex, HStack, Button } from '@chakra-ui/react'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'

type Props = {
  onCancel: () => void
  onApply: () => void
  canApply: boolean
}

const AddingItemPanel: FC<Props> = ({ onCancel, onApply, canApply }) => {
  return (
    <Flex position='absolute' top='0' right='0'>
      <RightPanelWrapper
        show
        // width={300}
        fullHeight={false}
        bg='transparent'
        hideBorder
      >
        <HStack py={2} pr={4} spacing={4} justify='flex-end'>
          <Button onClick={() => onCancel()} colorScheme='gray' w={'28'}>
            Cancel
          </Button>
          <Button
            colorScheme={'blue'}
            onClick={() => onApply()}
            isDisabled={!canApply}
            w={'28'}
          >
            Done
          </Button>
        </HStack>
      </RightPanelWrapper>
    </Flex>
  )
}

export default AddingItemPanel
