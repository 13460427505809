import _ from 'lodash'
import { AddressT } from 'shared/types/model'

export const statesDict = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY'
}

export const stateByAbbr = _.reduce(
  statesDict,
  (res, abbr, name) => ({ ...res, [abbr]: name }),
  {}
)

export const getStateAbbr = (address: AddressT) => {
  if (_.has(address, 'stateAbbr')) return address.stateAbbr
  const st = _.get(address, 'state')
  if (st) {
    return _.get(statesDict, st)
  } else {
    return undefined
  }
}

export const getAddress = (address: AddressT) => {
  const street = _.get(address, 'structured.main', '')
  const city = _.get(address, 'city', '')
  const state = getStateAbbr(address)
  const zip = _.get(address, 'zipcode', '')
  const res = []
  const firstPath = `${street} ${city}`
  const secondPath = `${state} ${zip}`
  if (!_.isEmpty(_.trim(firstPath))) res.push(firstPath)
  if (!_.isEmpty(_.trim(secondPath))) res.push(secondPath)
  return res.join(', ')
}

// returns address format: Austin, TX 93281
export const getAddressLine2 = (address: AddressT) => {
  const city = _.get(address, 'city', '')
  const state = getStateAbbr(address)
  const zip = _.get(address, 'zipcode', '')
  const ar = _.filter([city, `${state} ${zip}`], v => !_.isEmpty(v))
  return _.join(ar, ', ')
}

export default {
  statesDict
}
