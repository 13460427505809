import { createReducer } from '@reduxjs/toolkit'
import { DictT, ItemT } from 'types/model'
import { receiveItems, clear, logout, clearItems } from 'model/actions'

const initialState = {} as DictT<ItemT>

const itemsReducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveItems, (state, action) => ({ ...state, ...action.payload }))
    .addCase(clearItems, () => initialState)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default itemsReducer
