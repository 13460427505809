import { FC, useEffect, useState, useMemo, useRef } from 'react'
import _ from 'lodash'
import { MpSdk, Mode } from 'shared/bundle/sdk'
import Item from 'shared/components/Item'
import { Vector3, Vector3Tuple } from 'three'
import SceneClickListener from 'shared/components/SceneClickListener'
import { ItemT, SlotT } from 'shared/types/model'
import AddingItemPanel from 'shared/components/AddingItemPanel'
import RotateControlSlider from 'shared/components/RotateControlSlider'
import ModelIntersection from 'shared/components/ModelIntersection'

type Props = {
  itemId: string
  item: ItemT
  slot: SlotT
  sdk: MpSdk
  onAddItem: (p: Vector3, rotation: number) => void
  viewMode: Mode.Mode
  onCancel: () => void
}

const AddingItem: FC<Props> = ({
  itemId,
  sdk,
  onAddItem,
  item,
  slot,
  viewMode,
  onCancel
}) => {
  const poseRef = useRef<MpSdk.Camera.Pose>()
  const [slotPosition, setSlotPosition] = useState<Vector3 | null>(null)
  const slotPositionRef = useRef(slotPosition)
  // const pointerIntersectionUnsubscribeRef = useRef<MpSdk.ISubscription>(null)
  const [sceneObject, setSceneObject] = useState<MpSdk.Scene.IObject | null>(
    null
  )
  const uniqKey = 'add_item_' + itemId
  const model = _.get(item, 'model')
  const viewModeRef = useRef(viewMode)
  const [rotation, setRotation] = useState(0)
  const [isAdded, setIsAdded] = useState(false)
  const defaultPosition = new Vector3(1000, 0, 0)

  useEffect(() => {
    viewModeRef.current = viewMode
  }, [viewMode])

  useEffect(() => {
    const run = async () => {
      const [so]: Array<MpSdk.Scene.IObject> = await sdk.Scene.createObjects(1)
      setSceneObject(so)
    }
    run()
    return () => sceneObject?.stop()
  }, [])

  const offsetV3 = useMemo(() => {
    const model = item?.model
    const position = _.get(model, 'offset', [0, 0, 0])
    const v3 = new Vector3()
    v3.fromArray(position)
    return v3
  }, [item])

  const modelRotation = useMemo(() => {
    const model = item?.model
    // const res: Vector3Tuple = _.get(model, 'rotation', [0, 0, 0])
    const mr = _.get(model, 'rotation', [0, 0, 0])
    const rotationAxis = _.get(model, 'rotationAxis', 1)
    const res: Vector3Tuple = [mr[0], mr[1], mr[2]]
    res[rotationAxis] = res[rotationAxis] + rotation
    return res
  }, [item, rotation])

  useEffect(() => {
    sdk.Camera.pose.subscribe(function (pose) {
      poseRef.current = pose
    })
  }, [])

  const maxS = useMemo(() => {
    return _.max([slot.size[0], slot.size[2]]) || 1
  }, [slot])

  const multK = useMemo(() => {
    let res = 1.2
    if (maxS) {
      res = (maxS / 2) * 1.5
    }
    return res
  }, [maxS])

  const updateSlotPosition = (p: Vector3 | null) => {
    setSlotPosition(p)
    slotPositionRef.current = p
  }

  const onApply = () => {
    onAddItem(slotPositionRef.current, rotation)
  }

  // useEffect(() => {
  //   const unsubscribe = sdk.Pointer.intersection.subscribe(
  //     (intersectionData: MpSdk.Pointer.Intersection) => {
  //       if (poseRef.current) {
  //         switch (viewModeRef.current) {
  //           case sdk.Mode.Mode.INSIDE: {
  //             const intersP = new Vector3(
  //               intersectionData.position.x,
  //               intersectionData.position.y,
  //               intersectionData.position.z
  //             )
  //             const v = new Vector3(
  //               poseRef.current.position.x,
  //               intersectionData.position.y,
  //               poseRef.current.position.z
  //             )
  //             // console.log('cameraFloorPosition orig', v)
  //             v.sub(intersP)
  //             // console.log('cameraFloorPosition after sub', v)
  //             v.normalize()
  //             // console.log('cameraFloorPosition after normalization', v)
  //             // console.log('multK')
  //             v.multiplyScalar(multK)
  //             intersP.sub(v)
  //             updateSlotPosition(intersP)
  //             break
  //           }
  //           case sdk.Mode.Mode.FLOORPLAN: {
  //             // console.log('intersectionData', intersectionData)
  //             // console.log('poseRef.current', poseRef.current)
  //             const intersP = new Vector3(
  //               intersectionData.position.x,
  //               intersectionData.position.y,
  //               intersectionData.position.z - maxS / 2
  //             )
  //             console.log('intersP', intersP, 'object', intersectionData.object)
  //             updateSlotPosition(intersP)
  //             break
  //           }
  //         }
  //       }
  //     }
  //   )
  //   pointerIntersectionUnsubscribeRef.current = unsubscribe
  //   return () => unsubscribe.cancel()
  // }, [])

  const sceneClickListener = () => {
    if (slotPositionRef.current) {
      // onAddItem(slotPositionRef.current)
      setIsAdded(true)
      // pointerIntersectionUnsubscribeRef.current.cancel()
    }
  }

  const onIntersect = (p: Vector3 | null) => {
    updateSlotPosition(p)
  }

  if (model && sceneObject) {
    return (
      <>
        {isAdded && (
          <RotateControlSlider
            onRotated={v => setRotation(v)}
            modelRotate={rotation}
          />
        )}
        <Item
          key={uniqKey}
          id={uniqKey}
          sdk={sdk}
          url={model.glb}
          scale={model.scale}
          position={slotPosition || defaultPosition}
          offset={offsetV3}
          rotation={modelRotation}
          // onClick={onSlotClick}
          isSelected={isAdded}
          onMove={updateSlotPosition}
          sceneObject={sceneObject}
          showLoadingIndicator={true}
          viewMode={viewMode}
        />
        <SceneClickListener sdk={sdk} onClick={sceneClickListener} />
        <AddingItemPanel
          onCancel={onCancel}
          onApply={onApply}
          canApply={isAdded}
        />
        {sdk && !isAdded && (
          <ModelIntersection
            sdk={sdk}
            enabled
            onIntersect={onIntersect}
            viewMode={viewMode}
          />
        )}
        {/* <ModelIntersection
          sdk={sdk}
          enabled={viewMode === sdk.Mode.Mode.FLOORPLAN}
        /> */}
      </>
    )
  } else {
    return null
  }
}

export default AddingItem
