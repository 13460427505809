import { doc, updateDoc, getDoc } from 'firebase/firestore'
import * as Sentry from '@sentry/react'
import _ from 'lodash'

import { db } from 'controllers/db'
import { CustomerT } from 'shared/types/model'

export const dbUpdateUser = async (
  userId: string,
  update: Partial<CustomerT>
) => {
  try {
    const ref = doc(db, 'customers', userId)
    await updateDoc(ref, update)
    if (_.has(update, 'heapCounters')) {
      const isLocalHost =
        location.hostname === 'localhost' || location.hostname === '127.0.0.1'
      if (!isLocalHost) {
        const heap: any = _.get(window, 'heap')
        if (!_.isNil(heap)) {
          const heapCounters = _.get(update, 'heapCounters', {})
          heap.addUserProperties({
            ...heapCounters
          })
        }
      }
    }
    return true
  } catch (e) {
    console.log('dbUpdateUser error', e)
    Sentry.captureException(e)
    return false
  }
}

export const dbGetUser = async (userId: string) => {
  try {
    const ref = doc(db, 'customers', userId)
    const sn = await getDoc(ref)
    return sn.data() as CustomerT | undefined
  } catch (e) {
    console.log('dbGetUserProfile error', e)
    Sentry.captureException(e)
    return undefined
  }
}
