import {
  ForwardRefRenderFunction,
  forwardRef,
  Ref,
  useImperativeHandle
} from 'react'
import {
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  ModalHeader,
  useDisclosure,
  ModalFooter,
  Link
} from '@chakra-ui/react'

export interface ICheckoutModal {
  open: () => void
}

type Props = {}

const CheckoutModal: ForwardRefRenderFunction<ICheckoutModal, Props> = (
  _props: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useImperativeHandle(ref, () => ({
    open: () => {
      onOpen()
    }
  }))

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader textAlign='center' fontWeight='bold' fontSize='lg'>
          Contact Us
        </ModalHeader>
        <ModalBody py={4} px={6} textAlign='center'>
          <Box display='flex' alignItems='center' justifyContent='center' mb={3}>
            <Text fontSize='md' color='gray.600'>
              Please contact
            </Text>
          </Box>

          <Link
            href='mailto:team@upstager.co'
            isExternal
            color='gray.700'
            fontSize='sm'
          >
            <Text fontSize='lg' color='gray.800'>
              <b>team@upstager.co</b>
            </Text>
          </Link>
          <Text fontSize='md' color='gray.600' mt={3}>
            for a final quote and delivery estimates.
          </Text>
          <Text fontSize='md' color='gray.600' mt={3}>
            Make sure to click ‘Save as’ in the top left before exiting.
          </Text>
        </ModalBody>
        <ModalFooter justifyContent='center' pb={4}>
          <Button colorScheme='blue' size='md' px={6} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(CheckoutModal)
