import {
  SceneComponent,
  Dict,
  ComponentInteractionType
} from 'shared/components/SceneComponent'
import { MpSdk, Mode } from 'shared/bundle/sdk'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import _ from 'lodash'
import { Object3D, Vector3 } from 'three'

export interface TransformControlsInputs extends Dict {
  visible: boolean
  mode: 'translate' | 'rotate' | 'scale'
  selection: Object3D | null
  showX: boolean
  showY: boolean
  showZ: boolean
  size: number
  viewMode: Mode.Mode
  cameraPosition: Vector3
}

class MTransformControls extends SceneComponent {
  private root: Object3D | null = null

  inputs: TransformControlsInputs = {
    visible: true,
    mode: 'translate',
    selection: null,
    showX: true,
    showY: true,
    showZ: true,
    size: 1,
    viewMode: 'mode.inside' as Mode.Mode,
    cameraPosition: new Vector3()
  }

  events = {
    [ComponentInteractionType.DRAG]: true,
    [ComponentInteractionType.HOVER]: false,
    [ComponentInteractionType.CLICK]: false
  }

  onInit () {
    const makeTC = (t, e, inputs) => {
      // console.log('makeTC', t, e)
      class I {
        addEventListener (t, i, n) {
          // console.log('addEventListener', t, i, n)
          return e.addEventListener(t, i, n)
        }
        removeEventListener (t, i, n) {
          // console.log('removeEventListener', t, i, n)
          return e.removeEventListener(t, i, n)
        }
        getBoundingClientRect () {
          // console.log('getBoundingClientRect', e.getBoundingClientRect())
          return e.getBoundingClientRect()
        }
        setPointerCapture (t) {
          // console.log('setPointerCapture', t)
          return e.setPointerCapture(t)
        }
        releasePointerCapture (t) {
          // console.log('releasePointerCapture', t)
          return e.releasePointerCapture(t)
        }
        get ownerDocument () {
          return {
            get pointerLockElement () {
              const res = e.ownerDocument.pointerLockElement ? this : null
              // console.log('pointerLockElement', res)
              return res
            }
          }
        }
      }

      const ThreeTransformControls = _.get(
        this.context.three,
        'TransformControls'
      ) as TransformControls

      const _raycaster = new this.context.three.Raycaster()

      const intersectObjectWithRay = (object, raycaster, includeInvisible) => {
        const allIntersections = raycaster.intersectObject(object, true)
        for (let i = 0; i < allIntersections.length; i++) {
          if (allIntersections[i].object.visible || includeInvisible) {
            return allIntersections[i]
          }
        }

        return false
      }

      const _changeEvent = {
        type: 'change'
      }
      const _mouseDownEvent = {
        type: 'mouseDown'
      }
      const _objectChangeEvent = {
        type: 'objectChange'
      }

      class N extends ThreeTransformControls {
        constructor () {
          super(t, e)
          this.domElement = new I()
        }

        setRaycaster (pointer) {
          if (inputs.viewMode === 'mode.floorplan') {
            _raycaster.set(inputs.cameraPosition, new Vector3(0, -1, 0))
          } else {
            _raycaster.setFromCamera(pointer, this.camera)
          }
        }

        pointerHover (pointer) {
          if (this.object === undefined || this.dragging === true) return
          // console.log('pointer hover', inputs.viewMode, pointer)
          this.setRaycaster(pointer)
          const intersect = intersectObjectWithRay(
            this._gizmo.picker[this.mode],
            _raycaster
          )
          if (intersect) {
            this.axis = intersect.object.name
          } else {
            this.axis = null
          }
        }

        pointerDown (pointer) {
          if (
            this.object === undefined ||
            this.dragging === true ||
            pointer.button !== 0
          )
            return
          if (this.axis !== null) {
            this.setRaycaster(pointer)
            const planeIntersect = intersectObjectWithRay(
              this._plane,
              _raycaster,
              true
            )
            if (planeIntersect) {
              this.object.updateMatrixWorld()
              this.object.parent.updateMatrixWorld()
              this._positionStart.copy(this.object.position)
              this._quaternionStart.copy(this.object.quaternion)
              this._scaleStart.copy(this.object.scale)
              this.object.matrixWorld.decompose(
                this.worldPositionStart,
                this.worldQuaternionStart,
                this._worldScaleStart
              )
              this.pointStart
                .copy(planeIntersect.point)
                .sub(this.worldPositionStart)
            }

            this.dragging = true
            _mouseDownEvent.mode = this.mode
            this.dispatchEvent(_mouseDownEvent)
          }
        }

        pointerMove (pointer) {
          const axis = this.axis
          const mode = this.mode
          const object = this.object
          let space = this.space
          if (mode === 'scale') {
            space = 'local'
          } else if (axis === 'E' || axis === 'XYZE' || axis === 'XYZ') {
            space = 'world'
          }

          if (
            object === undefined ||
            axis === null ||
            this.dragging === false ||
            pointer.button !== -1
          )
            return
          this.setRaycaster(pointer)
          const planeIntersect = intersectObjectWithRay(
            this._plane,
            _raycaster,
            true
          )
          if (!planeIntersect) return
          this.pointEnd.copy(planeIntersect.point).sub(this.worldPositionStart)
          if (mode === 'translate') {
            // Apply translate

            this._offset.copy(this.pointEnd).sub(this.pointStart)
            if (space === 'local' && axis !== 'XYZ') {
              this._offset.applyQuaternion(this._worldQuaternionInv)
            }

            if (axis.indexOf('X') === -1) this._offset.x = 0
            if (axis.indexOf('Y') === -1) this._offset.y = 0
            if (axis.indexOf('Z') === -1) this._offset.z = 0
            if (space === 'local' && axis !== 'XYZ') {
              this._offset
                .applyQuaternion(this._quaternionStart)
                .divide(this._parentScale)
            } else {
              this._offset
                .applyQuaternion(this._parentQuaternionInv)
                .divide(this._parentScale)
            }

            object.position.copy(this._offset).add(this._positionStart)

            // Apply translation snap

            if (this.translationSnap) {
              if (space === 'local') {
                object.position.applyQuaternion(
                  _tempQuaternion.copy(this._quaternionStart).invert()
                )
                if (axis.search('X') !== -1) {
                  object.position.x =
                    Math.round(object.position.x / this.translationSnap) *
                    this.translationSnap
                }

                if (axis.search('Y') !== -1) {
                  object.position.y =
                    Math.round(object.position.y / this.translationSnap) *
                    this.translationSnap
                }

                if (axis.search('Z') !== -1) {
                  object.position.z =
                    Math.round(object.position.z / this.translationSnap) *
                    this.translationSnap
                }

                object.position.applyQuaternion(this._quaternionStart)
              }

              if (space === 'world') {
                if (object.parent) {
                  object.position.add(
                    _tempVector.setFromMatrixPosition(object.parent.matrixWorld)
                  )
                }

                if (axis.search('X') !== -1) {
                  object.position.x =
                    Math.round(object.position.x / this.translationSnap) *
                    this.translationSnap
                }

                if (axis.search('Y') !== -1) {
                  object.position.y =
                    Math.round(object.position.y / this.translationSnap) *
                    this.translationSnap
                }

                if (axis.search('Z') !== -1) {
                  object.position.z =
                    Math.round(object.position.z / this.translationSnap) *
                    this.translationSnap
                }

                if (object.parent) {
                  object.position.sub(
                    _tempVector.setFromMatrixPosition(object.parent.matrixWorld)
                  )
                }
              }
            }
          } else if (mode === 'scale') {
            if (axis.search('XYZ') !== -1) {
              let d = this.pointEnd.length() / this.pointStart.length()
              if (this.pointEnd.dot(this.pointStart) < 0) d *= -1
              _tempVector2.set(d, d, d)
            } else {
              _tempVector.copy(this.pointStart)
              _tempVector2.copy(this.pointEnd)
              _tempVector.applyQuaternion(this._worldQuaternionInv)
              _tempVector2.applyQuaternion(this._worldQuaternionInv)
              _tempVector2.divide(_tempVector)
              if (axis.search('X') === -1) {
                _tempVector2.x = 1
              }

              if (axis.search('Y') === -1) {
                _tempVector2.y = 1
              }

              if (axis.search('Z') === -1) {
                _tempVector2.z = 1
              }
            }

            // Apply scale

            object.scale.copy(this._scaleStart).multiply(_tempVector2)
            if (this.scaleSnap) {
              if (axis.search('X') !== -1) {
                object.scale.x =
                  Math.round(object.scale.x / this.scaleSnap) *
                    this.scaleSnap || this.scaleSnap
              }

              if (axis.search('Y') !== -1) {
                object.scale.y =
                  Math.round(object.scale.y / this.scaleSnap) *
                    this.scaleSnap || this.scaleSnap
              }

              if (axis.search('Z') !== -1) {
                object.scale.z =
                  Math.round(object.scale.z / this.scaleSnap) *
                    this.scaleSnap || this.scaleSnap
              }
            }
          } else if (mode === 'rotate') {
            this._offset.copy(this.pointEnd).sub(this.pointStart)
            const ROTATION_SPEED =
              20 /
              this.worldPosition.distanceTo(
                _tempVector.setFromMatrixPosition(this.camera.matrixWorld)
              )
            if (axis === 'E') {
              this.rotationAxis.copy(this.eye)
              this.rotationAngle = this.pointEnd.angleTo(this.pointStart)
              this._startNorm.copy(this.pointStart).normalize()
              this._endNorm.copy(this.pointEnd).normalize()
              this.rotationAngle *=
                this._endNorm.cross(this._startNorm).dot(this.eye) < 0 ? 1 : -1
            } else if (axis === 'XYZE') {
              this.rotationAxis.copy(this._offset).cross(this.eye).normalize()
              this.rotationAngle =
                this._offset.dot(
                  _tempVector.copy(this.rotationAxis).cross(this.eye)
                ) * ROTATION_SPEED
            } else if (axis === 'X' || axis === 'Y' || axis === 'Z') {
              this.rotationAxis.copy(_unit[axis])
              _tempVector.copy(_unit[axis])
              if (space === 'local') {
                _tempVector.applyQuaternion(this.worldQuaternion)
              }

              this.rotationAngle =
                this._offset.dot(_tempVector.cross(this.eye).normalize()) *
                ROTATION_SPEED
            }

            // Apply rotation snap

            if (this.rotationSnap)
              this.rotationAngle =
                Math.round(this.rotationAngle / this.rotationSnap) *
                this.rotationSnap

            // Apply rotate
            if (space === 'local' && axis !== 'E' && axis !== 'XYZE') {
              object.quaternion.copy(this._quaternionStart)
              object.quaternion
                .multiply(
                  _tempQuaternion.setFromAxisAngle(
                    this.rotationAxis,
                    this.rotationAngle
                  )
                )
                .normalize()
            } else {
              this.rotationAxis.applyQuaternion(this._parentQuaternionInv)
              object.quaternion.copy(
                _tempQuaternion.setFromAxisAngle(
                  this.rotationAxis,
                  this.rotationAngle
                )
              )
              object.quaternion.multiply(this._quaternionStart).normalize()
            }
          }

          this.dispatchEvent(_changeEvent)
          this.dispatchEvent(_objectChangeEvent)
        }
      }
      return new N()
    }

    const moduleCanvas = this.context.renderer.domElement

    const t = makeTC(
      this.context.camera,
      moduleCanvas,
      this.inputs
    ) as TransformControls

    this.root = t
    t.enabled = true
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root.children[0]
    t.setMode(this.inputs.mode)
    if (this.inputs.selection && this.inputs.visible) {
      t.attach(this.inputs.selection)
    }
    t.showX = this.inputs.showX
    t.showY = this.inputs.showY
    t.showZ = this.inputs.showZ
    t.setSize(this.inputs.size)
  }

  onInputsUpdated (oldInputs: TransformControlsInputs) {
    const t = this.root as TransformControls
    if (t) {
      if (this.inputs.selection !== oldInputs.selection) {
        if (this.inputs.selection && this.inputs.visible) {
          t.attach(this.inputs.selection)
        } else {
          t.detach()
        }
      }

      if (this.inputs.visible !== oldInputs.visible) {
        if (this.inputs.visible && this.inputs.selection) {
          t.attach(this.inputs.selection)
        } else {
          t.detach()
        }
      }

      if (this.inputs.showX !== oldInputs.showX) {
        t.showX = this.inputs.showX
      }

      if (this.inputs.showY !== oldInputs.showY) {
        t.showY = this.inputs.showY
      }

      if (this.inputs.showZ !== oldInputs.showZ) {
        t.showZ = this.inputs.showZ
      }

      if (this.inputs.size !== oldInputs.size) {
        t.setSize(this.inputs.size)
      }

      if (this.inputs.mode !== oldInputs.mode) {
        t.setMode(this.inputs.mode)
      }
    }
  }

  onDestroy () {
    if (this.root) {
      const t = this.root as TransformControls
      t.dispose()
    }
  }
}

export const customTransformControlsType = 'mp.customTransformControls'
export const makeCustomTransformControls = (): MpSdk.Scene.IComponent => {
  return new MTransformControls()
}
