import { useRef } from 'react'
import _ from 'lodash'
import {
  Flex,
  Text,
  useColorModeValue,
  VStack,
  HStack,
  Button,
  Divider,
  Box,
  Progress,
  Tooltip,
  Image,
  Link
} from '@chakra-ui/react'
import { ReactComponent as Logo } from 'shared/assets/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useTitle } from 'react-use'
import {
  faHouseLaptop,
  faGear,
  faBagsShopping,
  faMapLocation,
  faBuildings,
  faPlus
} from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import UpgradeModal, { IUpgradeModal } from 'modals/UpgradeModal'
import { useSelector } from 'model/hooks'
import { getCurrentPlanUsage } from 'model/selectors/paymentPlans'
import AccountButton from 'components/sidebar/AccountButton'
import { NavButton } from 'components/sidebar/NavButton'
import AddTourModal, { IAddTourModal } from 'modals/AddTourModal'
import { TourModelT } from 'types/model'
import SideMenuNavButton from 'components/sidebar/SideMenuNavButton'
import { getTourModelsAlive } from 'model/selectors/tourModels'

interface LinkItemT {
  name: string
  pth: string
  icon: IconDefinition
}

const linkItems: LinkItemT[] = [
  { name: 'Designs', pth: '/designs', icon: faHouseLaptop as IconDefinition },
  { name: 'Tours', pth: '/tours', icon: faMapLocation as IconDefinition },
  { name: 'Orders', pth: '/orders', icon: faBagsShopping as IconDefinition },
  { name: 'Settings', pth: '/settings', icon: faGear as IconDefinition }
]

const DashboardSidebar = () => {
  const navigate = useNavigate()
  const pathname = window.location.pathname
  const activeLink = _.find(linkItems, li => _.startsWith(pathname, li.pth))
  const upgradeModalRef = useRef<IUpgradeModal>(null)
  const addTourModalRef = useRef<IAddTourModal>(null)
  const planUsage = useSelector(getCurrentPlanUsage)
  const tourModels = useSelector(getTourModelsAlive)
  const partner = useSelector(state => state.partner)
  const unlimitedPlans = _.get(partner, 'unlimitedPlans', false)

  useTitle(
    activeLink ? `${activeLink.name} - UpStager Customer` : 'UpStager Customer'
  )

  const toTours = () => {
    navigate('/tours')
  }

  // const renderNavItem = (item: LinkItemT) => {
  //   const isActive = _.startsWith(pathname, item.pth)
  //   return (
  //     <Flex
  //       key={item.name}
  //       align='center'
  //       px='4'
  //       py='2'
  //       borderRadius='lg'
  //       role='group'
  //       w='full'
  //       cursor={isActive ? undefined : 'pointer'}
  //       _hover={
  //         isActive
  //           ? undefined
  //           : {
  //               bg: 'cyan.400',
  //               color: 'white'
  //             }
  //       }
  //       bg={isActive ? 'cyan.400' : undefined}
  //       as={isActive ? undefined : 'button'}
  //       onClick={isActive ? undefined : () => navigate(item.pth)}
  //       color={isActive ? 'white' : undefined}
  //     >
  //       {item.icon && <FontAwesomeIcon icon={item.icon} fixedWidth />}
  //       <Text fontWeight={500} pl={2}>
  //         {item.name}
  //       </Text>
  //     </Flex>
  //   )
  // }

  const renderBottomSection = () => {
    return (
      <VStack w='full' spacing={0}>
        {!unlimitedPlans && (
          <>
            <Box w='full' px={4}>
              <Button
                w='full'
                h='8'
                size='sm'
                colorScheme='teal'
                onClick={() => upgradeModalRef.current?.open()}
              >
                Upgrade
              </Button>
            </Box>
            <HStack px={4} pt='4' h='9' alignContent='center' w='full' spacing={4}>
              <Progress
                alignSelf='center'
                w='full'
                value={
                  planUsage.limitReached
                    ? 100
                    : (planUsage.used / planUsage.toursIncluded) * 100
                }
                // value={1}
                size='xs'
                colorScheme={planUsage.limitReached ? 'red' : 'teal'}
              />
              <Box flexShrink={0}>
                <Text
                  fontSize='xs'
                  flex='1'
                  lineHeight='4'
                  fontWeight='normal'
                  color={planUsage.limitReached ? 'red' : 'gray.700'}
                >
                  {`${planUsage.used} / ${planUsage.toursIncluded}`} tours
                </Text>
              </Box>
            </HStack>
          </>
        )}
        <Divider pt={4} m={0} />
        <HStack
          py={{ base: '2', sm: '5' }}
          // px={{ base: '2', sm: '5' }}
          mt={0}
          w='full'
          bg={_.startsWith(pathname, '/settings') ? 'gray.200' : 'white'}
        >
          <AccountButton
            onUpgradeClick={() => upgradeModalRef.current?.open()}
          />
        </HStack>
      </VStack>
    )
  }

  const renderLogo = () => {
    if (partner && partner.logoUrl) {
      return (
        <HStack w={'full'} justify='space-around' px={3} pt={2}>
          <Image src={partner.logoUrl} h='20px' mt={1} />
          <FontAwesomeIcon icon={faPlus} />
          <Box w='120px'>
            <Logo height={'30px'} width='120px' />
          </Box>
        </HStack>
      )
    } else {
      return (
        <HStack
          px={4}
          w='full'
          pt={4}
          alignItems='center'
          justifyContent='flex-start'
          spacing={2}
        >
          <Logo height='26px' />
        </HStack>
      )
    }
  }

  const renderTopMenu = () => {
    return (
      <Flex flexShrink={0} w='full' px={4} pt={6}>
        <NavButton
          label='All tours'
          fontSize='md'
          fontWeight='semibold'
          color='gray.500'
          iconColor='gray.500'
          icon={faBuildings}
          onClick={toTours}
          isActive={_.startsWith(pathname, '/tours')}
        />
      </Flex>
    )
  }

  const onAddTourClick = () => {
    console.log('on add tour click')
    addTourModalRef.current?.open()
  }

  const createTourButton = (
    <Tooltip
      label='Add a new tour'
      // shouldWrapChildren
      hasArrow
      closeDelay={1000}
      w='full'
    >
      <Box w='full' px={4} pt={6}>
        <Button
          w='full'
          h='8'
          fontWeight='semibold'
          variant='outline'
          color='gray.500'
          fontSize='sm'
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={onAddTourClick}
        >
          Add tour
        </Button>
      </Box>
    </Tooltip>
  )

  const renderTours = () => {
    if (!_.isEmpty(tourModels)) {
      return (
        <VStack
          spacing={3}
          overflow='hidden'
          minH='24'
          flex-grow='1'
          flex-basis='0'
          w='full'
          align='flex-start'
          justify={'flex-start'}
          px={4}
          h='full'
          pt={6}
        >
          <Text
            fontSize='xs'
            letterSpacing='0.05em'
            textTransform='uppercase'
            color='gray.500'
          >
            TOURS
          </Text>
          <VStack
            spacing='1'
            overflowY='auto'
            h='100%'
            w='full'
            justify={'flex-start'}
          >
            {_.map(tourModels, (tm: TourModelT, index: number) => (
              <SideMenuNavButton key={tm.id as string} tm={tm} id={`tour_${index}`} />
            ))}
          </VStack>
        </VStack>
      )
    }
  }

  const renderHowToSection = () => (
    <Flex align='end' justify='end' px='4' py='2' w='full' flexGrow={1}>
      <Link
        href='https://www.youtube.com/playlist?list=PL_GNx7PMNVeh_dBVU5rR8_w8qh8iEruAW'
        isExternal
        color='gray.900'
        textDecoration='underline'
        fontSize='sm'
      >
        How-To Videos
      </Link>
    </Flex>
  )

  return (
    <Flex h='full' w='full' flexShrink={0} bg='white'>
      <Flex boxShadow={useColorModeValue('sm', 'sm-dark')} w='full'>
        <VStack
          justify='space-between'
          spacing='1'
          width='full'
          flexShrink={0}
          flexGrow={0}
        >
          <VStack w='full' spacing={0}>
            {renderLogo()}
            {renderTopMenu()}
            {createTourButton}
          </VStack>
          {renderTours()}
          {renderHowToSection()}
          {renderBottomSection()}
        </VStack>
      </Flex>
      <UpgradeModal ref={upgradeModalRef} />
      <AddTourModal ref={addTourModalRef} />
    </Flex>
  )
}

export default DashboardSidebar
