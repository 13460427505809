import { FC } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Text,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Box,
  VStack
} from '@chakra-ui/react'
import _ from 'lodash'
import { ItemT } from 'shared/types/model'

type Props = {
  items: ItemT[]
}

const UnpricedItems: FC<Props> = ({ items }) => {
  if (_.isEmpty(items)) {
    return null
  } else {
    return (
      <Accordion allowToggle w='full' overflow='hidden' h='full'>
        <AccordionItem w='full' px={0}>
          <AccordionButton w='full' px={0}>
            <HStack w='full' justify={'space-between'}>
              <Text fontSize='sm'>{_.size(items)} unpriced items</Text>
              <AccordionIcon />
            </HStack>
          </AccordionButton>
          <AccordionPanel
            pb={4}
            px={0}
            overflow='hidden'
            display={'flex'}
            flexDirection={'column'}
            maxH={'sm'}
          >
            <Text color='gray.500' fontSize='xs'>
              These items are missing a price and cannot be added to your cart
              and are not included in your total.
            </Text>
            <VStack flex={1} w='full' spacing={4} pt={4} overflowY='auto'>
              {_.map(items, item => {
                return (
                  <HStack key={item.id} w='full' align={'flex-start'}>
                    <Box flex={0.5}>
                      <Text
                        fontWeight={'medium'}
                        fontSize='xs'
                        color={'blue.800'}
                      >
                        {item.title}
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize='xs' noOfLines={3} color='gray.400'>
                        {item.desc}
                      </Text>
                    </Box>
                  </HStack>
                )
              })}
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    )
  }
}

export default UnpricedItems
