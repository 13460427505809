import { collection, getDocs, query, where } from 'firebase/firestore'
import * as Sentry from '@sentry/react'
import { db } from 'controllers/db'
import store from 'model/store'
import { PartnerT } from 'shared/types/model'
import { receivePartner } from 'model/actions'

export const dbFetchPartner = async (subdomain: string) => {
  console.log('fetch partner subdomain', subdomain)
  try {
    const q = query(
      collection(db, 'partners'),
      where('subdomain', '==', subdomain)
    )
    const sn = await getDocs(q)
    if (!sn.empty) {
      store.dispatch(receivePartner(sn.docs[0].data() as PartnerT))
    } else {
      store.dispatch(receivePartner(null))
      console.log('fetchPartner error: partner is not found')
      // Sentry.captureMessage(
      //   `fetchPartner error: partner is not found, subdomain is ${subdomain}`
      // )
      return null
    }
  } catch (e) {
    console.log('fetchPartner error', e)
    store.dispatch(receivePartner(null))
    Sentry.captureException(e)
    return null
  }
}
