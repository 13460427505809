import { configureStore } from '@reduxjs/toolkit'

import authData from 'model/reducers/authData'
import tours from 'model/reducers/tours'
import slots from 'model/reducers/slots'
import items from 'model/reducers/items'
import user from 'model/reducers/user'
import tourModels from 'model/reducers/tourModels'
import mattertags from 'model/reducers/mattertags'
import suppliers from 'model/reducers/suppliers'
import settings from 'model/reducers/settings'
import prices from 'model/reducers/prices'
import products from 'model/reducers/products'
import subscription from 'model/reducers/subscription'
import requestsDesign from 'model/reducers/requestsDesign'
import roomTypes from 'model/reducers/roomTypes'
import templates from 'model/reducers/templates'
import onboarding from 'model/reducers/onboarding'
import userAccounts from 'model/reducers/userAccounts'
import account from 'model/reducers/account'
import profiles from 'model/reducers/profiles'
import accountProfile from 'model/reducers/accountProfile'
import invitations from 'model/reducers/invitations'
import partner from 'model/reducers/partner'
import noTourAccount from 'model/reducers/noTourAccount'

const store = configureStore({
  reducer: {
    authData,
    tours,
    slots,
    items,
    user,
    tourModels,
    mattertags,
    suppliers,
    settings,
    prices,
    products,
    subscription,
    requestsDesign,
    roomTypes,
    templates,
    onboarding,
    userAccounts,
    account,
    profiles,
    accountProfile,
    invitations,
    partner,
    noTourAccount
  }
})
export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
