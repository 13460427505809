import { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Box,
  FormErrorMessage,
  Text,
  Link
} from '@chakra-ui/react'
import validator from 'validator'
import Tour from 'shared/components/Tour'
import config from 'shared/config'
import { ISubscription, MpSdk } from 'shared/bundle/sdk'
import AuthHeader from 'pages/auth/AuthHeader'
// import OnboardingContext from 'contexts/OnboardingContext'
import { useNavigate } from 'react-router'
import AuthDivider from 'pages/auth/AuthDivider'
import { useDispatch, useSelector } from 'model/hooks'
import { updateOnboarding } from 'model/actions'
import { OnboardingT } from 'types/model'
import { saveFile } from 'controllers/storage'

const OnboardingMPUrl = () => {
  const dispatch = useDispatch()
  const tourModelId = useSelector(state =>
    _.get(state, 'onboarding.tourModelId')
  )
  const [matterportModelId, setMatterportModelId] = useState<string | null>(
    null
  )
  const [loading, setLoading] = useState(false)
  const modelIdInputRef = useRef<HTMLInputElement>(null)
  const [error, setError] = useState(false)
  const [mId, setMId] = useState<string | null>(null)
  const navigate = useNavigate()
  const modeSubscriptionRef = useRef<ISubscription>()

  const defaultModelId = 'saPpcn1h9gE'

  useEffect(() => {
    return () => {
      modeSubscriptionRef.current && modeSubscriptionRef.current.cancel()
    }
  }, [])

  const processModelId = () => {
    if (loading) {
      return null
    }
    let mId = matterportModelId
    if (matterportModelId && validator.isURL(matterportModelId)) {
      const u = new URL(matterportModelId)
      const sp = u.searchParams
      mId = sp.get('m')
    }
    if (_.isEmpty(mId)) {
      setError(true)
    } else {
      setLoading(true)
      setMId(mId)
    }
  }

  const toNextScreen = () => {
    navigate('/onboarding/uinfo')
  }

  const onUseDemoTour = () => {
    const details = {
      contactEmail: '',
      contactName: '',
      externalUrl: '',
      formattedAddress: '4701 Meridian Ave, Unit 602, Miami Beach',
      formattedContactPhone: '',
      name: 'Ritz-Carlton Residences Unit 602',
      phone: '',
      presentedBy: 'The Ritz-Carlton Residences, Miami Beach',
      sid: 'saPpcn1h9gE',
      summary: 'Captured by OmniScenic.com'
    }
    const upd: Partial<OnboardingT> = {
      modelId: defaultModelId,
      modelDetails: details,
      photoUrl:
        'https://firebasestorage.googleapis.com/v0/b/upstager-dev.appspot.com/o/tourModels%2Fi8tRr81vBRpdOZsjhde4%2Fl1hZjjJBTImhf2HmTYeJ?alt=media&token=6efde5f6-2c22-4aca-85f2-3fb1f2745bbe'
    }
    dispatch(updateOnboarding(upd))
    toNextScreen()
  }

  const onSdkReady = async (sdk: MpSdk) => {
    // console.log('onSdkReady', sdk)
    setLoading(false)
    try {
      const resolution = {
        width: 640,
        height: 480
      }
      const visibility = {
        mattertags: false,
        sweeps: false
      }
      const storagePath = `/tourModels/${tourModelId}`
      modeSubscriptionRef.current = sdk.Mode.current.subscribe(async function (
        mode
      ) {
        if (mode === 'mode.inside') {
          const screenshotUri = await sdk.Renderer.takeScreenShot(
            resolution,
            visibility
          )
          // console.log('screenshotUri', screenshotUri)
          const file = await saveFile(storagePath, screenshotUri, undefined, {
            contentType: 'image/jpg'
          })
          const details = await sdk.Model.getDetails()
          const upd: Partial<OnboardingT> = {
            modelId: mId,
            modelDetails: details,
            photoUrl: file?.url
          }
          dispatch(updateOnboarding(upd))
          toNextScreen()
        }
      })
    } catch (error) {
      console.log('model details error', error)
    }
  }
  const onError = () => {
    setLoading(false)
    setError(true)
  }

  const renderTour = () => {
    if (mId) {
      return (
        <Box w='full' h='72' pt={4}>
          <Tour
            modelId={mId}
            applicationKey={config.applicationKey}
            onSdkReady={onSdkReady}
            onError={onError}
            autoPlay
            quickStart
            hideDollHouse
            hideGuidedTour
            hideHighlightReels
            hideFloorSwitch
            disableZoom
            ignoreScrollWheel
            disablePanToNewHighligt
            loopGuidedTour={false}
            hideFloorPlan
          />
        </Box>
      )
    }
  }

  const pageHeader = (
    <AuthHeader title='Enhance unfurnished virtual tours' description='' />
  )

  console.log('error', error)

  return (
    <VStack spacing='2' w='full' pt={12} pb={12}>
      {pageHeader}
      <VStack
        bgColor='white'
        p='10'
        w='full'
        maxW='2xl'
        rounded='lg'
        boxShadow='sm'
      >
        <FormControl isInvalid={error}>
          <FormLabel>
            Enter the copied Matterport link or model ID of an unfurnished
            property
          </FormLabel>
          <InputGroup>
            <Input
              ref={modelIdInputRef}
              value={matterportModelId || ''}
              placeholder='https://my.matterport.com/show/?m=b4znJwcqE2p'
              onChange={e => setMatterportModelId(e.target.value)}
              onFocus={() => setError(false)}
              onBlur={processModelId}
              isDisabled={loading}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  modelIdInputRef.current?.blur()
                }
              }}
            />
            <InputRightElement w='24' mr={-1}>
              <Button
                variant={'solid'}
                colorScheme='blue'
                size='sm'
                onClick={processModelId}
                isLoading={loading}
              >
                Upstage
              </Button>
            </InputRightElement>
          </InputGroup>
          {/* {error && ( */}
          <FormErrorMessage>Enter a valid Matterport tour URL</FormErrorMessage>
          {/* )} */}
        </FormControl>
        {renderTour()}
        <AuthDivider />
        <Box pt={4}>
          <Button
            variant={'solid'}
            colorScheme='blue'
            onClick={onUseDemoTour}
            w='96'
          >
            Use a demo tour
          </Button>
        </Box>
      </VStack>
      <VStack pt={12}>
        <Text fontWeight={'medium'} color='body'>
          Don’t have a matterport tour?
        </Text>

        <Text color='gray.600'>
          You can order a tour using Matterport capture services:
        </Text>

        <Link
          color='blue.500'
          isExternal
          href='https://buy.matterport.com/capture-services-order'
        >
          Order Matterport tour
        </Link>
      </VStack>
    </VStack>
  )
}

export default OnboardingMPUrl
