import {
  doc,
  getDoc,
  // setDoc,
  query,
  collection,
  where,
  onSnapshot,
  updateDoc,
  deleteDoc,
  setDoc
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import { InvitationT } from 'shared/types/model'
import store from 'model/store'
import { receiveInvitations } from 'model/actions'
import { addListener } from 'controllers/listeners'
import { switchAccount } from 'controllers/auth'
// import { logTeammateInvited } from 'controllers/analytics'
import { getRequestHeaders } from 'controllers/headers'
import config from 'shared/config'

export const fetchInvitations = async (accountId: string) => {
  try {
    if (!_.isNil(accountId)) {
      const q = query(
        collection(db, 'invitations'),
        where('accountId', '==', accountId)
      )
      const unsubscribe = onSnapshot(
        q,
        sn => {
          const res = {}
          sn.forEach(doc => {
            const p = doc.data()
            _.set(res, doc.id, p)
          })
          store.dispatch(receiveInvitations(res))
        },
        err => {
          console.log(`fetchInvitations error: ${err.message}`)
        }
      )
      addListener('invitations', unsubscribe)
    } else {
      console.log('fetchInvitations error: accountId missing')
    }
  } catch (e) {
    console.log('fetchInvitations error', e)
    Sentry.captureException(e)
  }
}

export const sendInvitation = async (inv: InvitationT): Promise<void> => {
  try {
    await setDoc(doc(db, 'invitations', inv.id), _.omitBy(inv, _.isNil))
    // logTeammateInvited(email)
  } catch (e) {
    console.error('sendInvitation error', e)
    Sentry.captureException(e)
  }
}

export const fetchInvitation = async (
  invitationId: string
): Promise<InvitationT | undefined> => {
  try {
    const docRef = doc(db, 'invitations', invitationId)
    const docSn = await getDoc(docRef)
    const invitation = docSn.data() as InvitationT | undefined
    return invitation
  } catch (e) {
    console.log('fetchInvitation error', e)
    Sentry.captureException(e)
    return undefined
  }
}

export const acceptInvitation = async (inviteId: string): Promise<void> => {
  try {
    const url = `${config.backendUrl}/customer/accept`
    const headers = await getRequestHeaders()
    const res = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        inviteId
      })
    })
    if (res.status === 200) {
      const { accountId } = await res.json()
      switchAccount(accountId)
    }
  } catch (e) {
    // Sentry.captureException(e)
    console.log('acceptInvitation error', e)
  }
}

export const declineInvitation = async (
  invitationId: string
): Promise<void> => {
  try {
    await updateDoc(doc(db, 'invitations', invitationId), {
      status: 'declined'
    })
  } catch (e) {
    // Sentry.captureException(e)
    console.log('declineInvitation error', e)
  }
}

export const removeInvitation = async (invitationId: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, 'invitations', invitationId))
  } catch (e) {
    // Sentry.captureException(e)
    console.log('removeInvitation error', e)
  }
}
