import { useRef, useState, FC, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPaperclip } from '@fortawesome/pro-light-svg-icons'
import { Flex, Progress, VStack, Center, Text } from '@chakra-ui/react'
import _ from 'lodash'
// import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FileT, StorageFileT, StorageT } from 'shared/types/storage'
import Dropzone, { DropzoneOptions } from 'react-dropzone'
import { faCloudArrowUp } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  onComplete: (files: FileT[]) => void
  storagePath: string
  userId: string
  buttonTitle?: string
  fileProcessor?: (file: File) => Promise<File>
  thumbnailSize?: number
  storage: StorageT
  generateId: () => string
  children?: ReactNode
  options?: DropzoneOptions
  hideProgress?: boolean
  onFilesPicked?: (files: File[]) => void
}

const FilesPickerButton: FC<Props> = ({
  onComplete,
  storagePath,
  userId,
  buttonTitle,
  fileProcessor,
  thumbnailSize,
  storage,
  generateId,
  children,
  options,
  hideProgress = false,
  onFilesPicked
}) => {
  const filePickerRef = useRef<HTMLInputElement>(null)
  const [progress, setProgress] = useState<number | undefined>()
  const [inputKey, setInputKey] = useState(_.now())
  const [cancelled, setCancelled] = useState(false)

  const openFilePicker = () => {
    setCancelled(false)
    filePickerRef?.current?.click()
  }

  const mimeIsImage = (contentType: string = '') => {
    return _.startsWith(contentType, 'image')
  }

  const processFiles = async (files: File[]) => {
    const count = _.size(files)
    const res: FileT[] = []
    if (onFilesPicked) {
      onFilesPicked(files)
      setInputKey(_.now())
    } else {
      for (const i in files) {
        const fileRaw = fileProcessor ? await fileProcessor(files[i]) : files[i]
        if (cancelled) return
        console.log(i, 'fileRaw ===>', fileRaw)
        const id = generateId()
        const p = `${storagePath}/${id}`

        const onProgress = (v: number) => {
          console.log('onProgress', v)
          setProgress(_.ceil(v / count + (Number(i) / count) * 100))
        }
        const fileInfo: StorageFileT | undefined = await storage.saveFileObject(
          fileRaw,
          p,
          onProgress
        )
        console.log('onFilesPicked: fileInfo', fileInfo)
        if (fileInfo) {
          const pFile: FileT = {
            id,
            createdAt: _.now() + Number(i),
            name: _.get(fileRaw, 'name'),
            createdBy: userId,
            ...fileInfo
          }
          if (mimeIsImage(fileInfo.contentType) && thumbnailSize) {
            const thumbnail: any = await storage.createImageThumbnail(
              fileRaw,
              thumbnailSize
            )
            const thumbnailUrl: string | undefined = _.get(
              await storage.saveFileObject(thumbnail, `${p}_thumbnail`),
              'url'
            )
            if (thumbnailUrl) {
              pFile.thumbnailUrl = thumbnailUrl
            }
          }
          console.log('push file to list', pFile)
          res.push(pFile)
        }
      }

      console.log('%cres', 'color:green', res)
      setInputKey(_.now())

      setProgress(undefined)
      if (cancelled) {
        // deleteFiles(res)
      } else {
        onComplete(res)
      }
    }
  }

  // const onFilesPicked = async (event: ChangeEvent) => {
  //   const files: File[] = _.values(_.get(event, 'target.files'))
  //   await processFiles(files)
  // }

  const renderUploadFileButtonSecondary = (isDragActive: boolean) => {
    if (!hideProgress && !_.isNil(progress)) {
      return (
        <VStack
          borderWidth={0.5}
          borderColor={isDragActive ? 'blue.500' : 'gray.200'}
          w='56'
          h='32'
          rounded={'base'}
          _hover={{ bg: 'gray.50', cursor: 'pointer' }}
          align='center'
          justify={'center'}
        >
          <Text fontSize={'xs'} color='gray.500'>
            Uploading ({progress}%)
          </Text>
          <Progress
            w={32}
            size={'xs'}
            value={progress}
            isIndeterminate={progress === 0}
          />
        </VStack>
      )
    } else if (children) {
      return (
        <Flex
          as='button'
          onClick={openFilePicker}
          disabled={options && options.disabled}
          h='fit-content'
          flexShrink={0}
        >
          {children}
        </Flex>
      )
    } else {
      return (
        <VStack
          borderWidth={0.5}
          borderColor={isDragActive ? 'blue.500' : 'gray.200'}
          w='56'
          h='32'
          rounded={'base'}
          _hover={{ bg: 'gray.50', cursor: 'pointer' }}
          align='center'
          justify={'center'}
          outline='none'
        >
          <Center w={6} h={6} rounded='base' bg='gray.100' color='gray.600'>
            <FontAwesomeIcon icon={faCloudArrowUp} fontSize='0.6em' />
          </Center>
          <VStack spacing={0}>
            <Text fontSize={'xs'} color='gray.500'>
              <Text as='span' color='blue.400'>
                Click to upload
              </Text>{' '}
              or drag and drop
            </Text>
            <Text fontSize={'xs'} color='gray.400'>
              {buttonTitle}
            </Text>
          </VStack>
        </VStack>
      )
    }
  }

  return (
    <Dropzone
      // innerRef={filePickerRef}
      key={inputKey}
      onDrop={acceptedFiles => processFiles(acceptedFiles)}
      onError={e => alert(`error, ${JSON.stringify(e)}`)}
      // useFsAccessApi={false}
      // noClick={true}
      {...options}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()}>
          {renderUploadFileButtonSecondary(isDragActive)}
          <input {...getInputProps()} id='file-input' />
        </div>
      )}
    </Dropzone>
  )
}

export default FilesPickerButton
