import {
  ForwardRefRenderFunction,
  Ref,
  useImperativeHandle,
  useState,
  forwardRef
} from 'react'
import {
  Button,
  useDisclosure,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage
} from '@chakra-ui/react'

import { useSelector } from 'model/hooks'
import { getDesignPrice, getPerSFPrice } from 'model/selectors/paymentPlans'
// import { RequestDesignT } from 'shared/types/model'
// import { generateId } from 'controllers/db'
import _ from 'lodash'
// import { dbCreateRequestDesign } from 'controllers/requestDesign'
import numeral from 'numeral'
import { openRequestDesignPaymentPage } from 'controllers/stripe'

export type IRequestDesignModal = {
  open: (tourModelId: string) => void
}

type Props = {}

const RequestDesignModal: ForwardRefRenderFunction<
  IRequestDesignModal,
  Props
> = (_props: Props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tourModelId, setTourModelId] = useState('')
  // const [isChecked, setIsChecked] = useState(false)
  const designPrice = useSelector(getDesignPrice)
  const perSFPrice = useSelector(getPerSFPrice)
  const user = useSelector(state => state.user)
  const [sf, setSF] = useState('')
  const [sfError, setSFError] = useState(false)
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => ({
    open: (tourModelId: string) => {
      setTourModelId(tourModelId)
      setSFError(false)
      setSF('')
      onOpen()
    }
  }))

  const sfInvalid = sf !== '' && _.toNumber(sf) < 5000

  const onRequest = async () => {
    // console.log('onRequest', tourModelId)
    if (sfInvalid) {
      setSFError(true)
    } else if (user && user.currentAccountId && tourModelId) {
      setLoading(true)
      const numSF = sf !== '' ? _.toNumber(sf) : undefined
      const successed = await openRequestDesignPaymentPage(tourModelId, numSF)
      setLoading(false)
      if (successed) {
        onClose()
      }

      // const id = generateId()
      // if (user && user.currentAccountId && tourModelId) {
      //   const rd: RequestDesignT = {
      //     id,
      //     accountId: user.currentAccountId,
      //     createdAt: _.now(),
      //     tourModelId,
      //     assignedTo: null,
      //     completed: false,
      //     stripeInvoice: null
      //   }
      //   if (sf !== '') {
      //     rd.sf = _.toNumber(sf)
      //   }
      //   dbCreateRequestDesign(rd)
      //   onClose()
      // }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent w='xl'>
        <ModalHeader borderBottomWidth={1}>Request Design</ModalHeader>
        <ModalCloseButton />
        <ModalBody h='full' display={'flex'} flexDirection='column'>
          <VStack align='flex-start' fontSize={'sm'} pt={6}>
            <Text>
              An UpStager designer will create a professional design to your
              space.
            </Text>
            <VStack spacing={0} align='flex-start' pt={4}>
              <Heading size='sm' pb={2}>
                Pricing:
              </Heading>
              <Text as='li'>
                {numeral((designPrice?.unitAmount || 0) / 100).format(
                  '$0,0.00'
                )}{' '}
                (fixed price) for homes and apartments less than 5000 sq. feet;
              </Text>
              <Text as='li'>
                {numeral((perSFPrice?.unitAmount || 0) / 100).format('$0,0.00')}
                /sq.feet for spaces more than 5000 sq. feet;
              </Text>
            </VStack>

            <FormControl isInvalid={sfInvalid} pt={4}>
              <FormLabel htmlFor='square feet'>
                Enter the property size in sq. feet:
              </FormLabel>
              <Input
                value={sf}
                type='number'
                placeholder='0'
                onChange={e => setSF(e.target.value)}
                onFocus={() => setSFError(false)}
                size='sm'
              />
              {sfError ? (
                <FormErrorMessage>
                  Enter sf amount more than 5000 or keep the field empty
                </FormErrorMessage>
              ) : (
                <FormHelperText color='orange.500'>
                  Ignore this step if the property is less than 5k sq. feet.
                </FormHelperText>
              )}
            </FormControl>
            <VStack align={'flex-start'} py={4}>
              <Heading size='sm'>How it works:</Heading>
              <Box as='ol' pl={4}>
                <Text as='li' fontSize='sm'>
                  Once you click Request, an Invoice for your order will be
                  automatically generated in &quot;Designs&quot;
                </Text>
                <Text as='li'>Click on the Invoice and pay online</Text>
                <Text as='li'>
                  Once we see your payment, our designers will start Upstaging
                  the property.
                </Text>
                <Text as='li'>
                  Your design will be ready within 48 hours. We will notify you
                  by email.
                </Text>
              </Box>
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              colorScheme='blue'
              onClick={onRequest}
              isLoading={loading}
              isDisabled={loading}
            >
              Request
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(RequestDesignModal)
