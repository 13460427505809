import { FC, useRef, useState, useEffect } from 'react'
import {
  Text,
  Stack,
  HStack,
  Input,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Box,
  Hide
} from '@chakra-ui/react'
import _ from 'lodash'
import { useSelector } from 'model/hooks'
import { AccountProfileT, DictT } from 'shared/types/model'
import { dbUpdateAccountProfile } from 'controllers/profiles'
import FilesPickerButton from 'components/FilesPickerButton'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import { convertToIco, resizeFile, resizeFileSquare } from 'utils/imageResizer'
import storage from 'controllers/storage'
import { FileT } from 'shared/types/storage'
import SettingsCardTitle from 'components/settings/SettingsCardTitle'
import FilePlaceholder from 'components/filesPicker/FilePlaceholder'
import PickedFile from 'components/filesPicker/PickedFile'
// import {
//   logSettingsFaviconAdded,
//   logSettingsFaviconRemoved,
//   logSettingsLogoAdded,
//   logSettingsLogoRemoved
// } from 'controllers/analytics'
import { dbSignOut } from 'controllers/auth'
import { useNavigate } from 'react-router-dom'

const SettingsAccount: FC = () => {
  const nameInputRef = useRef<HTMLInputElement>(null)
  // const redirectUrlInputRef = useRef<HTMLInputElement>(null)
  // const customDomainInputRef = useRef<HTMLInputElement>(null)
  const [name, setName] = useState('')
  // const [redirectUrl, setRedirectUrl] = useState('')
  // const [redirectUrlError, setRedirectUrlError] = useState(false)
  // const [customDomainUrl, setCustomDomainUrl] = useState('')
  // const [customDomainUrlError, setCustomDomainUrlError] = useState(false)
  const profile = useSelector(getCurrentUserProfile)
  const accountProfile: AccountProfileT | null = useSelector(
    state => state.accountProfile
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (accountProfile) {
      setName(accountProfile.name || '')
      // setRedirectUrl(accountProfile.logoRedirectUrl || '')
      // setCustomDomainUrl(accountProfile.customDomainUrl || '')
    }
  }, [accountProfile])

  const renderName = () => {
    return (
      <FormControl>
        <FormLabel>Organization name</FormLabel>
        <Input
          ref={nameInputRef}
          type='text'
          size='sm'
          rounded='md'
          maxW={'96'}
          value={name}
          placeholder='name'
          onChange={e => setName(e.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
              nameInputRef.current?.blur()
            }
          }}
          onBlur={() =>
            accountProfile &&
            dbUpdateAccountProfile(accountProfile.id, { name })
          }
          onSubmit={() => nameInputRef.current?.blur()}
        />
      </FormControl>
    )
  }

  const onLogoUploaded = (files: FileT[]) => {
    if (_.size(files) > 0 && accountProfile) {
      const f = files[0]
      dbUpdateAccountProfile(accountProfile.id, { logoUrl: f.url })
      // logSettingsLogoAdded(f.url)
    }
  }

  const onRemoveLogoClick = () => {
    if (accountProfile) {
      dbUpdateAccountProfile(accountProfile.id, {
        logoUrl: undefined
      })
      // logSettingsLogoRemoved(accountProfile.logoUrl || '')
    }
  }

  const renderLogoUpload = () => {
    if (accountProfile) {
      return (
        <VStack align={'flex-start'} minW={'64'} spacing={4} flex={1}>
          <Text fontWeight={'semibold'} color='gray.800'>
            Company Logo
          </Text>

          <FilesPickerButton
            userId={profile ? profile.id : ''}
            storagePath={`/accounts/${accountProfile.id}/`}
            onComplete={onLogoUploaded}
            fileProcessor={resizeFile}
            options={{
              accept: {
                'image/*': ['.png', '.jpeg', '.jpg', '.heic']
              },
              multiple: false
            }}
            storage={storage}
            generateId={() => 'logo'}
          >
            {accountProfile.logoUrl ? (
              <Box w={'48'} h={'16'} position='relative'>
                <PickedFile
                  url={accountProfile.logoUrl}
                  onRemove={onRemoveLogoClick}
                />
              </Box>
            ) : (
              <FilePlaceholder buttonTitle='PNG, JPG or HEIC file' />
            )}
          </FilesPickerButton>
        </VStack>
      )
    }
  }

  const processFaviconFiles = async (files: File[]) => {
    console.log('processFaviconFiles', files)
    if (accountProfile && !_.isEmpty(files)) {
      const f: File = files[0]
      const storagePath = `/accounts/${accountProfile.id}/`
      const sizes = [32, 128, 180, 192]
      const urls = await Promise.all(
        _.map(sizes, async size => {
          const resizedFile = await resizeFileSquare(f, size)
          const fileInfo = await storage.saveFileObject(
            resizedFile,
            storagePath + `/favicon${size}.png`
          )
          return _.get(fileInfo, 'url', null)
        })
      )
      console.log('sizes', sizes)
      console.log('urls', urls)
      const favicons: DictT<string> = {}
      _.forEach(sizes, (size, i) => {
        const key = `icon${size}`
        const url = urls[i]
        if (!_.isNil(url)) {
          favicons[key] = url
        }
      })
      const icoResizedFile = await resizeFileSquare(f, 32)
      const icoFile = await convertToIco(icoResizedFile)
      const faviconInfo = await storage.saveFileObject(
        icoFile,
        storagePath + '/favicon.ico'
      )
      if (faviconInfo) {
        favicons.ico = faviconInfo?.url
      }
      dbUpdateAccountProfile(accountProfile.id, { favicons })
      // logSettingsFaviconAdded(favicons.icon192)
    }
  }

  const onFaviconRemoveClick = () => {
    if (accountProfile) {
      dbUpdateAccountProfile(accountProfile.id, {
        favicons: undefined
      })
      // logSettingsFaviconRemoved(_.get(accountProfile, 'favicons.icon192', ''))
    }
  }

  const renderFaviconUpload = () => {
    if (accountProfile) {
      return (
        <VStack align={'flex-start'} minW={'64'} spacing={4} flex={1}>
          <Text fontWeight={'semibold'} color='gray.800'>
            Add a favicon
          </Text>

          <FilesPickerButton
            userId={profile ? profile.id : ''}
            storagePath={`/accounts/${accountProfile.id}/`}
            onComplete={() => null}
            fileProcessor={f => resizeFileSquare(f, 400)}
            options={{
              accept: {
                'image/*': ['.png', '.jpeg', '.jpg']
              },
              multiple: false
            }}
            storage={storage}
            generateId={() => 'favicon'}
            hideProgress
            onFilesPicked={processFaviconFiles}
          >
            {accountProfile.favicons ? (
              <Box w={'16'} h={'16'} position='relative'>
                <PickedFile
                  url={_.get(accountProfile, 'favicons.icon192', '')}
                  onRemove={onFaviconRemoveClick}
                />
              </Box>
            ) : (
              <FilePlaceholder buttonTitle='PNG or JPG file' />
            )}
          </FilesPickerButton>
        </VStack>
      )
    }
  }

  return (
    <VStack
      w='full'
      px={{ lg: '8', base: '0' }}
      py={{ lg: '4', base: '0' }}
      overflowY='auto'
      bg={{ lg: 'gray.50', base: 'white' }}
      spacing={8}
    >
      <HStack
        w='full'
        bg='white'
        boxShadow={{ lg: 'base', base: 'none' }}
        borderRadius='2xl'
        p={{ lg: '8', base: '4' }}
        spacing={{ lg: '16', base: 0 }}
        align='flex-start'
        h='fit-content'
      >
        <SettingsCardTitle
          title='Your Account'
          description='Information about your organization'
        />
        <Stack direction='column' spacing='12' flex={2}>
          {renderName()}
          <Stack
            direction={{ lg: 'row', base: 'column' }}
            spacing={{ base: 8, lg: 0 }}
            overflow='hidden'
          >
            {renderLogoUpload()}
            {renderFaviconUpload()}
          </Stack>
        </Stack>
      </HStack>

      <Hide above='lg'>
        <Box w='full' pl='4' pb='6'>
          <Button variant={'outline'} onClick={() => dbSignOut(navigate)}>
            Sign out
          </Button>
        </Box>
      </Hide>
    </VStack>
  )
}

export default SettingsAccount
