import {
  onSnapshot,
  query,
  collection,
  where,
  doc,
  // setDoc,
  deleteDoc
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { DictT, RequestDesignT } from 'shared/types/model'
import { receiveRequestsDesign } from 'model/actions'

export const fetchRequestsDesign = (accountId: string) => {
  try {
    const state = store.getState()
    const partnerId = _.get(state, 'partner.id', null)
    const q = query(
      collection(db, 'requestsDesign'),
      where('accountId', '==', accountId),
      where('deleted', '==', 0),
      where('tourModelDeleted', '==', 0),
      where('partnerId', '==', partnerId)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: DictT<RequestDesignT> = {}
        sn.forEach(doc => {
          const p = doc.data() as RequestDesignT
          _.set(res, doc.id, p)
        })
        console.log('receive requests design', res)
        store.dispatch(receiveRequestsDesign(res))
      },
      err => {
        console.log(`receive requests error: ${err}`)
      }
    )
    addListener('requestsDesign', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('receive requests error', e)
  }
}

// export const dbCreateRequestDesign = async (rd: RequestDesignT) => {
//   try {
//     console.log('dbCreateRequestDesign', rd)
//     const ref = doc(db, `requestsDesign/${rd.id}`)
//     await setDoc(ref, _.omitBy(rd, _.isUndefined))
//   } catch (e) {
//     Sentry.captureException(e)
//   }
// }

export const dbRemoveRequestDesign = async (rd: RequestDesignT) => {
  try {
    const ref = doc(db, `requestsDesign/${rd.id}`)
    await deleteDoc(ref)
  } catch (e) {
    Sentry.captureException(e)
  }
}
