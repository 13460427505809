import { SceneComponent } from 'shared/components/SceneComponent'
import { MpSdk } from 'shared/bundle/sdk'
import { Object3D, Raycaster, Event, Intersection } from 'three'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import _ from 'lodash'

export interface IMItemHighlightInputs extends Record<string, unknown> {
  effectComposer: EffectComposer | null
  itemId: string | null
}

class MItemHighlight extends SceneComponent {
  private root: Object3D | null = null
  private outlinePass: OutlinePass | null = null
  private composer: EffectComposer | null = null
  private selectedObjects: Object3D[] = []
  private raycaster: Raycaster | null = null
  private prevIntersects: Intersection<Object3D<Event>>[] | undefined
  // private circle: Mesh | null = null

  inputs: IMItemHighlightInputs = {
    effectComposer: null,
    itemId: null
  }

  onInputsUpdated = (oldInputs: IMItemHighlightInputs) => {
    if (oldInputs.itemId !== this.inputs.itemId) {
      this.selectedObjects = []
      const scene = this.context.scene
      if (this.inputs.itemId) {
        console.log('scene', scene)
        const o = scene.getObjectByName(this.inputs.itemId)
        console.log('search by item name', this.inputs.itemId, 'res:', o)
        if (o) {
          this.selectedObjects.push(o)
        }

        setTimeout(() => console.log('ooooo', o), 1000)
      }
      if (this.outlinePass) {
        this.outlinePass.selectedObjects = this.selectedObjects
      }
    }
  }

  onInit () {
    console.log('%coutline pass onInit', 'color: red;')
    const THREE = this.context.three
    console.log('THREE', THREE)
    // const renderer = this.context.renderer
    this.root = new THREE.Object3D()
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root
    this.composer = this.inputs?.effectComposer
    if (this.composer) {
      const OPass = _.get(this.context.three, 'OutlinePass')
      console.log('OutlinePassClass', OPass)
      console.log('this.root.parent', this.root.parent)
      const oPass: OutlinePass = new OPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        this.context.scene,
        this.context.camera
      )
      this.outlinePass = oPass
      oPass.edgeStrength = 5
      oPass.edgeGlow = 1
      // oPass.edgeThickness = 2.6
      // oPass.pulsePeriod = 2
      oPass.resolution.set(2048, 2048)

      this.outlinePass.visibleEdgeColor.set(new THREE.Color('#47C1BF'))
      this.outlinePass.hiddenEdgeColor.set(new THREE.Color('#47C1BF'))
      this.composer.insertPass(oPass, 1)
    } else {
      console.error('effect composer is null')
    }
    // console.log('this.context', this.context)
  }

  onDestroy () {
    console.log('%coutline pass onDestroy', 'color: red;')
    if (this.outlinePass) {
      this.composer?.removePass(this.outlinePass)
    }
  }
}

export const itemHighlightType = 'mp.itemHighlight'
export const makeItemHighlight = (): MpSdk.Scene.IComponent => {
  return new MItemHighlight()
}
