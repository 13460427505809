import {
  collection,
  query,
  doc,
  setDoc,
  where,
  updateDoc,
  onSnapshot,
  writeBatch
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db, dbOmit } from 'controllers/db'
import store from 'model/store'
import { TourModelT, TourT } from 'shared/types/model'
import { receiveTourModels, resetOnboarding } from 'model/actions'
import { addListener } from 'controllers/listeners'
import { v4 } from 'uuid'
import { addressByString } from 'shared/utils/googleApis'
import { dbCreateTour } from 'controllers/tours'
// import { NavigateFunction } from 'react-router-dom'

export const dbCreateTourModel = async (tm: TourModelT) => {
  try {
    console.log('createTourModel', tm)
    const ref = doc(db, `tourModels/${tm.id}`)
    setDoc(ref, dbOmit(tm), { merge: true })
  } catch (e) {
    Sentry.captureException(e)
  }
}

const processOnboardingTour = async (
  accountId: string
  // navigate: NavigateFunction
) => {
  try {
    const state = store.getState()
    const partnerId = _.get(state, 'partner.id', null)
    const onboarding = state.onboarding
    if (!_.isNil(onboarding) && onboarding.modelDetails && onboarding.modelId) {
      const tour: TourT = {
        ...onboarding.tour,
        accountId,
        createdAt: _.now(),
        updatedAt: _.now()
      }
      const sessionToken = v4()

      const tourModel: TourModelT = {
        id: onboarding.tourModelId,
        modelId: onboarding.modelId,
        enabled: false,
        createdAt: _.now(),
        photoUrl: onboarding.photoUrl,
        accountId,
        name: onboarding.modelDetails.name || '',
        deleted: 0,
        partnerId
      }
      if (!_.isEmpty(onboarding.modelDetails.formattedAddress)) {
        try {
          const newAddress = await addressByString(
            onboarding.modelDetails.formattedAddress,
            sessionToken
          )
          tourModel.address = newAddress
        } catch (e) {
          console.warn('cannot get tour model address')
          Sentry.captureException(e)
        }
      }
      await dbCreateTourModel(tourModel)
      await dbCreateTour(tour)
      store.dispatch(resetOnboarding())
      // navigate(`/tour/${onboarding.tourModelId}?onboarding`, { state: { tab: 1 } })
    }
  } catch (e) {
    console.error('processOnboardingTour error', e)
    Sentry.captureException(e)
  }
}

export const fetchTourModels = async (
  accountId: string
  // navigate: NavigateFunction
) => {
  try {
    const state = store.getState()
    const partnerId = _.get(state, 'partner.id', null)
    console.log('fetch tour models accountId', accountId)
    console.log('fetch tour models partnerId', partnerId)
    const q = query(
      collection(db, 'tourModels'),
      where('accountId', '==', accountId),
      where('deleted', '==', 0),
      where('partnerId', '==', partnerId)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        console.log('tour models received, amount', _.size(res))
        store.dispatch(receiveTourModels(res))
        if (_.isEmpty(res)) {
          setTimeout(() => processOnboardingTour(accountId), 0)
        }
      },
      err => {
        console.log(`fetchProjects error: ${err}`)
        Sentry.captureException(err)
      }
    )
    addListener('tourModels', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch tour models error', e)
  }
}

export const dbUpdateTourModel = async (
  tourModelId: string,
  update: Partial<TourModelT>
) => {
  try {
    const ref = doc(db, `tourModels/${tourModelId}`)
    updateDoc(ref, update)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbDeleteTourModel = async (tourModelId: string) => {
  try {
    const timeNow = _.now()
    dbUpdateTourModel(tourModelId, { deleted: timeNow })
    const state = store.getState()
    const batch = writeBatch(db)
    _.forEach(state.tours, t => {
      if (t.tourModelId === tourModelId) {
        batch.update(doc(db, `tours/${t.id}`), { tourModelDeleted: timeNow })
      }
    })
    _.forEach(state.requestsDesign, rd => {
      if (rd.tourModelId === tourModelId) {
        batch.update(doc(db, `requestsDesign/${rd.id}`), {
          tourModelDeleted: timeNow
        })
      }
    })
    await batch.commit()
  } catch (e) {
    Sentry.captureException(e)
  }
}
