import { SceneComponent } from 'shared/components/SceneComponent'
import { MpSdk } from 'shared/bundle/sdk'
import {
  Object3D,
  ColorRepresentation,
  Vector3,
  DirectionalLight,
  DirectionalLightHelper
} from 'three'

export interface IMCustomDirectionalLight extends Record<string, unknown> {
  position: Vector3
  color: ColorRepresentation
  intensity: number
  distance: number | undefined
  decay: number | undefined
  angle: number | undefined
  penumbra: number | undefined
  debug: boolean
  castShadow: boolean
}

class MCustomDirectionalLight extends SceneComponent {
  private root: Object3D | null = null
  private light: DirectionalLight | null = null
  private lightHelper: DirectionalLightHelper | null = null

  inputs: IMCustomDirectionalLight = {
    position: new Vector3(0, 2, 0),
    color: 0xff66ff,
    intensity: 5,
    distance: 200,
    decay: 2,
    angle: Math.PI / 4,
    penumbra: 0.8,
    debug: true,
    castShadow: true
  }

  onInit () {
    const THREE = this.context.three
    this.root = new THREE.Object3D()
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root
    console.log('%cINIT SPOT LIGHT: this intputs', 'color: gold;', this.inputs)
    this.light = new THREE.DirectionalLight(
      this.inputs.color,
      this.inputs.intensity
    )
    this.light.name = 'custom_spot_light'
    this.light.position.copy(this.inputs.position)
    this.light.castShadow = this.inputs.castShadow
    this.light.shadow.mapSize.width = 4096
    this.light.shadow.mapSize.height = 4096
    this.light.shadow.camera.near = 0.5
    this.light.shadow.camera.far = 200
    this.light.shadow.camera.visible = true
    // this.light.shadow.bias = 0.0001
    // this.light.shadow.bias = -0.000222
    console.log('spot light', this.light)

    this.lightHelper = new THREE.DirectionalLightHelper(this.light)
    this.lightHelper.visible = this.inputs.debug
    this.lightHelper.position.copy(this.inputs.position)
    // this.pointLightHelper.castShadow = false

    this.root?.add(this.light)
    this.root?.add(this.lightHelper)
  }

  onInputsUpdated (oldInputs: IMCustomDirectionalLight) {
    if (oldInputs.position !== this.inputs.position && this.light) {
      // console.log('update hemisphere light position', this.inputs.position)
      // this.light.position.copy(this.inputs.position)
      // this.lightHelper?.position.copy(this.inputs.position)
    }
  }

  onDestroy () {
    if (this.light) {
      this.light.dispose()
      this.root?.remove(this.light)
    }
  }
}

export const customDirectionalLightType = 'mp.customDirectionalLight'
export const makeCustomDirectionalLight = (): MpSdk.Scene.IComponent => {
  return new MCustomDirectionalLight()
}
