import { Heading, HStack, VStack, Flex } from '@chakra-ui/react'
import _ from 'lodash'
import { useSelector } from 'model/hooks'
import ToursGettingStartedCard from 'pages/tours/toursContent/ToursGettingStartedCard'

const DEFAULT_CARDS = [
  {
    label: 'Add Tour',
    description: 'Go to Matterport and get the tour link of the property you want to virtually stage.'
  }, {
    label: 'Create Design',
    description: 'Create one or more virtually staged designs for your Matterport tour.'
  }, {
    label: 'Share',
    description: 'Publish the space with virtual staging on a listing, website, ad,  social media, or email it.'
  }
]

const ToursGettingStarted = () => {
  const partner = useSelector(state => state?.partner)
  const cards = _.get(partner, 'gettingStartedCards', DEFAULT_CARDS)
  return (
    <Flex w='full' justify='center' pt={12} position='relative'>
      <VStack spacing={6} align='flex-start'>
        <Heading size='md'>Let’s upstage your space!</Heading>
        <HStack w='3xl' spacing={8} align='flex-start'>
          <ToursGettingStartedCard
            num='1'
            title={_.get(cards, [0, 'label']) || DEFAULT_CARDS[0].label}
            description={_.get(cards, [0, 'description']) || DEFAULT_CARDS[0].description}
          />
          <ToursGettingStartedCard
            num='2'
            title={_.get(cards, [1, 'label']) || DEFAULT_CARDS[1].label}
            description={_.get(cards, [1, 'description']) || DEFAULT_CARDS[1].description}
          />
          <ToursGettingStartedCard
            num='3'
            title={_.get(cards, [2, 'label']) || DEFAULT_CARDS[2].label}
            description={_.get(cards, [2, 'description']) || DEFAULT_CARDS[2].description}
          />
        </HStack>
      </VStack>
    </Flex>
  )
}

export default ToursGettingStarted
