import _ from 'lodash'
import {
  SceneComponent,
  ComponentOutput
} from 'shared/components/SceneComponent'
import { Scene } from 'shared/bundle/sdk'

interface Outputs extends ComponentOutput {
  floors: number[]
}

class MFloorsCalculator extends SceneComponent {
  outputs = {
    floors: []
  } as Outputs

  onInit () {
    console.log('floors calculator init')
    _.forEach(this.context.scene.children, (ch: any) => {
      if (_.startsWith(ch.name, 'ModelMesh')) {
        const meshes = _.sortBy(ch.floorMeshes.list, 'name')
        const floors = _.map(meshes, (fm: any) => {
          console.log('Floor mesh', fm)
          return fm.center.y - fm.size.y / 2
        })
        console.log('return floors', floors)
        this.outputs.floors = floors
      }
    })
  }
}

export const floorsCalculatorType = 'mp.floorsCalculator'
export const makeFloorsCalculator = (): Scene.IComponent => {
  return new MFloorsCalculator()
}
