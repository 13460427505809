import {
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useRef,
  useImperativeHandle
} from 'react'
import {
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text
} from '@chakra-ui/react'

type Props = {
  onApply: () => void
}

export interface IResetDesignAlert {
  open: () => void
}

const ResetDesignAlert: ForwardRefRenderFunction<IResetDesignAlert, Props> = (
  { onApply },
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  useImperativeHandle(ref, () => ({
    open: () => {
      onOpen()
    }
  }))

  const apply = () => {
    onApply()
    onClose()
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Reset design
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>
              All items will be removed.
              <br />
              Are you sure? You {"can't"} undo this action afterwards
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='red' onClick={apply} ml={3}>
              Reset
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default forwardRef(ResetDesignAlert)
