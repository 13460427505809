import { FC, useEffect } from 'react'
import _ from 'lodash'
import { Flex } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import { initOnboarding } from 'model/actions'
import { useDispatch, useSelector } from 'model/hooks'
import { generateId } from 'controllers/db'

const OnboardingLayout: FC<{}> = () => {
  const onboarding = useSelector(state => state.onboarding)
  const partner = useSelector(state => state.partner)
  const dispatch = useDispatch()

  useEffect(() => {
    if (_.isNil(onboarding)) {
      const tourId = generateId()
      const tourModelId = generateId()
      dispatch(
        initOnboarding({
          modelId: null,
          modelDetails: undefined,
          name: '',
          email: '',
          tourModelId,
          tour: {
            id: tourId,
            name: 'Design 1',
            tourModelId,
            createdAt: 0,
            deleted: 0,
            slots: {},
            tourModelDeleted: 0,
            partnerId: _.get(partner, 'id', null)
          }
        })
      )
    }
  }, [onboarding])

  return (
    <Flex
      h='full'
      w='full'
      align='flex-start'
      justify={'center'}
      bg='gray.50'
      overflowY='auto'
    >
      <Outlet />
    </Flex>
  )
}

export default OnboardingLayout
