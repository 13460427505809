import { collection, query, onSnapshot, where } from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { db } from 'controllers/db'
import store from 'model/store'
import {
  receivePrices,
  receiveProducts,
  receiveSubscription
} from 'model/actions'
import { addListener } from 'controllers/listeners'
import { PriceT, DictT, ProductT, SubscriptionT } from 'shared/types/model'
import { getRequestHeaders } from 'controllers/headers'
import config from 'shared/config'

export const fetchPrices = async () => {
  console.log('fetch prices')
  const c = collection(db, 'prices')
  try {
    const unsubscribe = onSnapshot(
      c,
      sn => {
        const res: DictT<PriceT> = {}
        sn.forEach(doc => {
          const p = doc.data() as PriceT
          _.set(res, doc.id, p)
        })
        store.dispatch(receivePrices(res))
      },
      err => {
        console.log('fetch prices error', err)
        Sentry.captureException(err)
      }
    )
    addListener('prices', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch prices error', e)
  }
}

export const fetchProducts = async () => {
  console.log('fetch products')
  const q = query(collection(db, 'products'), where('active', '==', true))
  try {
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: DictT<ProductT> = {}
        sn.forEach(doc => {
          const p = doc.data() as ProductT
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveProducts(res))
      },
      err => {
        Sentry.captureException(err)
        console.log('fetch products error', err)
      }
    )
    addListener('products', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch products error', e)
  }
}

export const openPaymentPage = async (priceId?: string): Promise<boolean> => {
  try {
    const state = store.getState()
    const partnerId = _.get(state, 'partner.id', null)
    const url = `${config.backendUrl}/customer/create-checkout-session`
    const headers = await getRequestHeaders()
    const req = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        priceId,
        partnerId
      })
    })
    console.log('req', req)
    const res = await req.text()
    console.log('backend message', res)
    if (req.status === 200) {
      window.open(res, '_self', 'noopener,noreferrer')
      return true
    } else {
      // Sentry.captureException(new Error('cannot open payment page'))
      console.warn('cannot open payment page:', res)
      return false
    }
  } catch (e) {
    console.log('openPaymentPage error', e)
    Sentry.captureException(e)
    return false
  }
}

export const openRequestDesignPaymentPage = async (
  tourModelId: string,
  sf?: number
): Promise<boolean> => {
  try {
    const state = store.getState()
    const partnerId = _.get(state, 'partner.id', null)
    const userId = _.get(state, 'user.id', null)
    const url = `${config.backendUrl}/customer/create-request-design-checkout-session`
    const headers = await getRequestHeaders()
    const data = {
      tourModelId,
      sf,
      partnerId,
      userId
    }
    const req = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(_.omitBy(data, _.isUndefined))
    })
    console.log('req', req)
    const res = await req.text()
    console.log('backend message', res)
    if (req.status === 200) {
      window.open(res, '_self', 'noopener,noreferrer')
      return true
    } else {
      Sentry.captureException(new Error('cannot open payment page'))
      console.warn('cannot open payment page:', res)
      return false
    }
  } catch (e) {
    console.log('openRequestDesignPaymentPage error', e)
    Sentry.captureException(e)
    return false
  }
}

export const fetchSubscription = async (accountId: string): Promise<void> => {
  try {
    const state = store.getState()
    const partnerId = _.get(state, 'partner.id', null)
    const unsubscribe = onSnapshot(
      query(
        collection(db, 'subscriptions'),
        where('accountId', '==', accountId),
        where('status', '==', 'active'),
        where('partnerId', '==', partnerId)
      ),
      sn => {
        if (!_.isEmpty(sn.docs)) {
          const res = sn.docs[0].data() as SubscriptionT
          store.dispatch(receiveSubscription(res || null))
        } else {
          store.dispatch(receiveSubscription(null))
        }
      },
      err => {
        Sentry.captureException(err)
        console.log('fetch subscription error', err)
      }
    )
    addListener('subscription', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchSubscription error', e)
  }
}
