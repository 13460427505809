import { createReducer } from '@reduxjs/toolkit'
import { ProductT, DictT } from 'types/model'
import { receiveProducts, clear, logout } from 'model/actions'

const initialState: DictT<ProductT> = {}

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveProducts, (state, action) => action.payload)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default reducer
