import { createReducer } from '@reduxjs/toolkit'
import { CustomerProfileT, DictT } from 'shared/types/model'
import { receiveProfiles, clear, logout } from 'model/actions'

const initialState = {} as DictT<CustomerProfileT>

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveProfiles, (state, action) => action.payload)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default reducer
