import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getTours } from 'model/selectors/base'
import { DictT, TourT } from 'shared/types/model'

export const getToursByModel = createSelector([getTours], tours => {
  const res: DictT<DictT<TourT>> = {}
  _.forEach(tours, t => {
    if (!t.deleted || t.deleted === 0) {
      _.set(res, [t.tourModelId, t.id], t)
    }
  })
  return res
})

export const getEnabledToursByModel = createSelector(
  [getToursByModel],
  toursByModel => {
    const res: DictT<DictT<TourT>> = {}
    _.forEach(toursByModel, (tours, modelId) => {
      const enabledTours = _.filter(tours, t => t.enabled || false)
      if (!_.isEmpty(enabledTours)) {
        _.set(res, modelId, _.keyBy(enabledTours, 'id'))
      }
    })
    return res
  }
)
