import { createReducer } from '@reduxjs/toolkit'
import { CustomerT } from 'shared/types/model'
import { receiveUser, logout } from 'model/actions'

const initialState = null as CustomerT | null

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveUser, (state, action) => action.payload)
    // .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default reducer
