import { createReducer } from '@reduxjs/toolkit'
import { PriceT, DictT } from 'types/model'
import { receivePrices, clear, logout } from 'model/actions'

const initialState: DictT<PriceT> = {}

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receivePrices, (state, action) => action.payload)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default reducer
