import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import numeral from 'numeral'

import { getTourModelsUsed } from 'model/selectors/tourModels'
import { getTeammatesAmount } from 'model/selectors/team'
import { getProducts, getPrices, getSubscription, getPartner } from 'model/selectors/base'
import { PriceT, ProductT, DictT } from 'shared/types/model'
import { FREE_PLAN_LIMIT, FREE_PLAN_USERS } from 'shared/constants/paymentPlans'

export const getPublicProducts = createSelector([getProducts], products => {
  const res: DictT<ProductT> = {}
  _.forEach(products, p => {
    if (_.get(p, ['metadata', 'isPublic'], false)) {
      res[p.id] = p
    }
  })
  return res
})

export const getPricesByInterval = createSelector(
  [getProducts, getPrices],
  (products, prices) => {
    console.log('all prices', prices)
    const neededPrices = _.filter(
      prices,
      p =>
        _.get(products, [p.product, 'metadata', 'isPublic'], false) &&
        p.active &&
        !p.metadata.isDesignPrice
    )
    console.log('neededPrices', neededPrices)
    const groupedByInterval = _.groupBy(neededPrices, p =>
      _.get(p, 'recurring.interval', 'month')
    )
    const sortPrices = (ar: PriceT[]) => {
      return _.sortBy(ar, pr => {
        const amount = _.get(products, [pr.product, 'metadata', 'amount'])
        return _.toNumber(amount)
      })
    }
    return {
      year: sortPrices(groupedByInterval.year),
      month: sortPrices(groupedByInterval.month)
    }
  }
)

export const getCurrentPlanUsage = createSelector(
  [
    getSubscription,
    getPrices,
    getProducts,
    getTourModelsUsed,
    getTeammatesAmount,
    getPartner
  ],
  (subscription, prices, products, used, usersUsed, partner) => {
    const unlimitedPlans = _.get(partner, 'unlimitedPlans', false)
    let res = {
      name: 'Free',
      toursIncluded: FREE_PLAN_LIMIT,
      usersIncluded: FREE_PLAN_USERS,
      usersUsed,
      used,
      usersLimitReached: unlimitedPlans ? false : usersUsed >= FREE_PLAN_USERS,
      limitReached: unlimitedPlans ? false : used >= FREE_PLAN_LIMIT,
      subscriptionPeriodStart: 0,
      isFree: true
    }
    if (!_.isNil(subscription) && !_.isNil(prices) && !_.isNil(products)) {
      const price = prices[subscription.priceId]
      const product = products[subscription.productId]
      if (price && product) {
        res = {
          name: `${numeral(price.unitAmount / 100).format('$0')}/${
            _.get(price, 'recurring.interval') === 'month' ? 'mo' : 'yr'
          }`,
          usersUsed,
          usersIncluded: product.metadata.isCustom || unlimitedPlans
            ? usersUsed + 1
            : _.toNumber(product.metadata.users),
          toursIncluded: product.metadata.isCustom || unlimitedPlans
            ? used + 1
            : _.toNumber(product.metadata.amount),
          used,
          limitReached: product.metadata.isCustom || unlimitedPlans
            ? false
            : used >= _.toNumber(product.metadata.amount),
          usersLimitReached: product.metadata.isCustom || unlimitedPlans
            ? false
            : usersUsed >= _.toNumber(product.metadata.users),
          subscriptionPeriodStart: subscription.currentPeriodStart,
          isFree: false
        }
      }
    }

    return res
  }
)

export const getDesignPrice = createSelector([getPrices], prices => {
  return _.find(
    prices,
    p =>
      _.get(p, 'metadata.isDesignPrice', false) &&
      !_.get(p, 'metadata.isPerSf', false)
  )
})

export const getPerSFPrice = createSelector([getPrices], prices => {
  return _.find(
    prices,
    p =>
      _.get(p, 'metadata.isDesignPrice', false) &&
      _.get(p, 'metadata.isPerSf', false)
  )
})
