import { FC, useState, useMemo } from 'react'
import validator from 'validator'
import {
  Avatar,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Text,
  VStack,
  useDisclosure,
  HStack,
  useBreakpointValue,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faPlus } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'
import { accountRoles } from 'shared/dictionaries'
import { removeInvitation, sendInvitation } from 'controllers/invitations'
import { useSelector } from 'model/hooks'
import {
  CustomerProfileT,
  DictT,
  AccountT,
  InvitationT
  // AccountProfileT
} from 'shared/types/model'
import { faEllipsisH, faTrash } from '@fortawesome/pro-light-svg-icons'
import { generateId } from 'controllers/db'
import {
  getCurrentAccountProfile,
  getCurrentUserProfile
} from 'model/selectors/profiles'
import { getCurrentPlanUsage } from 'model/selectors/paymentPlans'
import { useNavigate } from 'react-router'

const Team: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [email, setEmail] = useState<string>('')
  const profile = useSelector(getCurrentUserProfile)
  const accProfile = useSelector(getCurrentAccountProfile)
  const profiles: DictT<CustomerProfileT> = useSelector(state => state.profiles)
  const account: AccountT | null = useSelector(state => state.account)
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const invitations = useSelector(state => state.invitations)
  const currentPlanUsage = useSelector(getCurrentPlanUsage)
  const navigate = useNavigate()

  const canProceed = useMemo((): boolean => {
    return email.length > 0 && validator.isEmail(email)
  }, [email])

  const submit = () => {
    if (canProceed && account && profile && accProfile) {
      const id = generateId()
      const inv: InvitationT = {
        id,
        accountId: account.id,
        createdAt: _.now(),
        createdBy: profile.email,
        email,
        accountName: accProfile.name || '',
        status: 'pending'
      }
      sendInvitation(inv)
      onClose()
    }
  }

  const openInvitationDialog = () => {
    if (currentPlanUsage.usersLimitReached) {
      navigate('/settings/billing')
    } else {
      setEmail('')
      onOpen()
    }
  }

  const renderHeader = () => {
    return (
      <HStack
        w='full'
        py={{ base: 4, lg: 8 }}
        px={{ base: 4, lg: 8 }}
        justify='space-between'
        align={'center'}
      >
        <VStack w='full' align={'flex-start'} spacing={{ base: 1, lg: 2 }}>
          <Heading fontSize='lg' fontWeight='bold' color='gray.900'>
            Team
          </Heading>
          {currentPlanUsage.usersLimitReached ? (
            <Text color='red.500' fontSize='sm'>
              Teammates amount limit is reached. Upgrade to invite more users.
            </Text>
          ) : (
            <Text
              color='gray.600'
              fontSize={{ lg: 'sm', base: 'xs' }}
              fontWeight='normal'
            >
              {isDesktop
                ? 'Invite your teammates to your Upstager account'
                : 'Invite your teammates'}
            </Text>
          )}
        </VStack>
        <VStack>
          <Button
            leftIcon={<FontAwesomeIcon color='white' size='xs' icon={faPlus} />}
            onClick={openInvitationDialog}
            colorScheme='blue'
            fontSize={{ base: 'xs', lg: 'md' }}
          >
            Invite teammate
          </Button>
        </VStack>
      </HStack>
    )
  }

  const renderRow = (
    id: string,
    user: boolean,
    avatar?: string,
    name?: string,
    description?: string,
    status?: string
  ) => (
    <HStack key={id} w='full' align='center' justify='space-between'>
      <HStack spacing={{ base: 2, lg: 6 }}>
        <Avatar
          size={{ base: 'sm', lg: 'lg' }}
          name={name}
          bg={user ? 'teal.600' : 'gray.200'}
          src={avatar}
          icon={
            !user ? (
              <FontAwesomeIcon
                icon={faClock}
                size={isDesktop ? 'lg' : 'xs'}
                color='white'
              />
            ) : undefined
          }
        />
        <Flex direction='column'>
          <Heading
            size={{
              base: 'xs',
              lg: 'md'
            }}
            fontWeight='bold'
            color='gray.800'
          >
            {name}
          </Heading>
          <Text
            mt='1'
            fontSize='sm'
            lineHeight='5'
            fontWeight='normal'
            color={user ? 'gray.600' : 'gray.400'}
          >
            {description}
          </Text>
        </Flex>
      </HStack>
      <Flex align='center' gap='10'>
        <Text
          fontSize={{
            base: 'xs',
            lg: 'sm'
          }}
          fontWeight='normal'
          color={{
            base: 'gray.400',
            lg: 'gray.600'
          }}
        >
          {status}
        </Text>
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FontAwesomeIcon icon={faEllipsisH} />}
            variant='ghost'
            size='md'
            disabled={user}
          />
          <MenuList zIndex={100}>
            {!user && (
              <MenuItem
                color='gray.500'
                icon={<FontAwesomeIcon icon={faTrash} fixedWidth />}
                onClick={() => removeInvitation(id)}
              >
                <Text color='gray.700' fontWeight='semibold'>
                  Remove invitation
                </Text>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Flex>
    </HStack>
  )

  return (
    <Flex
      w='full'
      px={{ lg: '8', base: '0' }}
      py={{ lg: '4', base: '0' }}
      pb={4}
      overflowY='auto'
      bg={{ lg: 'gray.50', base: 'white' }}
    >
      <VStack
        w='full'
        bg='white'
        boxShadow={{ lg: 'base', base: 'none' }}
        borderRadius='2xl'
        align='flex-start'
        h='fit-content'
        spacing={0}
      >
        {renderHeader()}
        <Divider color={'gray.100'} />
        <VStack
          w='full'
          overflowY='auto'
          p={{ lg: '8', base: '4' }}
          spacing={6}
        >
          {_.map(account?.admins, value => {
            const profile = _.get(profiles, value.id)
            const email = _.get(profile, 'email', '')
            const role = _.get(accountRoles, value.role, '')
            return renderRow(
              value.id,
              true,
              profile?.avatar,
              profile?.name,
              email,
              role
            )
          })}
          {_.map(invitations, value => {
            const email = _.get(value, 'email')
            return renderRow(
              value.id,
              false,
              undefined,
              undefined,
              email,
              _.upperFirst(_.get(value, 'status', 'pending'))
            )
          })}
        </VStack>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Invite teammate</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex>
                <FormControl isRequired>
                  <FormLabel htmlFor='email'>Email</FormLabel>
                  <Input
                    id='email'
                    type='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onKeyPress={async e => {
                      if (e.key === 'Enter') {
                        submit()
                      }
                    }}
                  />
                </FormControl>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button variant='ghost' onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme='blue'
                  disabled={!canProceed}
                  onClick={submit}
                >
                  Submit
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>
    </Flex>
  )
}

export default Team
