import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getAccount, getInvitations } from 'model/selectors/base'

export const getTeammatesAmount = createSelector(
  [getAccount, getInvitations],
  (account, invitations) => {
    return _.size(account?.admins) + _.size(invitations)
  }
)
