import { createReducer } from '@reduxjs/toolkit'
import { DictT, SupplierT } from 'types/model'
import { receiveSuppliers } from 'model/actions'

const initialState = {} as DictT<SupplierT>

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveSuppliers, (state, action) => action.payload)
  // .addCase(clear, () => initialState)
  // .addCase(logout, () => initialState)
})

export default reducer
