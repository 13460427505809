import {
  CustomerT,
  DictT,
  RoomTypeT,
  ItemT,
  PriceT,
  ProductT,
  RequestDesignT,
  SettingsT,
  SlotT,
  SubscriptionT,
  SupplierT,
  TourModelT,
  TourT,
  ItemsTemplateT,
  OnboardingT,
  TourSlotT,
  AccountProfileT,
  AccountT,
  CustomerProfileT,
  InvitationT,
  PartnerT,
  NoTourAccountDataT
} from 'shared/types/model'
import { createAction } from '@reduxjs/toolkit'

export const receiveRoomTypes =
  createAction<DictT<RoomTypeT>>('RECEIVE_ROOM_TYPES')

export const receiveTemplates =
  createAction<DictT<ItemsTemplateT>>('RECEIVE_TEMPLATES')

export const clear = createAction('CLEAR')
export const logout = createAction('LOGOUT')
export const receiveAuthData = createAction<{
  uid: string
  email: string | null
} | null>('RECEIVE_AUTH_DATA')

export const receiveItems = createAction<DictT<ItemT>>('RECEIVE_ITEMS')
export const clearItems = createAction('CLEAR_ITEMS')

export const receiveRequestsDesign = createAction<DictT<RequestDesignT>>(
  'RECEIVE_REQUESTS_DESIGN'
)

export const receiveSettings = createAction<SettingsT>('RECEIVE_SETTINGS')
export const receiveSlots = createAction<DictT<SlotT>>('RECEIVE_SLOTS')
export const receiveSuppliers =
  createAction<DictT<SupplierT>>('RECEIVE_SUPPLIERS')
export const receiveTourModels = createAction<DictT<TourModelT> | null>(
  'RECEIVE_TOUR_MODELS'
)

export const addMattertag = createAction<{ tourSlotId: string; tagId: string }>(
  'ADD_MATTERTAG'
)
export const removeMattertag = createAction<{ tourSlotId: string }>(
  'REMOVE_MATTERTAG'
)
export const resetMattertags = createAction('RESET_MATTERTAGS')

export const receivePrices = createAction<DictT<PriceT>>('RECEIVE_PRICES')
export const receiveProducts = createAction<DictT<ProductT>>('RECEIVE_PRODUCTS')
export const receiveSubscription = createAction<SubscriptionT | null>(
  'RECEIVE_SUBSCRIPTION'
)

export const receiveTours = createAction<DictT<TourT>>('RECEIVE_TOURS')
export const receiveUser = createAction<CustomerT>('RECEIVE_USER')

export const updateOnboarding =
  createAction<Partial<OnboardingT>>('UPDATE_ONBOARDING')
export const initOnboarding = createAction<OnboardingT>('INIT_ONBOARDING')
export const resetOnboarding = createAction('RESET_ONBOARDING')
export const oboardingAddTourSlots = createAction<DictT<TourSlotT>>(
  'ONBOARDING_ADD_TOUR_SLOTS'
)
export const onboardingResetDesign = createAction('ONBOARDING_RESET_DESIGN')
export const onboardingUpdateTourSlot = createAction<{
  slotId: string
  key: keyof TourSlotT
  value: any
}>('ONBOARDING_UPDATE_TOUR_SLOT')
export const onboardingDeleteTourSlot = createAction<string>(
  'ONBOARDING_DELETE_TOUR_SLOT'
)
export const receiveUserAccounts = createAction<DictT<AccountProfileT>>(
  'RECEIVE_USER_ACCOUNTS'
)

export const receiveAccount = createAction<AccountT | null>('RECEIVE_ACCOUNT')
export const receiveProfiles =
  createAction<DictT<CustomerProfileT>>('RECEIVE_PROFILES')
export const receiveAccountProfile = createAction<AccountProfileT | null>(
  'RECEIVE_ACCOUNT_PROFILE'
)
export const receiveInvitations = createAction<DictT<InvitationT>>(
  'RECEIVE_INVITATIONS'
)

export const receivePartner = createAction<PartnerT | null>('RECEIVE_PARTNER')

export const receiveNoTourAccountData = createAction<NoTourAccountDataT | null>('RECEIVE_NO_TOUR_ACCOUNT_DATA')
