import { FC } from 'react'
import { IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Flex, Box, Text } from '@chakra-ui/react'

const AccountProfileButton: FC<{
  label?: string
  sublabel?: string
  avatar?: string
  icon?: IconDefinition
  onClick?: () => void
}> = ({ label, sublabel, avatar, icon, onClick }) => {
  const renderIcon = () => {
    if (icon) {
      return <FontAwesomeIcon icon={icon} />
    } else {
      return <Avatar size='sm' name={label} borderRadius='6px' src={avatar} />
    }
  }
  return (
    <Flex gap='10px' onClick={onClick}>
      <Box color='gray.500'>{renderIcon()}</Box>
      <Box my='auto'>
        <Text color='gray.800' size='sm' textAlign='start'>
          {label}
        </Text>
        <Text color='gray.500' fontSize='xx-small' textAlign='start'>
          {sublabel}
        </Text>
      </Box>
    </Flex>
  )
}

export default AccountProfileButton
