import { useEffect, useRef, FC } from 'react'
import { MpSdk, Scene } from 'shared/bundle/sdk'
import { DictT } from 'shared/types/model'
import _ from 'lodash'
import { floorsCalculatorType } from 'shared/components/matterport/MFloorsCalculator'

type Props = {
  sdk: MpSdk
  onFloorsReady: (floors: number[]) => void
}

const FloorsCalculator: FC<Props> = ({ sdk, onFloorsReady }) => {
  const soRef = useRef<MpSdk.Scene.IObject | null>(null)
  const subscriptionsRef = useRef<DictT<MpSdk.ISubscription>>({})

  useEffect(() => {
    const run = async () => {
      const [sceneObj]: MpSdk.Scene.IObject[] = await sdk.Scene.createObjects(1)
      soRef.current = sceneObj
      const node = sceneObj.addNode()
      const component = node.addComponent(floorsCalculatorType)

      const outputPath = sceneObj.addPath({
        id: 'floors_calculator',
        type: 'output' as Scene.PathType.OUTPUT,
        node,
        component,
        property: 'floors'
      })
      const outputSpy = {
        path: outputPath,
        onEvent (floors: number[]) {
          console.log('floors output spy', floors)
          onFloorsReady(floors)
        }
      }

      subscriptionsRef.current.intSpy = sceneObj.spyOnEvent(outputSpy)
      node.start()
    }
    run()
    return () => {
      _.forEach(subscriptionsRef.current, s => s.cancel())
      subscriptionsRef.current = {}
      soRef.current.stop()
    }
  }, [])

  return null
}

export default FloorsCalculator
