import { FC } from 'react'
import { Heading, VStack, Text } from '@chakra-ui/react'

type Props = {
  num: string
  title: string
  description: string
}

const ToursGettingStartedCard: FC<Props> = ({ num, title, description }) => {
  return (
    <VStack spacing={4} align='flex-start' flex={1}>
      <Text color='blue.500' fontSize={'2xl'}>
        {num}
      </Text>
      <Heading size={'sm'}>{title}</Heading>
      <Text size='sm' color='gray.600'>
        {description}
      </Text>
    </VStack>
  )
}

export default ToursGettingStartedCard
