import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState,
  useMemo,
  useEffect
} from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel,
  VStack,
  FormErrorMessage
} from '@chakra-ui/react'

import AddressAutocomplete from 'shared/components/AddressAutocomplete'
import { AddressT, TourModelT } from 'shared/types/model'
import { useSelector } from 'model/hooks'
import { dbUpdateTourModel } from 'controllers/tourModels'

export interface IEditTourModelModal {
  open: (tmId: string) => void
}

type ErrorsT = {
  model?: string
  address?: string
}

type Props = {}

const EditTourModelModal: ForwardRefRenderFunction<
  IEditTourModelModal,
  Props
> = (_props: Props, ref: Ref<unknown>) => {
  const tourModels = useSelector(state => state.tourModels)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tourModelId, setTourModelId] = useState<string | null>(null)
  const [address, setAddress] = useState<AddressT | undefined>()
  const [errors, setErrors] = useState<ErrorsT>({})
  const [name, setName] = useState('')

  const tourModel = useMemo(() => {
    if (tourModels && tourModelId) {
      return tourModels[tourModelId]
    }
  }, [tourModels, tourModelId])

  useEffect(() => {
    if (tourModel) {
      setAddress(tourModel.address)
      setName(tourModel.name || '')
    }
  }, [tourModel])

  useImperativeHandle(ref, () => ({
    open: (tmId: string) => {
      setTourModelId(tmId)
      onOpen()
    }
  }))

  const resetErrors = () => setErrors({})

  const onUpdateClick = async () => {
    console.log('tourModelId', tourModelId)
    const newErrors: ErrorsT = {}
    if (_.isEmpty(address)) {
      newErrors.address = 'Enter the property address please'
    }
    setErrors(newErrors)
    if (tourModelId && _.isEmpty(newErrors)) {
      try {
        const update: Partial<TourModelT> = {
          address,
          name
        }
        await dbUpdateTourModel(tourModelId, update)
        onClose()
      } catch (e) {
        console.error(e)
      }
    }
  }

  const renderAddressInput = () => {
    return (
      <FormControl isRequired isInvalid={!_.isNil(errors.address)}>
        <FormLabel htmlFor='tourAddress'>Property address</FormLabel>
        <AddressAutocomplete
          value={_.isNil(address) ? '' : address.description}
          onSelect={setAddress}
          inputProps={{ onFocus: resetErrors }}
        />
        {!_.isNil(errors.address) && (
          <FormErrorMessage>{errors.address}</FormErrorMessage>
        )}
      </FormControl>
    )
  }

  const renderNameInput = () => {
    return (
      <FormControl isRequired isInvalid={!_.isNil(errors.model)}>
        <FormLabel htmlFor='tour name'>Tour Name</FormLabel>
        <Input
          value={name}
          placeholder='tour name'
          onChange={e => setName(e.target.value)}
        />
      </FormControl>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={1}>Edit Tour Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} pt={8}>
            {renderAddressInput()}
            {renderNameInput()}
          </VStack>

          <Flex mb={4} pt={12} justify='flex-end'>
            <Button colorScheme={'teal'} ml={2} onClick={onUpdateClick}>
              Update
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(EditTourModelModal)
