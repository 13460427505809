import {
  useState,
  useEffect,
  ForwardRefRenderFunction,
  forwardRef,
  Ref,
  useImperativeHandle
} from 'react'
import _ from 'lodash'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  HStack,
  Stack,
  useColorModeValue,
  useDisclosure,
  VStack,
  Flex,
  Badge,
  Button
} from '@chakra-ui/react'

import { ItemT, SupplierT } from 'shared/types/model'
import ItemPhotos from 'shared/components/productCardModal/ItemPhotos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'

export interface IProductCardModal {
  open: (item: ItemT, supplier?: SupplierT) => void
}

type Props = {}

const ProductCardModal: ForwardRefRenderFunction<IProductCardModal, Props> = (
  _props: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [item, setItem] = useState<ItemT | null>(null)
  const [supplier, setSupplier] = useState<SupplierT | null>(null)

  const [selected, setSelected] = useState<number>(0)

  useImperativeHandle(ref, () => ({
    open: (item: ItemT, supplier = null) => {
      setSupplier(supplier)
      setItem(item)
      onOpen()
    }
  }))

  useEffect(() => {
    const target = document.getElementById(`photo-${selected}`)
    const container = document.getElementById('photos-wrapper')
    if (target && container) {
      if (
        target.getBoundingClientRect().bottom >
        container.getBoundingClientRect().bottom
      ) {
        target.scrollIntoView(false)
      }
      if (
        target.getBoundingClientRect().top <
        container.getBoundingClientRect().top
      ) {
        target.scrollIntoView()
      }
    }
  }, [selected])

  useEffect(() => {
    if (item) {
      setSelected(0)
    }
  }, [item])

  const descColor = useColorModeValue('gray.600', 'gray.400')

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='5xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box
            maxW='7xl'
            mx='auto'
            px={{ base: '4', md: '8', lg: '12' }}
            py={{ base: '6', md: '8', lg: '12' }}
          >
            <Stack direction={{ base: 'column', md: 'row' }} spacing='16'>
              {item && <ItemPhotos item={item} />}
              {/* <Box flex='1'>
                {!modelOpened && <Gallery images={photos} />}
                {modelOpened && <ModelViewer model={item?.model} />}
              </Box> */}
              <Box w='xs'>
                <Stack spacing='8'>
                  <Stack spacing='4'>
                    {supplier && (
                      <Box>
                        <Badge colorScheme='green'>{supplier.name}</Badge>
                      </Box>
                    )}
                    <Stack>
                      <Text
                        fontWeight={'semibold'}
                        lineHeight={1.2}
                        color='blue.800'
                      >
                        {item?.title}
                      </Text>
                    </Stack>
                    <HStack spacing='1' justify={'space-between'}>
                      <Button
                        variant={'link'}
                        rightIcon={<FontAwesomeIcon icon={faExternalLink} />}
                        onClick={() => window.open(item.link, '_blank')}
                        colorScheme='blue'
                        size='sm'
                      >
                        View item
                      </Button>
                    </HStack>
                    <Text color={descColor} whiteSpace='pre-line'>
                      {item?.desc}
                    </Text>
                    <VStack align={'flex-start'} spacing={1}>
                      {_.map(item?.specifications, (v, k) => {
                        return (
                          <Flex direction='row' key={k} w='full'>
                            <Flex flex={1}>
                              <Text
                                color={descColor}
                                fontWeight='semibold'
                                whiteSpace='pre-line'
                              >
                                {k}:
                              </Text>
                            </Flex>
                            <Flex flex={1}>
                              <Text color={descColor} whiteSpace='pre-line'>
                                {v}
                              </Text>
                            </Flex>
                          </Flex>
                        )
                      })}
                    </VStack>
                  </Stack>
                  <HStack
                    spacing={{ base: '4', md: '8' }}
                    align='flex-end'
                    justify='start'
                  ></HStack>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(ProductCardModal)
