import { createReducer } from '@reduxjs/toolkit'
import { AccountT } from 'shared/types/model'
import { receiveAccount, clear, logout } from 'model/actions'

const initialState = null as AccountT | null

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveAccount, (state, action) => action.payload)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default reducer
