import { FC } from 'react'
import Tour from 'shared/components/Tour'
import config from 'shared/config'

type Props = {
  modelId: string
}

const Space: FC<Props> = ({ modelId }) => {
  return (
    <Tour
      key={modelId}
      modelId={modelId}
      applicationKey={config.applicationKey}
      // onSdkReady={() => null}
      hideFloorPlan={false}
      quickStart={false}
    />
  )
}

export default Space
