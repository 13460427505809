import { query, collection, where, getDocs } from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { DictT, ItemsTemplateT } from 'shared/types/model'
import { receiveTemplates } from 'model/actions'

export const fetchTemplates = async () => {
  try {
    const state = store.getState()
    const tags = _.get(state, 'partner.tags')
    console.log('fetchTemplates', tags)
    let q = query(collection(db, 'templates'), where('enabled', '==', true))
    if (!_.isEmpty(tags)) {
      q = query(
        collection(db, 'templates'),
        where('enabled', '==', true),
        where('tags', 'array-contains-any', tags)
      )
    }
    const sn = await getDocs(q)
    const res: DictT<ItemsTemplateT> = {}
    sn.forEach(doc => {
      const p = doc.data() as ItemsTemplateT
      _.set(res, doc.id, p)
    })
    console.log('receive templates ', res)
    store.dispatch(receiveTemplates(res))
  } catch (e) {
    console.log('fetchTemplates error', e)
    Sentry.captureException(e)
  }
}
