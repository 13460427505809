import { FC } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  Image,
  Text
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { RoomTypeT } from 'shared/types/model'
import { noImageUrl } from 'shared/constants/helpers'

type Props = {
  goBack: () => void
  selectRoomType: (rtId: string) => void
  roomTypes: RoomTypeT[]
}

const CatalogPanel: FC<Props> = ({ goBack, selectRoomType, roomTypes }) => {
  const renderCategories = () => {
    return (
      <Wrap
        spacing={4}
        overflowY='auto'
        // scrollSnapType={'y proximity'}
        sx={{
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {_.map(roomTypes, (rt: RoomTypeT) => {
          return (
            <WrapItem key={rt.id} scrollSnapAlign='start'>
              <VStack align={'flex-start'} pb={4}>
                <Box
                  as='button'
                  _hover={{ boxShadow: 'md' }}
                  onClick={() => selectRoomType(rt.id)}
                  id={`room-type-${rt.id}`}
                >
                  <Image
                    boxSize={164}
                    alt={rt.name}
                    src={rt.photo || noImageUrl}
                  />
                </Box>
                <Text fontSize={'xs'} color='blackAlpha.900'>
                  {rt.name}
                </Text>
              </VStack>
            </WrapItem>
          )
        })}
      </Wrap>
    )
  }

  const header = (
    <Box pb={8} bg='white' p={4}>
      <Flex align='flex-start' justify={'space-between'}>
        <HStack align='flex-start' spacing={4} w='full'>
          <Box as='button' onClick={() => goBack()}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Box>
          <Flex align={'center'} justify='space-between' w={'full'}>
            <VStack align={'flex-start'}>
              <Heading size='md' color='black'>
                Room types
              </Heading>
            </VStack>
          </Flex>
        </HStack>
      </Flex>
    </Box>
  )

  return (
    <RightPanelWrapper show width={380}>
      <Flex flex={1} maxH='full' direction='column'>
        {header}
        <Flex
          direction='column'
          align='flex-start'
          justify='flex-start'
          flex={1}
          h='full'
          overflow='hidden'
          p={4}
        >
          {renderCategories()}
        </Flex>
      </Flex>
    </RightPanelWrapper>
  )
}

export default CatalogPanel
