import { FC } from 'react'
import { Flex, HStack, VStack } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

import SettingsNavBar from 'components/SettingsNavBar'

const SettingsLayout: FC<{}> = () => {
  return (
    <HStack
      align={'flex-start'}
      w='full'
      h='full'
      spacing={0}
      overflow={'hidden'}
    >
      <VStack w='full' h='full' overflow={'hidden'} spacing={0}>
        <SettingsNavBar />
        <Flex
          h='full'
          w='full'
          overflowY='scroll'
          bg={{ lg: 'gray.50', base: 'white' }}
        >
          <Outlet />
        </Flex>
      </VStack>
    </HStack>
  )
}

export default SettingsLayout
