import { FC, useState } from 'react'
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  VStack,
  Box,
  FormErrorMessage
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import validator from 'validator'

import { dbResetPassword } from 'controllers/auth'
import _ from 'lodash'
import AuthGeneralError from 'pages/auth/AuthGeneralError'
import PasswordResetedAlert from 'pages/auth/PasswordResetedAlert'
import AuthHeader from 'pages/auth/AuthHeader'

const ForgotPassword: FC = () => {
  const location = useLocation()
  const [email, setEmail] = useState<string>(_.get(location, 'state.email', ''))
  const [loading, setLoading] = useState<boolean>(false)
  const [generalError, setGeneralError] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [showPasswordResetedAlert, setShowPasswordResetedAlert] =
    useState(false)

  const navigate = useNavigate()

  const submit = async (): Promise<void> => {
    setGeneralError(null)
    setShowPasswordResetedAlert(false)
    let eError = null
    if (_.isEmpty(email)) {
      eError = 'Please enter your email'
    } else if (!validator.isEmail(email)) {
      eError =
        'Please enter a valid email address using the following format: name@example.com'
    }

    if (_.isNil(eError)) {
      setLoading(true)
      const isSent = await dbResetPassword(email)
      if (isSent) {
        setShowPasswordResetedAlert(true)
      } else {
        setGeneralError(
          'We could not send a password reset email to the provided address'
        )
      }
    }
    setEmailError(eError)
    setLoading(false)
  }

  const resetErrors = () => {
    setEmailError(null)
  }

  const pageHeader = (
    <AuthHeader
      title='Forgot your password?'
      description='Enter your email address below and we will send you instructios to reset your password.'
    />
  )

  const renderGeneralError = () => {
    if (!_.isNil(generalError)) {
      return (
        <AuthGeneralError
          errorMessage={generalError}
          onReset={() => setGeneralError(null)}
        />
      )
    }
  }

  const renderPasswordResetedAlert = () => {
    if (showPasswordResetedAlert) {
      return (
        <PasswordResetedAlert
          email={email}
          onClose={() => setShowPasswordResetedAlert(false)}
        />
      )
    }
  }

  const renderInputs = () => {
    return (
      <VStack spacing={5} w='full'>
        <FormControl isRequired isInvalid={!_.isNil(emailError)}>
          <FormLabel htmlFor='email'>Email</FormLabel>
          <Input
            id='email'
            type='email'
            value={email}
            color={_.isNil(emailError) ? undefined : 'red.500'}
            onFocus={resetErrors}
            onChange={e => setEmail(e.target.value)}
            onKeyPress={async e => {
              if (e.key === 'Enter') {
                submit()
              }
            }}
          />
          <FormErrorMessage>{emailError}</FormErrorMessage>
        </FormControl>
      </VStack>
    )
  }

  const renderCTA = () => {
    return (
      <Box w='full' pt={4}>
        <Button
          isLoading={loading}
          variant='solid'
          colorScheme={'teal'}
          onClick={submit}
          w='full'
          size='lg'
        >
          Continue
        </Button>
      </Box>
    )
  }

  const renderToAuth = () => {
    return (
      <HStack spacing='2' justify='center' align='center' pt={4}>
        <Button
          variant='link'
          color='blue.600'
          onClick={() => navigate('/auth/signin', { state: { email } })}
        >
          Back to log in
        </Button>
      </HStack>
    )
  }

  return (
    <VStack spacing='2' w='full'>
      {pageHeader}
      <VStack
        bgColor='white'
        p='10'
        w='full'
        maxW='2xl'
        rounded='lg'
        boxShadow='sm'
      >
        {renderPasswordResetedAlert()}
        {renderGeneralError()}
        {renderInputs()}
        {renderCTA()}
        {renderToAuth()}
      </VStack>
    </VStack>
  )
}

export default ForgotPassword
