import { useEffect, useRef, FC } from 'react'

import { MpSdk, Scene, Mode } from 'shared/bundle/sdk'
import { modelIntersectionType } from 'shared/components/matterport/MModelIntersection'
import { Vector3 } from 'three'
import { DictT } from 'shared/types/model'
import _ from 'lodash'

type Props = {
  sdk: MpSdk
  enabled: boolean
  onIntersect: (p: Vector3 | null) => void
  viewMode: Mode.Mode
}

const ModelIntersection: FC<Props> = ({
  sdk,
  enabled,
  onIntersect,
  viewMode
}) => {
  const nodeRef = useRef<MpSdk.Scene.INode | null>(null)
  const compRef = useRef<MpSdk.Scene.IComponent | null>(null)
  const soRef = useRef<MpSdk.Scene.IObject | null>(null)
  const subscriptionsRef = useRef<DictT<MpSdk.ISubscription>>({})

  useEffect(() => {
    const comp = compRef.current
    if (comp && comp.inputs) {
      comp.inputs.enabled = enabled
    }
  }, [enabled])

  useEffect(() => {
    const comp = compRef.current
    if (comp && comp.inputs) {
      comp.inputs.mode = viewMode
    }
  }, [viewMode])

  useEffect(() => {
    if (viewMode === 'mode.floorplan') {
      subscriptionsRef.current.pointer = sdk.Pointer.intersection.subscribe(
        function (intersectionData) {
          if (compRef.current && compRef.current.inputs) {
            // console.log('pointer intersection:', intersectionData)
            const p = intersectionData.position
            compRef.current.inputs.cameraPosition = new Vector3(p.x, 30, p.z)
            // console.log('Intersection normal:', intersectionData.normal)
          }
        }
      )
    } else if (subscriptionsRef.current && subscriptionsRef.current.pointer) {
      subscriptionsRef.current.pointer.cancel()
    }
  }, [viewMode])

  useEffect(() => {
    const run = async () => {
      const [sceneObj]: MpSdk.Scene.IObject[] = await sdk.Scene.createObjects(1)
      soRef.current = sceneObj
      const node = sceneObj.addNode()
      compRef.current = node.addComponent(modelIntersectionType, {
        enabled,
        cameraPosition: new Vector3(),
        mode: viewMode
      })
      node.start()

      const outputPath = sceneObj.addPath({
        id: 'intersect',
        type: 'output' as Scene.PathType.OUTPUT,
        node,
        component: compRef.current,
        property: 'intersect'
      })
      const outputSpy = {
        path: outputPath,
        onEvent (p: Vector3 | null) {
          // console.log('onEvent', p)
          onIntersect(p)
        }
      }

      subscriptionsRef.current.intSpy = sceneObj.spyOnEvent(outputSpy)

      // sdk.Floor.current.subscribe(function (currentFloor) {
      //   if (currentFloor.sequence === -1) {
      //     console.log('Currently viewing all floors')
      //   } else if (currentFloor.sequence === undefined) {
      //     if (currentFloor.id === undefined) {
      //       console.log('Current viewing an unplaced unaligned sweep')
      //     } else {
      //       console.log('Currently transitioning between floors')
      //     }
      //   } else {
      //     console.log('Currently on floor', currentFloor)
      //     console.log("Current floor's sequence index", currentFloor.sequence)
      //     console.log("Current floor's name", currentFloor.name)
      //   }
      // })
    }
    run()
    return () => {
      _.forEach(subscriptionsRef.current, s => s.cancel())
      const node = nodeRef.current
      node?.stop()
    }
  }, [])

  return null
}

export default ModelIntersection
