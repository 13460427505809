import { FC } from 'react'
import {
  Badge,
  HStack,
  Text,
  Image,
  VStack,
  Button,
  Flex,
  IconButton,
  Tooltip
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCopy,
  faEdit,
  faEye,
  faTrashCan
} from '@fortawesome/pro-regular-svg-icons'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import numeral from 'numeral'
import _ from 'lodash'

import { MpSdk } from 'shared/bundle/sdk'
import { getItemPhotoUrl } from 'shared/utils/itemsUtils'
import { ItemT } from 'shared/types/model'

type Props = {
  sdk: MpSdk
  item: ItemT
  index: number
  quantity: number
  openProductCard: (item: ItemT) => void
  addToRoom: (itemId: string) => void
  onRemove: () => void
  mattertagId?: string
  supplierName?: string
  onSelect: () => void
  canEdit?: boolean
  pricesVisible: boolean
}
const PanelCartItem: FC<Props> = ({
  sdk,
  item,
  // index,
  // quantity,
  openProductCard,
  onRemove,
  mattertagId,
  supplierName,
  addToRoom,
  onSelect,
  canEdit,
  pricesVisible
}) => {
  const navigateToItem = async () => {
    if (mattertagId) {
      sdk.Mattertag.navigateToTag(mattertagId, sdk.Mattertag.Transition.FLY)
    }
  }

  const onSelectClick = () => {
    navigateToItem()
    onSelect()
  }

  const renderButtons = () => {
    if (canEdit) {
      return (
        <Flex direction='row' gap='2'>
          <Tooltip label='Edit'>
            <IconButton
              minW='0'
              aria-label='edit item'
              icon={<FontAwesomeIcon icon={faEdit} fixedWidth size='1x' />}
              variant='link'
              colorScheme='black'
              color='blue.800'
              flex={1}
              onClick={onSelectClick}
              justifyContent='start'
            />
          </Tooltip>
          <Tooltip label='Remove'>
            <IconButton
              minW='0'
              aria-label='remove'
              icon={<FontAwesomeIcon icon={faTrashCan} fixedWidth size='1x' />}
              variant='link'
              colorScheme='red'
              color='red.900'
              onClick={onRemove}
              flex={1}
              justifyContent='start'
            />
          </Tooltip>
          <Tooltip label='Duplicate'>
            <IconButton
              minW='0'
              aria-label='duplicate item'
              icon={<FontAwesomeIcon icon={faCopy} fixedWidth size='1x' />}
              variant='link'
              colorScheme='black'
              color='blue.800'
              flex={1}
              onClick={() => addToRoom(item.id)}
              justifyContent='start'
            />
          </Tooltip>
        </Flex>
      )
    }
  }

  return (
    <VStack w='full' pb={4} _hover={{ bg: 'gray.200' }} px={4} role='group'>
      <HStack
        pt={4}
        bg='transparent'
        align='flex-start'
        w='full'
        spacing={4}
        scrollSnapAlign='start'
      >
        <VStack
          minW='100px'
          position='relative'
          justify='flex-start'
          spacing={0}
          h='full'
        >
          <Image boxSize='100' src={getItemPhotoUrl(item)} rounded='lg' />
          <Flex
            bg='whiteAlpha.300'
            position='absolute'
            w='100px'
            h='100px'
            left={0}
            top={0}
            rounded='lg'
            direction='column'
            justify='start'
            align='end'
            color='gray.600'
            as='button'
            onClick={() => openProductCard(item)}
            display='none'
            _groupHover={{ display: 'flex' }}
            p='2'
          >
            <FontAwesomeIcon icon={faEye} size='1x' />
          </Flex>
        </VStack>
        <VStack align='flex-start' w='full' h='full' gap='2' position='relative'>
          <Text fontWeight='semibold' lineHeight={1.2} color='blue.800'>
            {item.title}
          </Text>
          <HStack justify='space-between' w='full' align='center'>
            {renderButtons()}
            {item.price && pricesVisible && (
              <Text fontSize='sm' fontWeight='bold' color='blue.700' textAlign='center'>
                {numeral(item.price).format('$0,0.00')}
              </Text>
            )}
          </HStack>
          <Flex w='full' justify='space-between'>
            <Button
              size='xs'
              variant='link'
              colorScheme='gray.600'
              iconSpacing={1}
              rightIcon={
                <FontAwesomeIcon
                  icon={faChevronRight}
                  color='gray.600'
                  fontSize='0.8em'
                />
              }
              onClick={() => openProductCard(item)}
            >
              More info
            </Button>
            <Badge colorScheme='green'>
              {_.truncate(supplierName, { length: 16 })}
            </Badge>
          </Flex>
        </VStack>
      </HStack>
    </VStack>
  )
}

export default PanelCartItem
