import {
  ComponentOutput,
  SceneComponent
} from 'shared/components/SceneComponent'
import { MpSdk, Mode } from '../../bundle/sdk'
import {
  Object3D,
  Raycaster,
  Vector2,
  Event,
  Intersection,
  Vector3
} from 'three'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import _ from 'lodash'

interface Outputs extends ComponentOutput {
  selectedObjects: string[]
}

export interface IMOutlinePassInputs extends Record<string, unknown> {
  effectComposer: EffectComposer | null
  excludeItem: string | null
  position: Vector2
  cameraPosition: Vector3
  mode: Mode.Mode
}

class MOutlinePass extends SceneComponent {
  private root: Object3D | null = null
  private outlinePass: OutlinePass | null = null
  private composer: EffectComposer | null = null
  private selectedObjects: Object3D[] = []
  private raycaster: Raycaster | null = null
  private prevIntersects: Intersection<Object3D<Event>>[] | undefined
  // private circle: Mesh | null = null
  private rayDirection: Vector3 = new Vector3(0, -1, 0)

  inputs: IMOutlinePassInputs = {
    effectComposer: null,
    excludeItem: null,
    position: new Vector2(0, 0),
    cameraPosition: new Vector3(),
    mode: 'mode.inside' as Mode.Mode
  }

  outputs = {
    selectedObjects: []
  } as Outputs

  private getFurnitureMain = (obj: Object3D<Event>): Object3D<Event> | null => {
    const isFurnitureMain = _.get(obj, 'userData.isFurnitureMain', false)
    if (isFurnitureMain) {
      if (this.inputs.excludeItem && this.inputs.excludeItem === obj.name) {
        return null
      } else {
        // console.log('furniture main', obj.name, obj.userData)
        return obj
      }
    } else if (obj && obj.parent) {
      return this.getFurnitureMain(obj.parent)
    } else {
      return null
    }
  }

  onInputsUpdated (oldInputs: IMOutlinePassInputs) {
    if (oldInputs.excludeItem !== this.inputs.excludeItem) {
      this.prevIntersects = undefined
      this.selectedObjects = []
      if (this.outlinePass) {
        this.outlinePass.selectedObjects = this.selectedObjects
      }
    }

    // if (this.inputs.mode !== oldInputs.mode) {
    // console.log('mode changed', this.inputs.mode)
    // console.log(this.context)
    // }
    if (
      this.inputs.mode !== 'mode.floorplan' &&
      oldInputs.position !== this.inputs.position
    ) {
      // console.log('position changed', this.inputs.position)
      this.raycaster?.setFromCamera(this.inputs.position, this.context.camera)
      const intersects = this.raycaster?.intersectObjects(
        this.context.scene.children,
        true
      )
      this.processIntersects(intersects)
    } else if (
      this.inputs.mode === 'mode.floorplan' &&
      oldInputs.cameraPosition !== this.inputs.cameraPosition
    ) {
      this.raycaster?.set(this.inputs.cameraPosition, this.rayDirection)
      // console.log('this.pointer', this.pointer)
      // this.raycaster.setFromCamera(this.pointer, this.context.camera)
      const intersects = this.raycaster?.intersectObjects(
        this.context.scene.children,
        true
      )
      this.processIntersects(intersects)
      // console.log('intersects', intersects)
      // const int = _.find(
      //   intersects,
      //   i => i.object.visible && i.object.opacity === 1
      // )
      // this.outputs.intersect = int ? int.point : null
    }
  }

  processIntersects = intersects => {
    if (!_.isEqual(intersects, this.prevIntersects)) {
      this.prevIntersects = intersects
      // console.log('intersects', intersects)
      // const meshesObjects = []
      this.selectedObjects = []

      let objEv
      _.forEach(intersects, i => {
        if (
          _.get(i, 'object.parent.name') === 'items_shadows_node' &&
          this.inputs.mode === 'mode.inside'
        ) {
          return false
        }
        if (_.get(i.object, 'userData.isFurniture', false)) {
          objEv = i
          return false
        }
        // console.log(i.object.layers, i.object.name, i.object.parent.name)
        // return _.get(i.object, 'userData.isFurniture', false)
      })
      // console.groupEnd()
      if (objEv) {
        const obj = objEv.object
        const furnitureObject: Object3D<Event> | null =
          this.getFurnitureMain(obj)
        if (furnitureObject) {
          // console.log('furnitureObject', furnitureObject)
          this.selectedObjects.push(furnitureObject)
          // this.selectedObjects = furnitureObject.children
        }
      }
      if (this.outlinePass) {
        this.outlinePass.selectedObjects = this.selectedObjects
        this.outputs.selectedObjects = _.compact(
          _.map(this.selectedObjects, o => _.get(o, 'userData.itemId'))
        )
      }
    }
  }

  onInit () {
    // console.log('%coutline pass onInit', 'color: red;', this.context)
    const THREE = this.context.three
    // console.log('THREE', THREE)
    // const renderer = this.context.renderer
    this.root = new THREE.Object3D()
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root
    this.composer = this.inputs?.effectComposer
    if (this.composer) {
      const OPass = _.get(this.context.three, 'OutlinePass')
      // console.log('OutlinePassClass', OPass)
      // console.log('this.root.parent', this.root.parent)
      const oPass: OutlinePass = new OPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        this.context.scene,
        this.context.camera
      )
      this.outlinePass = oPass
      oPass.edgeStrength = 3
      oPass.edgeGlow = 1
      oPass.resolution.set(2048, 2048)
      oPass.visibleEdgeColor.set(new THREE.Color('#FFD700'))
      oPass.hiddenEdgeColor.set(new THREE.Color('#FFD700'))
      this.composer.insertPass(oPass, 1)
      this.raycaster = new this.context.three.Raycaster()
      // this.raycaster.layers.enableAll()
      this.raycaster.layers.enable(8)
      this.raycaster.layers.enable(4)

      // this.raycaster.layers.enable(8)

      this.raycaster.params = {
        Mesh: {},
        Line: { threshold: 1 },
        LOD: {},
        Points: { threshold: 1 },
        Sprite: {}
      }
      // const showcaseElt = window.document.getElementById('showcase')
      // console.log('showcaseElt', showcaseElt)
      // showcaseElt?.addEventListener('pointermove', this.onPointerMove)
      // renderer.domElement.addEventListener('pointermove', this.onPointerMove)
    } else {
      console.error('effect composer is null')
    }
    console.log('this.context', this.context)
  }

  onDestroy () {
    console.log('%coutline pass onDestroy', 'color: red;')
    if (this.outlinePass) {
      this.composer?.removePass(this.outlinePass)
    }
  }
}

export const outlinePassType = 'mp.outlinePass'
export const makeOutlinePass = (): MpSdk.Scene.IComponent => {
  return new MOutlinePass()
}
