import { FC } from 'react'
// import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HStack, Button, Tooltip, Text, Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

type Props = {
  visible: boolean
  tourId: string
  tourModelId: string
}

const OnboardingDesignNavBar: FC<Props> = ({ visible }) => {
  const navigate = useNavigate()

  const goNext = () => {
    navigate({
      pathname: '/auth/signup',
      search: '?onboarding'
    })
  }

  if (visible) {
    return (
      <HStack
        direction='row'
        position={'absolute'}
        align='center'
        bg='white'
        w='full'
        px={2}
        py={2}
        justify='flex-start'
      >
        <Tooltip label='Designs save automatically' hasArrow>
          <Button
            variant={'outline'}
            // leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
            onClick={goNext}
          >
            Continue
          </Button>
        </Tooltip>
        <HStack pr={'400px'}>
          <Flex>
            <Text maxW={'80'} fontSize='sm' color='gray.600' ml='8'>
              Once your design is ready, click `Continue` to proceed and share
              the design
            </Text>
          </Flex>
        </HStack>
      </HStack>
    )
  } else {
    return null
  }
}

export default OnboardingDesignNavBar
