import { SceneComponent } from 'shared/components/SceneComponent'
import { MpSdk } from 'shared/bundle/sdk'
import {
  Object3D,
  PointLight,
  PointLightHelper,
  ColorRepresentation,
  Vector3
} from 'three'

export interface IMCustomPointLight extends Record<string, unknown> {
  position: Vector3
  color: ColorRepresentation
  intensity: number
  distance: number | undefined
  decay: number | undefined
  debug: boolean
  castShadow: boolean
  shadowMapSize?: {
    width: number
    height: number
  }
  shadowCamera?: {
    near: number
    far: number
  }
}

class MCustomPointLight extends SceneComponent {
  private root: Object3D | null = null
  private pointLight: PointLight | null = null
  private pointLightHelper: PointLightHelper | null = null

  inputs: IMCustomPointLight = {
    position: new Vector3(0, 0, 0),
    color: 0xffffff,
    intensity: 1,
    distance: 0,
    decay: 1,
    debug: false,
    castShadow: false,
    shadowMapSize: {
      width: 512,
      height: 512
    },
    shadowCamera: {
      near: 0.5,
      far: 500
    }
  }

  onInit () {
    const THREE = this.context.three
    this.root = new THREE.Object3D()
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root
    this.pointLight = new THREE.PointLight(
      this.inputs.color,
      this.inputs.intensity,
      this.inputs.distance,
      this.inputs.decay
    )
    this.pointLight.name = 'custom_point_light'
    this.pointLight.position.copy(this.inputs.position)
    this.pointLight.castShadow = this.inputs.castShadow
    this.pointLight.shadow.mapSize.width =
      this.inputs.shadowMapSize?.width || 512
    this.pointLight.shadow.mapSize.height =
      this.inputs.shadowMapSize?.height || 512
    this.pointLight.shadow.camera.near = this.inputs.shadowCamera?.near || 0.5
    this.pointLight.shadow.camera.far = this.inputs.shadowCamera?.far || 500
    this.pointLight.shadow.radius = 4
    this.pointLight.shadow.bias = 0.0001

    const sphereSize = 0.1
    this.pointLightHelper = new THREE.PointLightHelper(
      this.pointLight,
      sphereSize
    )
    this.pointLightHelper.visible = this.inputs.debug
    // this.pointLightHelper.castShadow = false

    this.root?.add(this.pointLight)
    this.root?.add(this.pointLightHelper)
  }

  onInputsUpdated (oldInputs: IMCustomPointLight) {
    if (oldInputs.position !== this.inputs.position && this.pointLight) {
      // console.log('update hemisphere light position', this.inputs.position)
      this.pointLight.position.copy(this.inputs.position)
      this.pointLightHelper?.position.copy(this.inputs.position)
    }
  }

  onDestroy () {
    if (this.pointLight) {
      this.pointLight.dispose()
      this.root?.remove(this.pointLight)
    }
  }
}

export const customPointLightType = 'mp.customPointLight'
export const makeCustomPointLight = (): MpSdk.Scene.IComponent => {
  return new MCustomPointLight()
}
