import _ from 'lodash'
import { ItemT, FileT, DictT, TourSlotT } from 'shared/types/model'
import { noImageUrl } from 'shared/constants/helpers'

export const itemsIdsBySlots = (tourSlots: DictT<TourSlotT>) => {
  const timestamps: DictT<number> = {}
  _.forEach(tourSlots, tourSlot => {
    const curT = timestamps[tourSlot.itemId] || Infinity
    if (tourSlot.createdAt < curT) {
      timestamps[tourSlot.itemId] = tourSlot.createdAt
    }
  })
  const sortedItemsIds = _.sortBy(_.keys(timestamps), id => timestamps[id])
  return sortedItemsIds
}

export const getItemPhotoUrl = (item: ItemT) => {
  if (item.defaultPhotoId && _.has(item, ['photos', item.defaultPhotoId])) {
    const img: FileT | undefined = _.get(item, ['photos', item.defaultPhotoId])
    if (img) {
      return img.thumbnailUrl || img.url
    } else {
      return noImageUrl
    }
  } else {
    const photos = _.sortBy(_.values(item.photos), 'createdAt')
    const img = _.first(photos)
    if (img) {
      return img.thumbnailUrl || img.url
    } else {
      return noImageUrl
    }
  }
}

export default {
  getItemPhotoUrl
}
