import _ from 'lodash'
import { SceneComponent } from 'shared/components/SceneComponent'
import { Scene, Vector3 } from 'shared/bundle/sdk'
import { PMREMGenerator, Object3D } from 'three'

export interface IMRoomShadow extends Record<string, unknown> {
  screenShotUri?: string
  position?: Vector3
  shadowsEnabled: boolean
}

class RoomShadow extends SceneComponent {
  private root: Object3D | null = null
  private pmremGenerator: PMREMGenerator | null = null

  addPmremGenerator = () => {
    const THREE = this.context.three
    const scene = this.context.scene
    console.log('ROOM SHADOWS: addPmremGenerator')
    if (this.pmremGenerator) {
      // const textureUrl =
      //   'https://firebasestorage.googleapis.com/v0/b/upstager-dev.appspot.com/o/properties%2F2HVP384R2bJoNRitufsP%2FAg12ejXWWm0ijyXRPpdy%2F4edn8RLWuKrZc0gGZc1V_medium?alt=media&token=23d3a24b-5331-4097-aaf1-206856a3f8ec'
      const textureUrl =
        'https://firebasestorage.googleapis.com/v0/b/upstager-dev.appspot.com/o/env_map.png?alt=media&token=7a1bae86-50b7-4009-abdb-223fbff007aa'
      const getCubeMapTexture = () => {
        return new Promise((resolve, reject) => {
          new THREE.TextureLoader().load(
            textureUrl,
            texture => {
              const envMap =
                this.pmremGenerator?.fromEquirectangular(texture).texture
              this.pmremGenerator?.dispose()
              console.log('ROOM SHADOWS:: ENV MAP TEXTURE loaded', envMap)
              resolve({ envMap })
            },
            undefined,
            err => {
              console.error('ROOM SHADOWS:: texture loading error', err)
              reject(err)
            }
          )
        })
      }
      getCubeMapTexture()
        .then((texture: unknown) => {
          const envMap = _.get(texture, 'envMap')
          console.log('APPPLY ENV MAP')
          scene.environment = envMap
          scene.background = envMap
        })
        .catch(e => {
          console.error('addPmremGenerator error,', e)
        })
    }
  }

  onInit () {
    console.log('ROOM SHADOWS INIT')
    const THREE = this.context.three
    const renderer = this.context.renderer
    this.pmremGenerator = new THREE.PMREMGenerator(renderer)
    this.pmremGenerator.compileEquirectangularShader()
    this.root = new THREE.Object3D()
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root
    this.root.renderOrder = 2
    this.root.layers.set(1)
    // this.root.layers.enable(80)
    this.root.name = 'items_shadows_node'

    _.forEach(this.context.scene.children, (ch: any) => {
      if (_.startsWith(ch.name, 'ModelMesh')) {
        _.forEach(ch.children, (fm: any) => {
          if (_.startsWith(fm.name, 'FloorMesh')) {
            _.forEach(fm.children, (rm: any) => {
              rm.geometry.computeVertexNormals()
              rm.transparent = false

              if (_.isArray(rm.material)) {
                _.forEach(rm.material, m => {
                  m.transparent = false
                  m.depthFunc = THREE.LessDepth
                })
                const mat2 = new THREE.ShadowMaterial()
                mat2.color.set('#000000')
                mat2.opacity = 0.07
                mat2.side = THREE.FrontSide
                mat2.transparent = true
                mat2.depthFunc = THREE.LessEqualDepth
                const geo = rm.geometry.clone()
                const plane = new THREE.Mesh(geo, mat2)
                plane.receiveShadow = true
                plane.translateY(0.005)
                plane.receiveShadow = true
                this.root?.add(plane)
              }
              // console.log('rm', rm)
            })
          }
        })
      }
    })

    this.context.scene.receiveShadow = true
    this.context.camera.updateMatrix()

    this.addPmremGenerator()
  }

  onDestroy () {
    this.pmremGenerator && this.pmremGenerator.dispose()
  }
}

export const roomShadowType = 'mp.roomShadow'
export const makeRoomShadow = (): Scene.IComponent => {
  return new RoomShadow()
}
