import {
  ForwardRefRenderFunction,
  Ref,
  useImperativeHandle,
  useState,
  forwardRef,
  useMemo
} from 'react'
import {
  Button,
  useDisclosure,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  // ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  useClipboard,
  Tooltip,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  RadioGroup,
  Radio,
  Input,
  Textarea
} from '@chakra-ui/react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft
} from '@fortawesome/pro-regular-svg-icons'

import config from 'shared/config'
import { useSelector } from 'model/hooks'
import { getEnabledToursByModel } from 'model/selectors/tours'
import { handleShareTour } from 'controllers/tours'
import { dbUpdateUser } from 'controllers/user'

export type IShareTourModal = {
  open: (tourModelId: string) => void
}

type Props = {}

enum EMBED_MODE {
  RESPONSIBLE = 'RESPONSIBLE',
  FIXED = 'FIXED'
}

const ShareTourModal: ForwardRefRenderFunction<IShareTourModal, Props> = (
  _props: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [embedMode, setEmbedMode] = useState(EMBED_MODE.RESPONSIBLE)
  const [iframeWidth, setIFrameWidth] = useState('640')
  const [iframeHeight, setIFrameHeight] = useState('480')
  const [tourModelId, setTourModelId] = useState('')
  const enabledToursByModel = useSelector(getEnabledToursByModel)
  const { onCopy, setValue, hasCopied } = useClipboard('')
  const user = useSelector(state => state.user)

  const hasDesigns = useMemo(() => {
    const tours = _.get(enabledToursByModel, tourModelId)
    return !_.isEmpty(tours)
  }, [tourModelId, enabledToursByModel])

  const embedCode = useMemo(() => {
    const strW = embedMode === EMBED_MODE.FIXED ? iframeWidth + 'px' : '100%'
    const strH = embedMode === EMBED_MODE.FIXED ? iframeHeight + 'px' : '100%'
    return `<iframe src="${config.userAppUrl}/${tourModelId}" width="${strW}" height="${strH}" />`
  }, [iframeWidth, iframeHeight, embedMode, tourModelId])

  const copyEmbedCode = () => {
    setValue(embedCode)
    onCopy()
    if (user?.id) {
      const heapCounters = { ...user?.heapCounters, codeCopied: (user?.heapCounters?.codeCopied || 0) + 1 }
      dbUpdateUser(user.id, { heapCounters })
    }
  }

  useImperativeHandle(ref, () => ({
    open: (tourModelId: string) => {
      setTourModelId(tourModelId)
      setValue(`${config.userAppUrl}/${tourModelId}`)
      onOpen()
    }
  }))

  const handleCopy = () => {
    handleShareTour()
    onCopy()
    if (user?.id) {
      const heapCounters = { ...user?.heapCounters, linkCopied: (user?.heapCounters?.linkCopied || 0) + 1 }
      dbUpdateUser(user.id, { heapCounters })
    }
  }

  const renderAlert = () => {
    if (!hasDesigns) {
      return (
        <VStack pt={8} align='flex-start' spacing={0}>
          <Text color='red.600' fontWeight={'semibold'}>
            No designs created or enabled
          </Text>
          <Text fontSize={'sm'} fontWeight='semibold'>
            Your real estate leads will see an empty space without staging.
            Create or enable designs
          </Text>
        </VStack>
      )
    }
  }

  const renderShareContent = () => {
    return (
      <>
        <ModalBody h='full' display={'flex'} flexDirection='column'>
          <VStack align='flex-start' fontSize={'md'} py={6} spacing={0}>
            <Text>
              Share the copied link to the Upstaged tour with your Real Estate
              Leads.
            </Text>
            <Text>
              {"Here's what they can do when they open the link you share:"}
            </Text>
            <Text as='li' pl={4}>
              <strong>View the tour with enabled designs</strong>
            </Text>
            <Text as='li' pl={4}>
              <strong>Create designs on their own</strong>
            </Text>
            <Text
              as='li'
              listStyleType={'circle'}
              fontSize='sm'
              pl={10}
              color='gray.600'
            >
              {"You won't be charged for any designs created by users."}
            </Text>
            {renderAlert()}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Text fontSize={'xs'}>
              Copy the link and paste into your listing, ad, website or send it
              directly to clients.
            </Text>
            <Button
              flexShrink={0}
              variant='outline'
              onClick={() =>
                window.open(`${config.userAppUrl}/${tourModelId}`, '_blank')
              }
            >
              Preview tour
            </Button>
            <Tooltip
              label={hasCopied ? 'Link copied' : 'Copy link'}
              closeDelay={1000}
              hasArrow
            >
              <Button colorScheme='blue' onClick={handleCopy} flexShrink={0}>
                Copy link
              </Button>
            </Tooltip>
          </HStack>
        </ModalFooter>
      </>
    )
  }

  const renderEmbedTab = () => {
    return (
      <>
        <ModalBody>
          <RadioGroup
            onChange={v => setEmbedMode(v as EMBED_MODE)}
            value={embedMode}
            colorScheme='green'
            pb={8}
          >
            <VStack align='flex-start' pt={8} spacing={2}>
              <Radio value={EMBED_MODE.RESPONSIBLE}>Responsive size</Radio>
              <HStack>
                <Radio value={EMBED_MODE.FIXED} pr={8}>
                  Fixed size
                </Radio>
                <Input
                  size='sm'
                  isDisabled={embedMode === EMBED_MODE.RESPONSIBLE}
                  w={'24'}
                  type='number'
                  value={iframeWidth}
                  onChange={e => setIFrameWidth(e.target.value)}
                />
                <Text>x</Text>
                <Input
                  size='sm'
                  isDisabled={embedMode === EMBED_MODE.RESPONSIBLE}
                  w={'24'}
                  type='number'
                  value={iframeHeight}
                  onChange={e => setIFrameHeight(e.target.value)}
                />
              </HStack>
            </VStack>
          </RadioGroup>
          <Textarea size='sm' value={embedCode} isReadOnly color={'gray.500'} />
        </ModalBody>
        <ModalFooter>
          <Tooltip
            label={hasCopied ? 'Code copied' : 'Copy code'}
            closeDelay={1000}
            hasArrow
          >
            <Button
              // variant='outline'
              onClick={copyEmbedCode}
              flexShrink={0}
              leftIcon={
                <HStack spacing={0.5}>
                  <FontAwesomeIcon icon={faChevronLeft} size='xs' />
                  <FontAwesomeIcon icon={faChevronRight} size='xs' />
                </HStack>
              }
            >
              Copy embed code
            </Button>
          </Tooltip>
        </ModalFooter>
      </>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent w='xl'>
        {/* <ModalHeader borderBottomWidth={1}>Share tour</ModalHeader> */}
        <ModalCloseButton />
        <Tabs variant='enclosed' p={4} colorScheme='blue'>
          <TabList>
            <Tab>Share</Tab>
            <Tab>Embed</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>{renderShareContent()}</TabPanel>
            <TabPanel p={0}>{renderEmbedTab()}</TabPanel>
          </TabPanels>
        </Tabs>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(ShareTourModal)
