import { FC } from 'react'
import { Flex } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

const AuthPageLayout: FC<{}> = () => {
  return (
    <Flex
      h='full'
      w='full'
      align='flex-start'
      justify={'center'}
      bg='gray.50'
      pt={12}
      pb={12}
      overflowY='auto'
    >
      <Outlet />
    </Flex>
  )
}

export default AuthPageLayout
