import { FC, useMemo } from 'react'
import { Text, Center, HStack, VStack } from '@chakra-ui/react'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { useSelector } from 'model/hooks'
import { getDesignPrice } from 'model/selectors/paymentPlans'

type Props = {
  price: string
  isMonth: boolean
  isCustom: boolean
  isBasic: boolean
}

const PlanFeatures: FC<Props> = ({ price, isMonth, isCustom, isBasic }) => {
  const period = isMonth ? 'mo' : 'yr'
  const designPrice = useSelector(getDesignPrice)

  const features = useMemo(() => {
    const res = [
      {
        title: 'DIY virtual staging tool'
      },
      {
        title: 'Interactive tours for real estate buyers'
      },
      {
        title: 'Unlimited designs per space'
      },
      {
        title: 'Unlimited revisions of designs'
      },
      designPrice
        ? {
            title: `Request professional designs - starting  $${
              designPrice.unitAmount / 100
            } per design`
          }
        : null,
      {
        title: 'Shoppable furniture items'
      },
      {
        title: 'Add your company logo to the tour'
      },
      {
        title: 'Multiple business roles',
        isBasic
      },
      {
        title: 'Advanced business features',
        isBasic
      },
      {
        title: 'Collaborations with furniture brands',
        isBasic
      }
    ]
    // if (designPrice) {
    //   res.push({
    //     title: `Request designs -  additional $${
    //       designPrice.unitAmount / 100
    //     } per design`,
    //     desc: 'An Upstager designer will add designs to your tours for you'
    //   })
    // }
    return _.compact(res)
  }, [designPrice, isBasic])

  const renderRow = (
    f: { title: string; desc: string; isBasic: boolean },
    i: number
  ) => {
    return (
      <HStack key={i} spacing='2' align={'flex-start'}>
        <Center
          rounded={'full'}
          bg={f.isBasic ? 'gray.100' : 'teal.100'}
          boxSize={4}
          color={f.isBasic ? 'gray.500' : 'teal.300'}
          flexShrink={0}
          mt={0.5}
        >
          <FontAwesomeIcon icon={faCheck} fontSize='0.5em' fixedWidth />
        </Center>
        <VStack align='flex-start' spacing={0}>
          <Text
            fontWeight={'semibold'}
            color={f.isBasic ? 'gray.300' : 'gray.800'}
            fontSize={'sm'}
            textDecoration={f.isBasic ? 'line-through' : undefined}
          >
            {f.title}
          </Text>
          <Text fontWeight={'light'} color='gray.500' fontSize={'xs'}>
            {f.desc}
          </Text>
        </VStack>
      </HStack>
    )
  }

  return (
    <VStack
      px={{ base: '4', lg: '0' }}
      pt={{ base: '9', lg: '0' }}
      w={{ base: 'full', lg: '65%' }}
      spacing={2}
      align='flex-start'
    >
      <Text fontWeight={'bold'} color='gray.800'>
        {isCustom ? 'Custom pricing' : `${price}/${period} plan includes`}
      </Text>
      {_.map(features, renderRow)}
    </VStack>
  )
}

export default PlanFeatures
