import { VStack, Flex, Button, Heading } from '@chakra-ui/react'
import { ReactComponent as Logo } from 'shared/assets/logo.svg'

const MobilePlaceholder = () => {
  return (
    <VStack h='full' w='full' align={'center'} justify='center' pb={32} px={12}>
      <Flex justifyContent='center'>
        <Button variant='link' as='a' href='https://upstager.co/'>
          <Logo width='142px' height='32px' />
        </Button>
      </Flex>
      <Heading size='sm' pt={2} textAlign='center'>
        Unfortunately we currently do not support mobile devices. Please, open
        the url on desktop to continue.
      </Heading>
    </VStack>
  )
}

export default MobilePlaceholder
