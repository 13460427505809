import { FC } from 'react'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HStack, Button, Tooltip, Text, Switch, Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { dbUpdateTourPath } from 'controllers/tours'
import { useSelector } from 'model/hooks'
import config from 'shared/config'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  visible: boolean
  tourId: string
  tourModelId: string
  canEdit: boolean
}

const EditTourNavBar: FC<Props> = ({
  visible,
  tourId,
  tourModelId,
  canEdit
}) => {
  const t = useSelector(state => state.tours[tourId])
  const navigate = useNavigate()

  const toDashboard = () => {
    navigate({
      pathname: `/tour/${tourModelId}`,
      search: '?design-complete'
    })
  }

  if (visible) {
    return (
      <HStack
        direction='row'
        position={'absolute'}
        align='center'
        bg='white'
        w='full'
        px={2}
        py={2}
        justify='space-between'
      >
        <Tooltip label='Designs save automatically' hasArrow>
          <Button
            variant={'ghost'}
            leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
            onClick={toDashboard}
          >
            Exit
          </Button>
        </Tooltip>
        {canEdit && (
          <HStack pr={'400px'}>
            <Flex>
              <Text maxW={'80'} fontSize='sm' color='gray.600' ml='24'>
                Once your design is ready, select enable so it can be viewed
                inside the Upstaged tour link.
              </Text>
            </Flex>
            <HStack minW='28' px={8}>
              <Switch
                colorScheme={'teal'}
                isChecked={t.enabled}
                // boxShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'
                sx={{
                  span: {
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0.5px 0.5px 0.5px 0.5px'
                  }
                }}
                onChange={v =>
                  dbUpdateTourPath(t.id, 'enabled', v.target.checked)
                }
              />
              <Text fontSize={'sm'}>{t.enabled ? 'Enabled' : 'Disabled'}</Text>
            </HStack>
            <Button
              size='sm'
              variant={'outline'}
              leftIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
              onClick={() =>
                window.open(`${config.userAppUrl}/${tourModelId}`, '_blank')
              }
            >
              Preview
            </Button>
          </HStack>
        )}
      </HStack>
    )
  } else {
    return null
  }
}

export default EditTourNavBar
