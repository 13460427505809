import { FC } from 'react'
import {
  HStack,
  Tooltip,
  Button,
  Heading,
  VStack
  // Text
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  onAddTourClick: () => void
}

const ToursNavBar: FC<Props> = ({ onAddTourClick }) => {
  const renderButtons = () => {
    return (
      <Tooltip
        label='Add a new tour'
        shouldWrapChildren
        hasArrow
        closeDelay={1000}
        closeOnClick={false}
      >
        <Button
          id='customer-nav-add-tour-button'
          size='lg'
          variant='solid'
          colorScheme='blue'
          onClick={onAddTourClick}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          Add tour
        </Button>
      </Tooltip>
    )
  }

  return (
    <HStack
      px='4'
      w='full'
      align='center'
      justify='space-between'
      h='28'
      flexShrink={0}
      bg='white'
      borderLeftWidth={1}
      borderLeftColor='gray.50'
      borderBottomWidth={1}
      borderBottomColor='gray.100'
    >
      <VStack align='flex-start'>
        <Heading>Tours</Heading>
        {/* <Text>
          Select <strong>Copy link</strong> and then paste it (command + v) in a
          new tab to open your tour with the UpStager solution added
        </Text> */}
      </VStack>
      {renderButtons()}
    </HStack>
  )
}

export default ToursNavBar
