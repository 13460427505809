import _ from 'lodash'
import Resizer from 'react-image-file-resizer'
import { convertAsync } from 'shared/utils/png2ico'

export const resizeFile = async (
  file: File,
  size: number = 400
): Promise<File> =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      size,
      size,
      'PNG',
      100,
      0,
      f => resolve(f as File),
      'file'
    )
  })

const b64toByteArrays = (b64Data: string) => {
  const sliceSize = 512

  const byteCharacters = atob(
    b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, '')
  )
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }
  return byteArrays
}

const b64toFile = (b64Data: string, fileName: string, contentType: string) => {
  const byteArrays = b64toByteArrays(b64Data)
  const file = new File(byteArrays, fileName, {
    type: contentType,
    lastModified: _.now()
  })
  console.log('b64toFile res', file)
  return file
}

export const convertToIco = async (file: File) => {
  const byteArray = await convertAsync([{ png: file }])
  console.log('ico', byteArray)
  const byteArrays = []
  byteArrays.push(byteArray)
  const res = new File(byteArrays, 'favicon.ico', {
    type: 'image/x-icon',
    lastModified: _.now()
  })
  return res
}

export const resizeFileSquare = async (
  file: File,
  size: number = 400
): Promise<File> => {
  const contentType = 'image/png'
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const image = new Image()
      // console.log('reader result', reader.result)
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = size
        canvas.height = size
        const ctx = canvas.getContext('2d')
        if (ctx) {
          // console.log('ctx', ctx)
          ctx.drawImage(image, 0, 0, size, size)
          const resizedDataUrl = canvas.toDataURL(contentType, 1)
          const fileName = file.name
          const fileNameWithoutFormat = fileName
            .toString()
            .replace(/(png|jpeg|jpg|webp)$/i, '')
          const newFileName = fileNameWithoutFormat.concat('png')
          // console.log('newFileName', newFileName)
          const newFile: File = b64toFile(
            resizedDataUrl,
            newFileName,
            contentType
          )
          // console.log('return file', newFile)
          resolve(newFile)
        } else {
          reject(new Error('ctx is undefined'))
        }
      }
      image.src = reader.result as string
    }
  })
}
