import { initializeApp } from 'firebase/app'
import { getFirestore, doc, collection, deleteField } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage, ref } from 'firebase/storage'
import config from 'shared/config'
import { DictT } from 'types/model'
import { mapObject } from 'shared/utils/object'

const firebaseApp = initializeApp(config)

export const db = getFirestore()
export const auth = getAuth()
export const storage = getStorage(firebaseApp)
export const storageRef = ref(storage)

export const generateId = (): string => {
  return doc(collection(db, 'tmp')).id
}

export const dbOmit = (data: DictT<any>) => {
  return mapObject(data, value => (value === undefined ? deleteField() : value))
}
