import { FC, useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import { Text } from '@chakra-ui/react'
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList
} from '@choc-ui/chakra-autocomplete'
// import { getDomesticAddress } from 'shared/utils/stringUtils'
import * as googleApis from '../utils/googleApis'
import { v4 } from 'uuid'
// import { getDomesticAddress } from 'shared/utils/stringUtils'
import { DictT, AddressT } from '../types/model'

const MIN_SEARCH_LENGTH = 2
const MAX_LENGTH = 100

type AddressAutocompleteProps = {
  value: string
  onSelect: (address: AddressT) => void
  inputProps?: any
  autoCompleteProps?: any
}

type SearchingRequestT = {
  abort: () => void
}

/* eslint camelcase: ["error", {properties: "never"}] */
type OptionT = {
  place_id: string
  description: string
}

const AddressAutocomplete: FC<AddressAutocompleteProps> = ({
  value,
  onSelect,
  inputProps,
  autoCompleteProps
}) => {
  const [sessionToken] = useState(v4())
  const [address, setAddress] = useState(value)
  const [options, setOptions] = useState<DictT<OptionT>>({})
  const searchingRequests = useRef<SearchingRequestT[]>([])
  const inputRef = useRef<HTMLInputElement>(null)

  console.log('AddressAutocomplete, value', value)

  useEffect(() => {
    console.log('set address', value)
    setAddress(value)
  }, [value])

  const onChange = (event: { target: { value: string } }) => {
    const text = event.target.value
    // console.log('address autocompletion onChange', text)
    setAddress(text.substring(0, MAX_LENGTH))
  }

  const abortSearchingRequests = () => {
    const sr = searchingRequests.current
    _.forEach(sr, r => r && r.abort())
    searchingRequests.current = []
  }

  const updateSearchResults = (results: Array<OptionT>) => {
    const newOptions: DictT<OptionT> = {}

    _.forEach(results, r => {
      newOptions[r.place_id] = r
    })
    setOptions(newOptions)
  }

  const addSearchingRequest = (r: SearchingRequestT) => {
    const curRequests = searchingRequests.current || []
    searchingRequests.current = [r, ...curRequests]
  }

  const search = () => {
    if (address.length >= MIN_SEARCH_LENGTH) {
      abortSearchingRequests()
      const request = googleApis.autocomplete(
        address,
        sessionToken,
        (responseJSON: object) => {
          const results = _.get(responseJSON, 'predictions', [])
          if (!_.isEmpty(results)) {
            updateSearchResults(results)
          } else {
            updateSearchResults([])
          }
        }
      )
      addSearchingRequest(request)
    } else {
      updateSearchResults([])
    }
  }

  const debouncedSearch = _.debounce(search, 500)

  useEffect(() => {
    debouncedSearch()
  }, [address])

  const onSelectOption = async (value: string) => {
    console.log('onSelectOption', value)
    const item = _.get(options, value)
    console.log('onPressSearchResult', item)
    const structured: { main: string; secondary: string } = {
      main: _.get(item, 'structured_formatting.main_text', ''),
      secondary: _.get(item, 'structured_formatting.secondary_text', '')
    }
    try {
      const res = await googleApis.getPlaceDetailsFormatted(value, structured)
      onSelect({ ...res, placeId: value })
      inputRef.current.blur()
    } catch (e) {
      // Sentry.captureException(e)
      console.log('get details error', e)
    }
  }

  const autocompleteOptions: DictT<OptionT> = _.size(options) > 1 ? options : {}

  return (
    <AutoComplete
      // w='full'
      rollNavigation
      // openOnFocus
      emptyState={
        <Text textAlign={'center'} fontSize={'sm'} color='gray.500'>
          <i>no suggestions</i>
        </Text>
      }
      // bgColor='red'
      onChange={onSelectOption}
      {...autoCompleteProps}
    >
      <AutoCompleteInput
        ref={inputRef}
        w='100%'
        variant={'outline'}
        placeholder='Enter address or city'
        autoFocus={_.isEmpty(value)}
        onChange={onChange}
        value={address}
        onBlur={() => setAddress(value)}
        sx={{
          input: { bgColor: 'white' }
        }}
        {...inputProps}
      />
      <AutoCompleteList bgColor='yellow'>
        {_.map(autocompleteOptions, (option, oid) => (
          <AutoCompleteItem key={`option-${oid}`} value={oid} fixed>
            {option.description}
          </AutoCompleteItem>
        ))}
      </AutoCompleteList>
    </AutoComplete>
  )
}

export default AddressAutocomplete
