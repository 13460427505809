import { FC, useState, useRef } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  FormErrorMessage
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'
import _ from 'lodash'
import AuthHeader from 'pages/auth/AuthHeader'
import { useDispatch } from 'model/hooks'
import { updateOnboarding } from 'model/actions'

const OnboardingUInfo: FC = () => {
  const dispatch = useDispatch()
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [nameError, setNameError] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<string | null>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  const submit = async (): Promise<void> => {
    let eError = null
    let nError = null
    console.log('name, email', name, email)
    if (_.isEmpty(email)) {
      eError = 'Please enter your email'
    } else if (!validator.isEmail(email)) {
      eError =
        'Please enter a valid email address using the following format: name@example.com'
    }
    if (_.isEmpty(_.toString(name))) {
      nError = 'Please enter your name'
    }

    if (_.isNil(eError) && _.isNil(nError)) {
      dispatch(
        updateOnboarding({
          name,
          email
        })
      )
      navigate('/onboarding/design')
    }
    setNameError(nError)
    setEmailError(eError)
  }

  const pageHeader = (
    <AuthHeader
      title={'Try a demo'}
      description={
        'With this interactive demo, you will see how Upstager works in minutes'
      }
    />
  )

  const resetErrors = () => {
    setNameError(null)
    setEmailError(null)
  }

  const renderInputs = () => {
    return (
      <VStack spacing={5} w='full'>
        <FormControl isRequired isInvalid={!_.isNil(nameError)}>
          <FormLabel htmlFor='name'>Name</FormLabel>
          <Input
            id='name'
            type='text'
            value={name}
            onFocus={resetErrors}
            color={_.isNil(nameError) ? undefined : 'red.500'}
            onChange={e => setName(e.target.value)}
            onKeyPress={async e => {
              if (e.key === 'Enter') {
                emailInputRef.current?.focus()
              }
            }}
          />
          <FormErrorMessage>{nameError}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!_.isNil(emailError)}>
          <FormLabel htmlFor='email'>Email</FormLabel>
          <Input
            id='email'
            type='email'
            value={email}
            ref={emailInputRef}
            color={_.isNil(emailError) ? undefined : 'red.500'}
            onFocus={resetErrors}
            onChange={e => setEmail(e.target.value)}
            onKeyPress={async e => {
              if (e.key === 'Enter') {
                submit()
              }
            }}
          />
          <FormErrorMessage>{emailError}</FormErrorMessage>
        </FormControl>
      </VStack>
    )
  }

  const renderCTA = () => {
    return (
      <Box w='full' pt={4}>
        <Button
          variant='solid'
          colorScheme={'teal'}
          onClick={submit}
          w='full'
          size='lg'
        >
          Next
        </Button>
      </Box>
    )
  }

  return (
    <VStack spacing='2' w='full' h='full' overflow={'auto'} pt={12} pb={12}>
      {pageHeader}
      <VStack
        bgColor='white'
        p='10'
        w='full'
        maxW='2xl'
        rounded='lg'
        boxShadow='sm'
      >
        {renderInputs()}
        {renderCTA()}
      </VStack>
    </VStack>
  )
}

export default OnboardingUInfo
