import _ from 'lodash'
import { setDoc, doc, updateDoc } from 'firebase/firestore'
import {
  AuthError,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth'
import * as Sentry from '@sentry/react'
import { NavigateFunction } from 'react-router-dom'
import moment from 'moment'

import { auth, db } from 'controllers/db'
import { CustomerProfileT } from 'types/model'
import store from 'model/store'
import { clear, logout } from 'model/actions'
import {
  clearListenersExceptUser,
  setExistingUser,
  clearListeners
} from 'controllers/listeners'
import config from 'shared/config'

export const dbSignIn = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    const isLocalHost =
      location.hostname === 'localhost' || location.hostname === '127.0.0.1'
    if (!isLocalHost) {
      const heap: any = _.get(window, 'heap')
      if (!_.isNil(heap)) {
        console.log('triggering heap')
        heap.addUserProperties({
          lastSeenAt: moment().format('DD MMM YY')
        })
      }
    }
    return null
  } catch (e) {
    const er = e as AuthError
    console.log('error', er.code, er.message)
    return er.code
  }
}

export const dbSignUp = async (
  name: string,
  email: string,
  password: string,
  role: string
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    )
    const authUser = userCredential.user
    const userId = authUser.uid
    const customerProfile: CustomerProfileT = {
      id: userId,
      name,
      email,
      role
    }
    const ref = doc(db, `customersProfiles/${userId}`)
    await setDoc(ref, customerProfile, { merge: true })
    const isLocalHost =
      location.hostname === 'localhost' || location.hostname === '127.0.0.1'
    if (!isLocalHost) {
      const heap: any = _.get(window, 'heap')
      if (!_.isNil(heap)) {
        console.log('triggering heap')
        const now = moment().format('DD MMM YY')
        heap.addUserProperties({
          name,
          email,
          role,
          createdAt: now,
          lastSeenAt: now
        })
      }
      const subdomain = window.location.host.split('.')[0]
      if (subdomain === 'poppin') {
        const url = `${config.backendUrl}/p/poppin-sign-up`
        const headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
        const responseRaw = await fetch(url, {
          method: 'post',
          headers,
          body: JSON.stringify({
            name,
            emailAddress: email,
            role,
            date: moment().format('DD MMM YY')
          })
        })
        console.log('dbSignUp: responseRaw', responseRaw)
      }
    }
    return null
  } catch (e) {
    console.log('dbSignUp error', e)
    const er = e as AuthError
    console.log('err code:', er.code)
    console.log('err message:', er.message)
    return er.code
  }
}

export const dbSignOut = async (navigate: NavigateFunction) => {
  try {
    if (auth && auth.currentUser) {
      console.log('%cLOGOUT', 'color: orange;')
      clearListeners()
      setExistingUser(null)
      store.dispatch(logout())
      await signOut(auth)
    } else {
      navigate('/signin')
    }
  } catch (e) {
    if (typeof e === 'string') {
      console.log('signOut error:', e.toUpperCase())
    } else if (e instanceof Error) {
      console.log('signOut error:', e.message)
    }
    Sentry.captureException(e)
  }
}

export const dbResetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    return true
  } catch (e) {
    console.warn('password reset email was not sent', e)
    Sentry.captureException(e)
    return false
  }
}

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider()
  console.log('signInWithGoogle location.state:', window.location)
  // console.log('custom paramenters', provider.getCustomParameters())
  try {
    const result = await signInWithPopup(auth, provider)
    console.log('signInWithPopup result', result)
    // This gives you a Google Access Token. You can use it to access the Google API.
    // GoogleAuthProvider.credentialFromResult(result)
    console.log('signInWithGoogle result user', result.user)
    const uid = _.get(result, 'user.uid')
    // const customerProfile: CustomerProfileT = {
    //   id: uid,
    //   name: result.user.displayName || '',
    //   email: result.user.email || '',
    //   avatar: result.user.photoURL || undefined
    // }
    // const ref = doc(db, `customersProfiles/${uid}`)
    // await setDoc(ref, _.omitBy(customerProfile, _.isNil), { merge: true })
    // if (uid) {
    //   logSignInGoogleSuccess(uid)
    // }
    console.log('signInWithPopup uid', uid)
  } catch (error) {
    // logSignInGoogleFailed(_.get(error, 'message', 'unknown'))
    console.error('signInWithGoogle error', error)
  }
}

export const switchAccount = async (
  accId: string,
  navigate?: NavigateFunction
) => {
  console.log('SWITCH ACCOUNT', accId)
  if (_.isNil(accId)) {
    console.warn('cannot switch to account', accId)
    return null
  }
  try {
    const uid = _.get(auth, 'currentUser.uid')
    if (uid) {
      console.log(' ----> history replace ')
      store.dispatch(clear())
      clearListenersExceptUser()
      if (!_.isNil(navigate)) navigate('/', { replace: true })
      await updateDoc(doc(db, 'customers', uid), { currentAccountId: accId })
      // logSwitchAccount(accId)
    } else {
      console.log('switchAccount, no uid')
      Sentry.captureMessage(`${accId} switchAccount, uid is undefined`, 'fatal')
    }
  } catch (e) {
    console.log('cant change account', e)
    Sentry.captureException(e)
  }
}
