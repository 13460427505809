import { auth } from 'controllers/db'

export const getRequestHeaders = async () => {
  const idToken = await auth.currentUser?.getIdToken()
  return {
    Authorization: `Bearer ${idToken}`,
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
}
