import { FC, useEffect, useState, useMemo } from 'react'
import { Heading, Text, VStack, Box, Flex } from '@chakra-ui/react'
import _ from 'lodash'
import { useSelector } from 'model/hooks'

import { TourModelT } from 'types/model'
import { getToursByModel } from 'model/selectors/tours'
import moment from 'moment'
import Tour from 'shared/components/Tour'
import config from 'shared/config'
import { MpSdk } from 'shared/bundle/sdk'

type Props = {
  tourModel: TourModelT
}

const TourDetails: FC<Props> = ({ tourModel }) => {
  const toursByModel = useSelector(getToursByModel)
  const [modelDetails, setModelDetails] =
    useState<MpSdk.Model.ModelDetails | null>(null)

  const tours = useMemo(() => {
    return _.get(toursByModel, tourModel.id)
  }, [toursByModel, tourModel.modelId])

  const isUpstaged = !_.isEmpty(tours)

  useEffect(() => {
    setModelDetails(null)
  }, [tourModel.modelId])

  const onSdkReady = async (sdk: MpSdk) => {
    console.log('onSdkReady', sdk)
    try {
      const details = await sdk.Model.getDetails()
      console.log('model details', details)
      setModelDetails(details)
    } catch (error) {
      console.log('model details error', error)
    }
  }

  const renderMatterportDetails = () => {
    if (_.isNil(modelDetails)) {
      return (
        <Text fontSize='xs' color='gray.400'>
          Fetching data from Matterport...
        </Text>
      )
    } else {
      return (
        <VStack spacing={0} align='flex-start'>
          {_.map(modelDetails, (v, k) => {
            return (
              <Text key={k} fontSize={'sm'} color='gray.600'>
                {_.startCase(k)}: {v}
              </Text>
            )
          })}
        </VStack>
      )
    }
  }

  return (
    <Flex w='full' h='full' overflow='hidden'>
      <VStack w='full' align={'flex-start'} spacing={0} pt={4} overflowY='auto'>
        <Heading size='sm' pb={2}>
          Tour details:
        </Heading>
        <Text fontSize={'sm'} color='gray.600'>
          Address: {_.get(tourModel, 'address.description', '')}
        </Text>
        <Text fontSize={'sm'} color='gray.600'>
          Status:{' '}
          <Text as='span' color={isUpstaged ? 'green.300' : 'red.500'}>
            {isUpstaged ? 'Upstaged' : 'Not Upstaged'}
          </Text>
        </Text>
        <Text fontSize={'sm'} color='gray.600'>
          Designs: {_.size(tours)}
        </Text>
        <Text fontSize={'sm'} color='gray.600'>
          Added: {moment(tourModel.createdAt).format('lll')}
        </Text>
        <Heading size='sm' pb={2} pt={6}>
          Details from Matterport:
        </Heading>
        {renderMatterportDetails()}

        <Box display={'none'}>
          <Tour
            modelId={tourModel.modelId}
            applicationKey={config.applicationKey}
            onSdkReady={onSdkReady}
            autoPlay
            quickStart
            hideDollHouse
            hideGuidedTour
            hideHighlightReels
            hideFloorSwitch
            disableZoom
            ignoreScrollWheel
            disablePanToNewHighligt
            loopGuidedTour={false}
            hideFloorPlan
          />
        </Box>
      </VStack>
    </Flex>
  )
}

export default TourDetails
