import { createReducer } from '@reduxjs/toolkit'
import { receiveUserAccounts, clear, logout } from 'model/actions'
import { AccountProfileT, DictT } from 'types/model'

const initialState = {} as DictT<AccountProfileT>

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveUserAccounts, (state, action) => action.payload)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default reducer
