import { HStack, Divider, Text } from '@chakra-ui/react'

const AuthDivider = () => {
  return (
    <HStack w='full' align='center' pt={3}>
      <Divider color={'gray.100'} h={1} w='full' />
      <Text fontSize='sm' fontWeight={'medium'} color='gray.400'>
        OR
      </Text>
      <Divider color={'gray.100'} h={1} w='full' />
    </HStack>
  )
}

export default AuthDivider
