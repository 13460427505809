import _ from 'lodash'
import { useEffect, FC, useRef } from 'react'

import { MpSdk } from 'shared/bundle/sdk'
import { roomShadowType } from 'shared/components/matterport/MRoomShadow'
// import { DictT } from 'shared/types/model'

type Props = {
  sdk: MpSdk
}

const RoomShadow: FC<Props> = ({ sdk }) => {
  const nodeRef = useRef<MpSdk.Scene.INode | null>(null)

  useEffect(() => {
    console.log('room shadow init')
    const run = async () => {
      if (!_.isNil(nodeRef.current)) {
        const node = nodeRef.current
        node.stop()
      }
      const [sceneObject]: Array<MpSdk.Scene.IObject> =
        await sdk.Scene.createObjects(1)
      const node = sceneObject.addNode('shadow')
      node.addComponent(roomShadowType)
      nodeRef.current = node
      node.start()
    }

    run()

    return () => {
      nodeRef.current?.stop()
    }
  }, [])

  return null
}

export default RoomShadow
