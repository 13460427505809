import {
  doc,
  onSnapshot,
  query,
  collection,
  where,
  updateDoc,
  getDoc
} from 'firebase/firestore'
import { db, dbOmit } from 'controllers/db'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { AccountProfileT, CustomerProfileT } from 'shared/types/model'
import { receiveAccountProfile, receiveProfiles } from 'model/actions'
import { addListener } from 'controllers/listeners'
import store from 'model/store'

export const fetchAccountProfile = async (accountId: string) => {
  console.log('fetchAccountProfile', accountId)
  try {
    const unsubscribe = onSnapshot(
      doc(db, 'accountsProfiles', accountId),
      accountProfilesSN => {
        if (accountProfilesSN.exists()) {
          const accountProfile = accountProfilesSN.data() as AccountProfileT
          store.dispatch(receiveAccountProfile(accountProfile))
        } else {
          store.dispatch(receiveAccountProfile(null))
        }
      },
      err => {
        console.log('fetchAccountProfile error', err)
        Sentry.captureMessage('fetchAccountProfile')
        Sentry.captureException(err)
      }
    )
    addListener('accountProfile', unsubscribe)
  } catch (e) {
    console.log('fetchAccountProfile error', e)
    Sentry.captureException(e)
  }
}

export const fetchTeamProfiles = async (accountId: string, userId: string) => {
  console.log('fetchTeamProfiles', accountId)
  try {
    const q = query(
      collection(db, 'customersProfiles'),
      where('accounts', 'array-contains', accountId)
    )
    const unsubscribe = onSnapshot(
      q,
      profilesSN => {
        const profiles = _.keyBy(
          _.map(profilesSN.docs, doc => doc.data() as CustomerProfileT),
          'id'
        )
        const userProfile = _.get(profiles, userId)
        if (userProfile) {
          Sentry.setUser({
            id: userId,
            email: _.get(userProfile, 'email', ''),
            username: _.get(userProfile, 'name', ''),
            accountId
          })
        }
        store.dispatch(receiveProfiles(profiles))
      },
      err => {
        console.log('fetchTeamProfiles', err)
        Sentry.captureMessage(
          `fetchTeamProfiles, accountId ${accountId}, userId ${userId}`
        )
        Sentry.captureException(err)
      }
    )
    addListener('team', unsubscribe)
  } catch (e) {
    console.log('fetch user error', e)
    Sentry.captureException(e)
  }
}

export const dbUpdateAccountProfile = async (
  accountId: string,
  upd: Partial<AccountProfileT>
) => {
  try {
    const ref = doc(collection(db, 'accountsProfiles'), accountId)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    console.log('dbUpdateAccountProfile error', e)
    Sentry.captureException(e)
  }
}

export const dbUpdateUserProfile = async (
  userId: string,
  upd: Partial<CustomerProfileT>
) => {
  try {
    const ref = doc(collection(db, 'customersProfiles'), userId)
    await updateDoc(ref, upd)
    return true
  } catch (e) {
    console.log('dbUpdateUserProfile error', e)
    Sentry.captureException(e)
    return false
  }
}

export const dbGetUserProfile = async (userId: string) => {
  try {
    const ref = doc(collection(db, 'customersProfiles'), userId)
    const sn = await getDoc(ref)
    return sn.data() as CustomerProfileT | undefined
  } catch (e) {
    console.log('dbGetUserProfile error', e)
    Sentry.captureException(e)
    return undefined
  }
}
