import { FC, useRef, useState, useEffect } from 'react'
import {
  Text,
  Stack,
  HStack,
  Input,
  Avatar,
  FormControl,
  FormLabel,
  VStack,
  Box
} from '@chakra-ui/react'
import SettingsCardTitle from 'components/settings/SettingsCardTitle'
import _ from 'lodash'
import { useSelector } from 'model/hooks'
import { dbUpdateUserProfile } from 'controllers/profiles'
import FilesPickerButton from 'components/FilesPickerButton'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import { resizeFile } from 'utils/imageResizer'
import storage from 'controllers/storage'
import { FileT } from 'shared/types/storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenLine } from '@fortawesome/pro-solid-svg-icons'

const SettingsProfile: FC = () => {
  const nameInputRef = useRef<HTMLInputElement>(null)
  const [name, setName] = useState('')
  const profile = useSelector(getCurrentUserProfile)

  useEffect(() => {
    if (profile) {
      setName(profile.name || '')
    }
  }, [profile])

  const renderName = () => {
    return (
      <FormControl>
        <FormLabel>Your name</FormLabel>
        <Input
          ref={nameInputRef}
          type='text'
          size='sm'
          rounded='md'
          maxW={'96'}
          value={name}
          placeholder='name'
          onChange={e => setName(e.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
              nameInputRef.current?.blur()
            }
          }}
          onBlur={() => profile && dbUpdateUserProfile(profile.id, { name })}
          onSubmit={() => nameInputRef.current?.blur()}
        />
      </FormControl>
    )
  }

  const renderEmail = () => {
    if (profile) {
      return (
        <FormControl>
          <FormLabel>Your email</FormLabel>
          <Input
            type='email'
            size='sm'
            rounded='md'
            maxW={'96'}
            value={profile.email}
            placeholder='email'
            isDisabled
          />
        </FormControl>
      )
    }
  }

  const onLogoUploaded = (files: FileT[]) => {
    if (_.size(files) > 0 && profile) {
      const f = files[0]
      dbUpdateUserProfile(profile.id, { avatar: f.url })
    }
  }

  const renderLogoUpload = () => {
    if (profile) {
      return (
        <VStack align={'flex-start'} minW={'64'} spacing={4} flex={1}>
          <HStack spacing={4}>
            <Text fontWeight={'semibold'} color='gray.800'>
              Your Avatar
            </Text>
          </HStack>

          <FilesPickerButton
            userId={profile ? profile.id : ''}
            storagePath={`/users/${profile.id}/`}
            onComplete={onLogoUploaded}
            fileProcessor={resizeFile}
            options={{
              accept: {
                'image/*': ['.png', '.jpeg', '.jpg', '.heic']
              },
              multiple: false
            }}
            storage={storage}
            generateId={() => 'avatar'}
            buttonTitle='PNG, JPG or HEIC file'
            hideProgress
          >
            <HStack spacing={4} role='group' position={'relative'}>
              <Avatar
                w='16'
                h='16'
                name={profile.name}
                src={profile.avatar}
                _groupHover={{ filter: 'brightness(60%)' }}
              />
              <Text fontWeight={'normal'} color='gray.600' fontSize={'sm'}>
                Upload an image
              </Text>
              <Box
                position='absolute'
                _groupHover={{ display: 'block' }}
                display='none'
                top='50%'
                left='2'
                transform='translate(0%, -50%)'
              >
                <FontAwesomeIcon icon={faPenLine} color='white' />
              </Box>
            </HStack>
          </FilesPickerButton>
        </VStack>
      )
    }
  }

  return (
    <VStack
      w='full'
      px={{ lg: '8', base: '0' }}
      py={{ lg: '4', base: '0' }}
      pb={4}
      overflowY='auto'
      bg={{ lg: 'gray.50', base: 'white' }}
    >
      <HStack
        w='full'
        bg='white'
        boxShadow={{ lg: 'base', base: 'none' }}
        borderRadius='2xl'
        p={{ lg: '8', base: '4' }}
        spacing={{ lg: '16', base: 0 }}
        align='flex-start'
        h='fit-content'
      >
        <SettingsCardTitle title='Profile' description='User settings' />
        <Stack direction='column' spacing='12' flex={2}>
          {renderName()}
          {renderEmail()}
          <Stack
            direction={{ lg: 'row', base: 'column' }}
            spacing={{ base: 8, lg: 0 }}
            overflow='hidden'
          >
            {renderLogoUpload()}
          </Stack>
        </Stack>
      </HStack>
    </VStack>
  )
}

export default SettingsProfile
