import { createReducer } from '@reduxjs/toolkit'
import { SubscriptionT } from 'types/model'
import { receiveSubscription, clear, logout } from 'model/actions'

const initialState = null as SubscriptionT | null

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveSubscription, (state, action) => action.payload)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default reducer
