import { Flex, Image, Text, Button, Tooltip, HStack } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NoResponsesImage from 'shared/assets/no_notification.png'
import { faPlus, faShop } from '@fortawesome/pro-regular-svg-icons'
import { FC } from 'react'

type Props = {
  onCreate: () => void
  onRequest: () => void
}

const TourModelEmptyState: FC<Props> = ({ onCreate, onRequest }) => (
  <Flex
    justifyContent={{ base: 'flex-start', lg: 'center' }}
    alignItems='center'
    w='full'
    h='full'
    direction='column'
    gap='6'
    p={{ base: '16', lg: 0 }}
    position='relative'
  >
    <Image src={NoResponsesImage} alt='No tours' boxSize={44} />
    <Text
      maxWidth='96'
      textAlign='center'
      fontWeight='normal'
      fontSize='md'
      lineHeight='24px'
      color='gray.600'
    >
      No designs are created for the tour yet
    </Text>

    <HStack spacing={4}>
      <Tooltip
        label='Create a new design'
        shouldWrapChildren
        hasArrow
        // closeDelay={1000}
      >
        <Button
          w='56'
          h='12'
          fontWeight='semibold'
          variant='outline'
          color='gray.700'
          fontSize='lg'
          lineHeight='7'
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={onCreate}
        >
          Create design
        </Button>
      </Tooltip>
      <Tooltip
        label='An UpStager designer will create a professional design for you'
        shouldWrapChildren
        hasArrow
        textAlign={'center'}
        // closeDelay={1000}
      >
        <Button
          w='56'
          h='12'
          fontWeight='semibold'
          variant='solid'
          colorScheme='teal'
          fontSize='lg'
          lineHeight='7'
          leftIcon={<FontAwesomeIcon icon={faShop} />}
          onClick={onRequest}
        >
          Request design
        </Button>
      </Tooltip>
    </HStack>
  </Flex>
)

export default TourModelEmptyState
