import { FC, useMemo } from 'react'
import {
  Flex,
  WrapItem,
  Image,
  Text,
  Button,
  HStack,
  // Menu,
  // MenuList,
  // MenuItem,
  // IconButton,
  // MenuButton,
  VStack,
  Badge,
  Spinner
} from '@chakra-ui/react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  // faEdit,
  // faTrashCan,
  // faEllipsisH,
  faFileInvoiceDollar
} from '@fortawesome/pro-regular-svg-icons'

import { RequestDesignT, TourModelT } from 'shared/types/model'
import { noImageUrl } from 'shared/constants/helpers'
import moment from 'moment'
// import { dbRemoveRequestDesign } from 'controllers/requestDesign'
import { useSelector } from 'model/hooks'
// import { useNavigate } from 'react-router'

type Props = {
  tm: TourModelT
  rd: RequestDesignT
}

const RequestDesignCard: FC<Props> = ({ tm, rd }) => {
  // const navigate = useNavigate()
  const user = useSelector(state => state.user)

  // const isPaid = _.get(rd, 'stripeInvoice.paid', false)

  // const onDeleteRDClick = () => {
  //   if (!isPaid) {
  //     dbRemoveRequestDesign(rd)
  //   }
  // }

  const status = useMemo(() => {
    const si = rd.stripeInvoice
    if (si && si.paid) {
      return 'In progress'
    } else if (si && si.id) {
      return 'Ready for payment'
    } else {
      return 'Waiting for invoice'
    }
  }, [rd])

  // const renderMenu = () => {
  //   return (
  //     <Menu>
  //       <MenuButton
  //         as={IconButton}
  //         icon={<FontAwesomeIcon icon={faEllipsisH} />}
  //         variant={'unstyled'}
  //         colorScheme={'blackAlpha'}
  //         size='md'
  //       />
  //       <MenuList zIndex='101'>
  //         <MenuItem
  //           color={'red.500'}
  //           icon={<FontAwesomeIcon icon={faTrashCan} fixedWidth />}
  //           onClick={onDeleteRDClick}
  //           isDisabled={isPaid}
  //         >
  //           <Text color='red.500' fontWeight={'semibold'}>
  //             Cancel request
  //           </Text>
  //         </MenuItem>
  //       </MenuList>
  //     </Menu>
  //   )
  // }

  const renderStatusBadge = () => {
    return (
      <Badge variant='solid' colorScheme='gray' fontSize={'xs'}>
        {status}{' '}
        {status === 'Waiting for invoice' && <Spinner size='xs' speed='1s' />}
      </Badge>
    )
  }

  const onOpenInvoiceClick = () => {
    let url: string | undefined = rd.stripeInvoice?.url
    if (url) {
      const email = _.get(user, 'email')
      if (email) url += `?prefilled_email=${email}`
      window.open(url, '_blank')
    } else {
      console.error('invoice url does not exist')
    }
  }

  const renderActionButton = () => {
    const si = rd.stripeInvoice
    if (si && si.id && !si.paid) {
      return (
        <Button
          variant={'solid'}
          colorScheme='blue'
          leftIcon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
          onClick={onOpenInvoiceClick}
          size='lg'
        >
          View invoice
        </Button>
      )
    }
  }

  return (
    <WrapItem key={rd.id} w='80' h='60' position='relative' rounded={'xl'}>
      <Image
        src={tm.photoUrl || noImageUrl}
        rounded={'xl'}
        w='full'
        h='full'
        objectFit={'cover'}
      />
      <Flex
        w='full'
        h='full'
        position='absolute'
        left={0}
        top={0}
        rounded={'xl'}
        direction='column'
        justify={'space-between'}
      >
        <Flex
          flex={1}
          rounded='xl'
          bgGradient={'linear(to-t, #00000000, #ffffffaa)'}
          mb={8}
          px={2}
          justify='flex-end'
          align='flex-start'
          position={'relative'}
        >
          <HStack justify='space-between' w='full' pl={4} align='flex-start'>
            <VStack align={'flex-start'} pt='4'>
              {renderStatusBadge()}
            </VStack>
            {/* {renderMenu()} */}
          </HStack>
        </Flex>
        <HStack justify={'center'}>{renderActionButton()}</HStack>
        <HStack
          flex={1}
          rounded='xl'
          bgGradient={'linear(to-b, #00000000, #000000aa)'}
          justify='space-between'
          align='flex-end'
          px={4}
          py={6}
        >
          <HStack justify='space-between' align='flex-end' w='full'>
            <VStack spacing={0} align='flex-start'>
              <Text
                fontSize='lg'
                px={2}
                w='100%'
                color='white'
                lineHeight={1.4}
              >
                Professional design
              </Text>
              <Text
                px={2}
                color='white'
                fontWeight={'normal'}
                fontSize='xs'
                textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'
              >
                {moment(rd.createdAt).format('lll')}
              </Text>
            </VStack>
          </HStack>
        </HStack>
      </Flex>
    </WrapItem>
  )
}

export default RequestDesignCard
