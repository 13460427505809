import { FC } from 'react'
import {
  Flex,
  Text,
  VStack,
  Box,
  Image,
  Button,
  HStack,
  IconButton,
  Badge
} from '@chakra-ui/react'
import { ItemT, SupplierT } from 'shared/types/model'
import { getItemPhotoUrl } from 'shared/utils/itemsUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRightArrowLeft,
  faTrashCan
} from '@fortawesome/pro-regular-svg-icons'

type Props = {
  tourSlotId: string
  item: ItemT
  removeItem: () => void
  cancelSelection: () => void
  onReplaceClick: () => void
  catalogVisible: boolean
  openProductCard: () => void
  supplier?: SupplierT
}

const SelectedItemPanel: FC<Props> = ({
  item,
  removeItem,
  cancelSelection,
  onReplaceClick,
  catalogVisible,
  openProductCard,
  supplier
}) => {
  return (
    <Box position='absolute' top={0} left={0} right={catalogVisible ? 380 : 0}>
      <Flex bg='blue.500' boxShadow={'base'} justify='space-between'>
        <HStack px={6} py={4} spacing={4}>
          <Box>
            <Image boxSize={90} alt={item.title} src={getItemPhotoUrl(item)} />
          </Box>
          <VStack align={'flex-start'} spacing={0} w='full' maxW='60%'>
            {supplier && (
              <Box>
                <Badge colorScheme='green'>{supplier.name}</Badge>
              </Box>
            )}
            <HStack spacing={6} pt={2}>
              <Text
                fontSize={'3xl'}
                color='whiteAlpha.900'
                fontWeight={'bold'}
                lineHeight={1}
              >
                {item.title}
              </Text>
              <Text
                as={'i'}
                color='gray.300'
                fontSize={'sm'}
                borderWidth={1}
                borderColor='gray.300'
                rounded={'sm'}
                lineHeight={1.4}
                px='1'
              >
                Editing
              </Text>
            </HStack>
            <Text color='whiteAlpha.800' pt='2' noOfLines={2}>
              {item.desc}
            </Text>
            <Flex justify={'flex-end'} w='full'>
              <Button
                size='sm'
                color='whiteAlpha.900'
                variant={'link'}
                onClick={() => openProductCard()}
                textDecoration='underline'
              >
                Show more
              </Button>
            </Flex>
          </VStack>
        </HStack>
        <HStack spacing={4} pr={6} justify='flex-end'>
          <IconButton
            aria-label='remove'
            size='sm'
            variant={'outline'}
            _hover={{ bg: 'white', svg: { color: 'red.500' } }}
            icon={<FontAwesomeIcon icon={faTrashCan} color='white' />}
            onClick={() => removeItem()}
          />
          <Box>
            <Button
              size='sm'
              variant={'outline'}
              w='full'
              color='whiteAlpha.900'
              _hover={{
                bg: 'white',
                color: 'blue.500',
                svg: { color: 'blue.500' }
              }}
              leftIcon={
                <FontAwesomeIcon icon={faArrowRightArrowLeft} color='white' />
              }
              onClick={() => onReplaceClick()}
            >
              Replace
            </Button>
          </Box>
          <Button
            variant={'primary'}
            size='md'
            color='blue.500'
            bg='whiteAlpha.900'
            _hover={{ bg: 'white', color: 'blue.600' }}
            w='full'
            onClick={() => cancelSelection()}
          >
            Done
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}

export default SelectedItemPanel
