import { Unsubscribe } from 'firebase/auth'
import _ from 'lodash'
import { CustomerT } from 'types/model'

interface Listeners {
  [key: string]: Unsubscribe
}

const listeners: Listeners = {}

export let existingUser: CustomerT | null = null

export const setExistingUser = (user: CustomerT | null) => {
  existingUser = user
}

export function addListener (key: string, l: Unsubscribe) {
  const existingUnsubscribe = _.get(listeners, key)
  console.log(
    'addListener',
    key,
    'existingUnsubscribe exists',
    !_.isNil(existingUnsubscribe)
  )
  if (existingUnsubscribe) existingUnsubscribe()
  listeners[key] = l
}

export function hasListener (key: string) {
  return _.has(listeners, key)
}

export function clearListeners () {
  console.log('clear listeners:')
  setExistingUser(null)
  for (const key in listeners) {
    console.log('clear listener', key)
    const l = _.get(listeners, key)
    console.log('off:', key)
    l && l()
    delete listeners[key]
  }
}

export function clearListenersExceptUser (): void {
  console.log('clear listeners except user:')
  // setExistingUser(undefined)
  for (const key in listeners) {
    // console.log('clear listener', key)
    if (key !== 'user') {
      const l = _.get(listeners, key)
      console.log('off:', key)
      !_.isNil(l) && l()
      delete listeners[key]
    }
  }
}

export function offListener (key: string) {
  const l = _.get(listeners, key)
  if (l) {
    // l.off()
    l()
    delete listeners[key]
  }
}

// let userUnsubscribe

// export function setUserUnsubscribe (unsubscribe) {
//   userUnsubscribe = unsubscribe
// }

// export function clearUserListener () {
//   if (userUnsubscribe) userUnsubscribe()
// }
