import { useEffect, useRef, FC, useState } from 'react'
import _ from 'lodash'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { MpSdk, Scene, Mode } from 'shared/bundle/sdk'
import { DictT } from 'shared/types/model'
import { outlinePassType } from 'shared/components/matterport/MOutlinePass'
import { Vector2, Vector3 } from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'

type OutlinePassProps = {
  sdk: MpSdk
  effectComposer: EffectComposer | null
  excludeItem: string | null
  onHover: (ids: string[]) => void
  onClick: (id: string) => void
  selectedTourSlotId: string | null
  hideEditButton: boolean
  viewMode: Mode.Mode
}

const OutlinePass: FC<OutlinePassProps> = ({
  sdk,
  effectComposer,
  excludeItem,
  onHover,
  onClick,
  selectedTourSlotId,
  hideEditButton,
  viewMode
}) => {
  const nodeRef = useRef<MpSdk.Scene.INode | null>(null)
  const subscriptionsRef = useRef<DictT<MpSdk.ISubscription>>({})
  const compRef = useRef<MpSdk.Scene.IComponent | null>(null)
  const poseRef = useRef<MpSdk.Camera.Pose>()
  const [buttonPos, setButtonPos] = useState<number[] | null>(null)
  const intersectionCacheRef = useRef({
    time: 0,
    position: [0, 0],
    hovered: [],
    selectedTourSlotId: null,
    lastHoveredItemId: null,
    buttonHovered: false
  })

  useEffect(() => {
    intersectionCacheRef.current.selectedTourSlotId = selectedTourSlotId
  }, [selectedTourSlotId])

  useEffect(() => {
    const c = compRef.current
    if (c && c.inputs) {
      c.inputs.excludeItem = excludeItem
    }
  }, [excludeItem])

  useEffect(() => {
    const c = compRef.current
    if (c && c.inputs) {
      c.inputs.mode = viewMode
    }
  }, [viewMode])

  const showButton = () => {
    const intr = intersectionCacheRef.current
    if (intr && !intersectionCacheRef.current.buttonHovered) {
      setButtonPos(intr.position)
    } else {
      console.warn('cannot show button')
    }
  }

  const hideButton = () => {
    setButtonPos(null)
  }

  useEffect(() => {
    const run = async () => {
      console.log('START OUTLINE PASS')
      const sceneObjs: Array<MpSdk.Scene.IObject> =
        await sdk.Scene.createObjects(1)
      const sceneObj = sceneObjs[0]
      const node = sceneObj.addNode()
      const outlineComp = node.addComponent(outlinePassType, {
        effectComposer,
        excludeItem
      })

      compRef.current = outlineComp

      subscriptionsRef.current.pose = sdk.Camera.pose.subscribe(pose => {
        poseRef.current = pose
      })

      subscriptionsRef.current.pointer = sdk.Pointer.intersection.subscribe(
        function (intersectionData) {
          if (selectedTourSlotId) return
          if (compRef.current && compRef.current.inputs) {
            const showcase = document.getElementById('showcase')
            const showcaseSize = {
              w: showcase.clientWidth,
              h: showcase.clientHeight
            }
            const screenPos = sdk.Conversion.worldToScreen(
              intersectionData.position,
              poseRef.current,
              showcaseSize
            )
            const p = intersectionData.position
            compRef.current.inputs.cameraPosition = new Vector3(p.x, 30, p.z)
            const mouse = new Vector2()
            mouse.x = (Math.abs(screenPos.x) / showcaseSize.w) * 2 - 1
            mouse.y = -(Math.abs(screenPos.y) / showcaseSize.h) * 2 + 1
            outlineComp.inputs.position = mouse
            intersectionCacheRef.current.time = _.now()
            intersectionCacheRef.current.position = [
              Math.abs(screenPos.x),
              Math.abs(screenPos.y)
            ]
          }
        }
      )

      const evPath = sceneObj.addPath({
        id: 'outline_hover',
        type: 'output' as Scene.PathType.OUTPUT,
        node,
        component: outlineComp,
        property: 'selectedObjects'
      })
      const spy = {
        path: evPath,
        onEvent: (ev: any) => {
          if (selectedTourSlotId) return
          intersectionCacheRef.current.hovered = ev
          onHover(ev)
          if (!_.isEmpty(ev)) {
            showButton()
            intersectionCacheRef.current.lastHoveredItemId = ev[0]
          } else {
            hideButton()
          }
        }
      }
      subscriptionsRef.current.spy = sceneObj.spyOnEvent(spy)

      node.start()
    }
    run()
    return () => {
      console.log('STOP OUTLINE PASS')
      const node = nodeRef.current
      if (node) {
        node.stop()
        nodeRef.current = null
      }
      _.forEach(subscriptionsRef.current, s => s.cancel())
      subscriptionsRef.current = {}
    }
  }, [sdk.Scene, selectedTourSlotId])

  const onButtonClick = () => {
    hideButton()
    onClick(intersectionCacheRef.current.lastHoveredItemId)
  }

  const isVisible =
    intersectionCacheRef.current.lastHoveredItemId !==
      intersectionCacheRef.current.selectedTourSlotId && !hideEditButton

  return (
    <ButtonGroup
      position={'absolute'}
      left={buttonPos && buttonPos[0] - 20}
      top={buttonPos && buttonPos[1] - 20}
      isAttached
      variant='solid'
      size='sm'
      colorScheme={'blue'}
    >
      <Button
        pointerEvents={'all'}
        onClick={onButtonClick}
        display={buttonPos && isVisible ? 'block' : 'none'}
      >
        Edit
      </Button>
    </ButtonGroup>
  )
}

export default OutlinePass
