import { FC, useRef } from 'react'
import { VStack, Flex, Link } from '@chakra-ui/react'

import ToursContent from 'pages/tours/ToursContent'
import ToursNavBar from 'pages/tours/ToursNavBar'
import AddTourModal, { IAddTourModal } from 'modals/AddTourModal'

const Tours: FC = () => {
  const addTourModalRef = useRef<IAddTourModal>(null)

  const onAddTourClick = () => {
    addTourModalRef.current?.open()
  }

  return (
    <VStack h='full' w='full' bg='gray.50' spacing={0} position='relative'>
      <ToursNavBar onAddTourClick={onAddTourClick} />
      {/* <NavBar title='Tours' /> */}
      <Flex
        direction={'column'}
        w='full'
        justify={'flex-start'}
        overflow={'hidden'}
        flex={1}
      >
        <ToursContent />
      </Flex>
      <AddTourModal ref={addTourModalRef} />
      <Flex align='center' justify='center' position='absolute' bottom='5' right='10'>
        <Link
          href='https://www.youtube.com/playlist?list=PL_GNx7PMNVeh_dBVU5rR8_w8qh8iEruAW'
          isExternal
          color='gray.900'
          textDecoration='underline'
          fontSize='xs'
        >
          How-To Videos
        </Link>
      </Flex>
    </VStack>
  )
}

export default Tours
