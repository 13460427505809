import { FC, useState, useMemo } from 'react'
import {
  Box,
  Button,
  Stack,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  Heading,
  useToast,
  VStack,
  HStack,
  Tag,
  Tooltip,
  useClipboard
} from '@chakra-ui/react'
import _ from 'lodash'

import { openPaymentPage } from 'controllers/stripe'
import { useSelector } from 'model/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DateTypeSelect from 'components/stripe/DateTypeSelect'
import PlanFeatures from 'components/stripe/planSelect/PlanFeatures'
import {
  getPricesByInterval,
  getPublicProducts
} from 'model/selectors/paymentPlans'
import numeral from 'numeral'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

const PlanSelect: FC = () => {
  const [isYearType, setIsYearType] = useState<boolean>(false)
  const { onCopy, hasCopied } = useClipboard('+1 (786) 390-5980')
  const [loading, setLoading] = useState<boolean>(false)
  const [index, setIndex] = useState<number>(0)
  const products = useSelector(getPublicProducts)
  const pricesByInerval = useSelector(getPricesByInterval)
  const toast = useToast()

  console.log('pricesByInerval', pricesByInerval)

  const pricesList = useMemo(
    () => (isYearType ? pricesByInerval.year : pricesByInerval.month),
    [isYearType, pricesByInerval]
  )

  const customPrice = useMemo(() => {
    return _.last(pricesList)
  }, [pricesList])

  console.log('customPrice', customPrice)

  const customProduct = useMemo(() => {
    if (customPrice && products) {
      return products[customPrice.product]
    }
  }, [customPrice, products])

  const currentPrice = useMemo(() => {
    return _.has(pricesList, index) ? pricesList[index] : customPrice
  }, [index, pricesList, customPrice])

  const currentProduct = useMemo(() => {
    return products && currentPrice ? products[currentPrice.product] : null
  }, [currentPrice, products])

  const isCustomPlan = useMemo(() => {
    return !_.has(pricesList, index)
  }, [pricesList, index])

  const monthlyPrice = useMemo(() => {
    if (currentPrice) {
      if (isYearType) {
        return currentPrice.unitAmount / 12
      } else {
        return currentPrice.unitAmount
      }
    } else {
      return 0
    }
  }, [isYearType, currentPrice])

  const onSubmit = async () => {
    if (currentPrice && currentProduct) {
      if (isCustomPlan) {
        window.open('mailto:sales@upstager.co?subject=Subscription', '_blank')
      } else {
        setLoading(true)
        const res = await openPaymentPage(currentPrice.id)
        if (!res) {
          toast({
            title: 'Upgrade plan',
            description: 'Error occured',
            status: 'error',
            duration: 9000,
            isClosable: true
          })
        }
        setLoading(false)
      }
    }
  }

  console.log(
    'pricesList',
    pricesList,
    'currentProduct',
    currentProduct,
    'currentPrice',
    currentPrice
  )

  if (_.isEmpty(pricesList) || !currentProduct || !currentPrice) {
    return null
  }

  const renderSlider = () => {
    return (
      <Box w='full' flexShrink={0} h={'14'} pt={2} px={3}>
        <Slider
          value={index}
          onChange={(val: number) => setIndex(val)}
          min={0}
          max={_.size(pricesList)}
          colorScheme='teal'
        >
          <SliderTrack h={1.5} bg={'gray.200'}>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb w={5} h={5} borderWidth={2} borderColor={'teal.400'} />
          {_.map(pricesList, (price, i: number) => {
            const p = _.get(products, price.product)
            return (
              <SliderMark
                value={i}
                fontSize='xs'
                key={i}
                pt={6}
                position='relative'
              >
                <Text
                  fontWeight={'semibold'}
                  color={index >= i ? 'gray.800' : 'gray.500'}
                  textAlign='center'
                  pointerEvents={'all'}
                  transform={'translate(-50%, 0%)'}
                  onClick={() => setIndex(i)}
                >
                  {p?.metadata.amount}
                </Text>
              </SliderMark>
            )
          })}
          <SliderMark
            value={_.size(pricesList)}
            fontSize='xs'
            pt={6}
            position='relative'
          >
            <Text
              fontWeight={'semibold'}
              color={index >= _.size(pricesList) ? 'gray.800' : 'gray.500'}
              textAlign='center'
              pointerEvents={'all'}
              transform={'translate(-50%, 0%)'}
              onClick={() => setIndex(_.size(pricesList))}
            >
              {customProduct?.metadata.amount}+
            </Text>
          </SliderMark>
        </Slider>
      </Box>
    )
  }

  const renderCurrentPlanPrice = () => {
    if (currentPrice && currentProduct && isCustomPlan) {
      return (
        <Heading size='xl' color='teal.500'>
          Custom pricing
        </Heading>
      )
    } else if (currentPrice && currentProduct) {
      const strPrice = numeral(monthlyPrice / 100).format('$0.00')
      const strTourPrice = numeral(
        _.round(monthlyPrice / 100 / currentProduct.metadata.amount, 2)
      ).format('$0.00')
      return (
        <VStack align='flex-start' spacing={0}>
          <Heading size='2xl' color='teal.500'>
            {strPrice}/mo
          </Heading>
          <Text fontSize={'sm'} color='gray.400'>
            {strTourPrice} per space
          </Text>
        </VStack>
      )
    }
  }

  const renderSelectedPlanInfo = () => {
    const mostPopular = _.get(currentPrice, 'metadata.mostPopular', false)
    return (
      <VStack align='flex-start' w='full' spacing={6}>
        <HStack w='full' justify={'space-between'}>
          <Text color='gray.800' fontSize='3xl' fontWeight={'bold'}>
            {isCustomPlan ? 'Enterprise' : currentProduct.name}
          </Text>
          {mostPopular && (
            <Tag
              colorScheme='blue'
              variant='solid'
              rounded={'base'}
              px={4}
              fontWeight='bold'
            >
              Most Popular
            </Tag>
          )}
        </HStack>
        <Box h={12} flexShrink={0}>
          {renderCurrentPlanPrice()}
        </Box>
      </VStack>
    )
  }

  const renderActionButton = () => {
    if (isCustomPlan) {
      return (
        <VStack spacing={0} align='center' w='full'>
          <Heading size='sm'>Custom quote - Please contact us</Heading>
          <HStack w='full' justify='center'>
            <Text>Email:</Text>
            {/* Email: team@upstager.co Phone: +1 (786) 390-5980 */}
            <Button
              colorScheme={'teal'}
              variant='link'
              onClick={() =>
                window.open(
                  'mailto:sales@upstager.co?subject=Subscription',
                  '_blank'
                )
              }
            >
              team@upstager.co
            </Button>
          </HStack>
          <HStack w='full' justify='center'>
            <Text>Phone:</Text>
            <Tooltip
              label={hasCopied ? 'Copied' : 'Click to copy to clipboard'}
              closeDelay={1000}
            >
              <Button colorScheme={'teal'} variant='link' onClick={onCopy}>
                +1 (786) 390-5980
              </Button>
            </Tooltip>
          </HStack>
        </VStack>
      )
    } else {
      return (
        <Button
          onClick={onSubmit}
          w='full'
          rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
          colorScheme='teal'
          isLoading={loading}
        >
          Upgrade Now
        </Button>
      )
    }
  }

  return (
    <VStack spacing={10} w='full'>
      <DateTypeSelect
        isYearType={isYearType}
        onChange={(v: boolean) => setIsYearType(v)}
      />
      <Stack
        w='full'
        justify='space-between'
        align='flex-start'
        spacing='30'
        direction={{ base: 'column', lg: 'row' }}
        py={6}
      >
        <VStack
          w='full'
          // p={{ base: '0', lg: '10' }}
          align='flex-start'
          spacing={6}
        >
          {renderSelectedPlanInfo()}
          {renderSlider()}
          <HStack w='full' justify={'center'} pb={2}>
            <Text textAlign='center' fontWeight={'semibold'} color='teal.400'>
              {`${currentProduct.metadata.amount}${
                isCustomPlan ? '+' : ''
              } tours & ${
                isCustomPlan ? 'unlimited' : currentProduct.metadata.users
              } users`}
            </Text>
          </HStack>
          {renderActionButton()}
        </VStack>
        <PlanFeatures
          price={numeral(monthlyPrice / 100).format('$0,0.00')}
          isCustom={isCustomPlan}
          isMonth
          isBasic={currentProduct.name === 'Starter'}
        />
      </Stack>
    </VStack>
  )
}

export default PlanSelect
