import { FC, useState } from 'react'
import Catalog from 'shared/components/catalogPanel/Catalog'
import CategoryItems from 'shared/components/catalogPanel/CategoryItems'
// import _ from 'lodash'
import { DictT, ItemT, SlotT, SupplierT } from 'shared/types/model'

type Props = {
  closeCatalogClick: () => void
  addToRoom: (itemId: string) => void
  visible: boolean
  selectedItemId: string | null
  openProductCard: (item: ItemT) => void
  // itemsBySlot: DictT<DictT<ItemT>>
  slots: DictT<SlotT>
  suppliers?: DictT<SupplierT>
  // isEditor?: boolean
  getItems?: (
    slotId: string,
    amount: number,
    offset: number
  ) => Promise<ItemT[]>
  pricesVisible: boolean
  // toSuppliers?: () => void
}

const CatalogPanel: FC<Props> = ({
  closeCatalogClick,
  addToRoom,
  visible,
  selectedItemId,
  openProductCard,
  // itemsBySlot,
  slots,
  suppliers,
  // isEditor,
  getItems,
  pricesVisible
  // toSuppliers
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

  const onCloseCatalogClick = () => {
    setSelectedCategory(null)
    closeCatalogClick()
  }

  if (!visible) {
    return null
  } else if (selectedCategory) {
    console.log('Category Items')
    return (
      <CategoryItems
        toCatalog={() => setSelectedCategory(null)}
        closeCatalog={onCloseCatalogClick}
        addToRoom={addToRoom}
        selectedItemId={selectedItemId}
        openProductCard={openProductCard}
        slot={slots[selectedCategory]}
        // items={items}
        getItems={getItems}
        suppliers={suppliers}
        pricesVisible={pricesVisible}
      />
    )
  } else {
    console.log('return Catalog')
    return (
      <Catalog
        goBack={closeCatalogClick}
        selectCategory={(slotId: string) => setSelectedCategory(slotId)}
        selectedItemId={selectedItemId}
        slots={slots}
      />
    )
  }
}

export default CatalogPanel
