import { FC } from 'react'
import {
  Flex,
  ButtonGroup,
  Button
} from '@chakra-ui/react'

const ACTIVE_FILTER = 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))'

const SelectButton: FC<{
  active: boolean,
  label: string,
  onChange: (v: boolean) => void,
  v: boolean
}> = ({
  active,
  label,
  onChange,
  v
}) => (
  <Button
    variant='unstyled'
    p='6'
    display='inline-flex'
    onClick={() => onChange(v)}
    borderRadius='full'
    filter={active ? ACTIVE_FILTER : 'none'}
    backgroundColor={active ? 'white' : 'transparent'}
    color={active ? 'blue.500' : 'gray.800'}
  >
    {label}
  </Button>
)

const DateTypeSelect: FC<{
  isYearType: boolean,
  onChange: (v: boolean) => void
}> = ({
  isYearType,
  onChange
}) => {
  return (
    <Flex justify='center'>
      <ButtonGroup spacing='2' borderRadius='full' backgroundColor='gray.100'>
        <SelectButton label='Monthly' onChange={onChange} active={!isYearType} v={false} />
        <SelectButton label='Yearly' onChange={onChange} active={isYearType} v />
      </ButtonGroup>
    </Flex>
  )
}

export default DateTypeSelect
