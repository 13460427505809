import { FC, useMemo } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  Button,
  VStack,
  HStack,
  WrapItem,
  Image,
  Wrap,
  Text,
  useToast,
  Checkbox
} from '@chakra-ui/react'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { DictT, RoomTypeT, ItemsTemplateT } from 'shared/types/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { noImageUrl } from 'shared/constants/helpers'

type Props = {
  goBack: () => void
  closePanel: () => void
  addToRoom: (t: ItemsTemplateT) => void
  roomType: RoomTypeT
  templates: DictT<ItemsTemplateT>
}

const TemplatesList: FC<Props> = ({
  goBack,
  closePanel,
  addToRoom,
  roomType,
  templates
}) => {
  const toast = useToast()

  const sortedTemplates = useMemo(
    () => _.sortBy(templates, 'displayOrder'),
    [templates]
  )

  const tipName = 'tip_how_to_add_set'

  const showToast = () => {
    const hideTip = localStorage.getItem(tipName)
    if (_.isNil(hideTip)) {
      toast({
        title: 'Tip',
        variant: 'left-accent',
        isClosable: true,
        status: 'info',
        position: 'top',
        description: (
          <VStack w='full' align='flex-start'>
            <Text>
              When an item is added to room it will “stick” to your cursor. Move
              the cursor around the space until it appears in the right spot and
              then click to place it.
            </Text>
            <Checkbox
              colorScheme={'blackAlpha'}
              size='sm'
              borderColor='black'
              onChange={e =>
                localStorage.setItem(tipName, _.toString(e.target.checked))
              }
            >
              Do not show this again
            </Checkbox>
          </VStack>
        ),
        duration: 10000
      })
    }
  }

  const onAddToRoomClick = (t: ItemsTemplateT) => {
    showToast()
    addToRoom(t)
  }

  const renderItems = () => {
    return (
      <Wrap
        spacing={4}
        h='full'
        overflowY='auto'
        // scrollSnapType={'y proximity'}
        sx={{
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {_.map(sortedTemplates, (t: ItemsTemplateT) => {
          return (
            <WrapItem key={t.id} scrollSnapAlign='start'>
              <VStack align={'flex-start'} pb={4} w={'164px'} spacing={1}>
                <Box
                  as={'button'}
                  // _hover={{ boxShadow: 'md' }}
                  onClick={() => onAddToRoomClick(t)}
                >
                  <Image
                    boxSize={164}
                    alt={t.name}
                    src={t.photo || noImageUrl}
                  />
                </Box>
                <Text
                  fontSize={'xs'}
                  color='gray.600'
                  noOfLines={2}
                  h='8'
                  lineHeight={1.2}
                >
                  {t.name}
                </Text>
                <Button
                  size='sm'
                  colorScheme='blue'
                  w='full'
                  onClick={() => onAddToRoomClick(t)}
                >
                  Add to tour
                </Button>
              </VStack>
            </WrapItem>
          )
        })}
      </Wrap>
    )
  }

  return (
    <RightPanelWrapper show width={380}>
      <Flex flex={1} maxH='full' direction='column'>
        <Box pb={8} bg='white' p={4}>
          <Flex align='flex-start' justify={'space-between'}>
            <HStack align='flex-start' spacing={4}>
              <Box as='button' onClick={() => goBack()}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Box>
              <VStack align={'flex-start'}>
                <Heading size='md' color='black'>
                  {roomType.name}
                </Heading>
              </VStack>
            </HStack>
            <Button
              size='xs'
              variant={'ghost'}
              color='blue.500'
              onClick={() => closePanel()}
            >
              Done
            </Button>
          </Flex>
        </Box>
        <Flex
          direction='column'
          align={'flex-start'}
          justify='flex-start'
          flex={1}
          // h='full'
          overflow='hidden'
          px={4}
        >
          {renderItems()}
        </Flex>
      </Flex>
    </RightPanelWrapper>
  )
}

export default TemplatesList
