import {
  ForwardRefRenderFunction,
  Ref,
  useImperativeHandle,
  useState,
  forwardRef
} from 'react'
import {
  Button,
  useDisclosure,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  useClipboard,
  Tooltip
} from '@chakra-ui/react'

import config from 'shared/config'
import { useSelector } from 'model/hooks'
import { handleShareTour } from 'controllers/tours'
import { dbUpdateUser } from 'controllers/user'

export type IShareDesignModal = {
  open: (tourModelId: string, tourId: string) => void
}

type Props = {}

const ShareDesignModal: ForwardRefRenderFunction<IShareDesignModal, Props> = (
  _props: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tourModelId, setTourModelId] = useState('')
  const [tourId, setTourId] = useState('')
  const { onCopy, setValue, hasCopied } = useClipboard('')
  const user = useSelector(state => state.user)

  useImperativeHandle(ref, () => ({
    open: (tourModelId: string, tourId: string) => {
      setTourModelId(tourModelId)
      setTourId(tourId)
      setValue(`${config.userAppUrl}/${tourModelId}?d=${tourId}`)
      onOpen()
    }
  }))

  const handleCopy = () => {
    handleShareTour()
    onCopy()
    if (user?.id) {
      const heapCounters = { ...user?.heapCounters, linkCopied: (user?.heapCounters?.linkCopied || 0) + 1 }
      dbUpdateUser(user.id, { heapCounters })
    }
  }

  const renderShareContent = () => {
    return (
      <>
        <ModalBody h='full' display='flex' flexDirection='column'>
          <VStack align='flex-start' fontSize='xl' py={6} spacing={0} gap='8'>
            <Text>
              Copy the link to share the tour with a single design
            </Text>
            <Text
              listStyleType='circle'
              fontSize='md'
              color='gray.600'
            >
              To access the tour with interactive designs and DIY features use the &apos;share&apos; button located at the top right of the page
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              flexShrink={0}
              variant='outline'
              onClick={() =>
                window.open(`${config.userAppUrl}/${tourModelId}?d=${tourId}`, '_blank')
              }
            >
              Preview tour
            </Button>
            <Tooltip
              label={hasCopied ? 'Link copied' : 'Copy link'}
              closeDelay={1000}
              hasArrow
            >
              <Button colorScheme='blue' onClick={handleCopy} flexShrink={0}>
                Copy link
              </Button>
            </Tooltip>
          </HStack>
        </ModalFooter>
      </>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent w='xl'>
        <ModalCloseButton />
        {renderShareContent()}
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(ShareDesignModal)
