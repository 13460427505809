import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState,
  useRef
} from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel,
  VStack,
  FormHelperText,
  FormErrorMessage,
  Box,
  InputRightElement,
  InputGroup
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'
import { v4 } from 'uuid'

import AddressAutocomplete from 'shared/components/AddressAutocomplete'
import { AddressT, TourModelT } from 'shared/types/model'
import { getAddress } from 'shared/utils/addressUtils'
import { generateId } from 'controllers/db'
import { useSelector } from 'model/hooks'
import { dbCreateTourModel } from 'controllers/tourModels'
// import { dbCreateTour } from 'controllers/tours'
import Tour from 'shared/components/Tour'
import config from 'shared/config'
import { MpSdk } from 'shared/bundle/sdk'
import { addressByString } from 'shared/utils/googleApis'
import { saveFile } from 'controllers/storage'
import UpgradeModal, { IUpgradeModal } from 'modals/UpgradeModal'
import { getCurrentPlanUsage } from 'model/selectors/paymentPlans'
import { dbUpdateUser } from 'controllers/user'

export interface IAddTourModal {
  open: () => void
}

type ErrorsT = {
  model?: string
  address?: string
}

type Props = {}

const AddTourModal: ForwardRefRenderFunction<IAddTourModal, Props> = (
  _props: Props,
  ref: Ref<unknown>
) => {
  const navigate = useNavigate()
  const upgradeModalRef = useRef<IUpgradeModal>(null)
  const user = useSelector(state => state.user)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tourModelId, setTourModelId] = useState<string | null>(null)
  const [modelDetails, setModelDetails] = useState<
    MpSdk.Model.ModelDetails | undefined
  >()
  const [modelId, setModelId] = useState<string | null>(null)
  const [address, setAddress] = useState<AddressT | undefined>()
  const [errors, setErrors] = useState<ErrorsT>({})
  const modelIdInputRef = useRef<HTMLInputElement>(null)
  const [name, setName] = useState('')
  const [sessionToken] = useState(v4())
  const [sdk, setSdk] = useState<MpSdk>()
  const [loading, setLoading] = useState(false)
  const planUsage = useSelector(getCurrentPlanUsage)
  const partner = useSelector(state => state.partner)

  useImperativeHandle(ref, () => ({
    open: () => {
      setTourModelId(null)
      setAddress(undefined)
      setName('')
      setModelId(null)
      setErrors({})
      setModelDetails(undefined)
      if (planUsage.limitReached) {
        upgradeModalRef.current?.open()
      } else {
        onOpen()
      }
      if (user?.id) {
        const heapCounters = { ...user?.heapCounters, addTourModalOpened: (user?.heapCounters?.addTourModalOpened || 0) + 1 }
        dbUpdateUser(user.id, { heapCounters })
      }
    }
  }))

  const resetErrors = () => setErrors({})

  const processModelId = () => {
    let mId = tourModelId
    if (tourModelId && validator.isURL(tourModelId)) {
      const u = new URL(tourModelId)
      const sp = u.searchParams
      mId = sp.get('m')
    }
    setModelDetails(undefined)
    setAddress(undefined)
    setName('')
    setModelId(_.isEmpty(mId) ? null : mId)
  }

  const onCreateClick = async () => {
    console.log('tourModelId', tourModelId)
    const newErrors: ErrorsT = {}
    if (_.isEmpty(address)) {
      newErrors.address = 'Enter the property address please'
    }
    if (_.isEmpty(modelId)) {
      newErrors.model = 'Enter matterport tour model ID please'
    }
    console.log('modelId', modelId)
    setErrors(newErrors)
    if (
      _.isEmpty(newErrors) &&
      !_.isNil(modelId) &&
      !_.isNil(address) &&
      !_.isNil(sdk) &&
      user
    ) {
      setLoading(true)
      try {
        const id = generateId()
        const resolution = {
          width: 640,
          height: 480
        }
        const visibility = {
          mattertags: false,
          sweeps: true
        }
        const screenshotUri = await sdk.Renderer.takeScreenShot(
          resolution,
          visibility
        )
        const storagePath = `/tourModels/${id}`
        const file = await saveFile(storagePath, screenshotUri)
        const tm: TourModelT = {
          id,
          createdAt: _.now(),
          modelId,
          accountId: user.currentAccountId,
          address,
          enabled: true,
          photoUrl: file?.url,
          name,
          deleted: 0,
          partnerId: _.get(partner, 'id', null)
        }
        await dbCreateTourModel(tm)
        // const tourId = generateId()
        // const tour: TourT = {
        //   id: tourId,
        //   createdAt: _.now(),
        //   name: '',
        //   tourModelId: id,
        //   slots: {},
        //   userId: user.id
        // }
        // console.log('tm', tm, 'tour', tour)
        // await dbCreateTour(tour)
        setLoading(false)
        onClose()
        navigate(`/tour/${tm.id}`)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }
  }

  const onSdkReady = async (sdk: MpSdk) => {
    console.log('onSdkReady', sdk)
    try {
      const details = await sdk.Model.getDetails()
      console.log('model details', details)
      setModelDetails(details)

      setSdk(sdk)
      setName(details.name || '')
      try {
        const newAddress = await addressByString(
          details.formattedAddress,
          sessionToken
        )
        setAddress(newAddress)
      } catch (e) {
        console.warn('could not get the tour address details')
      }
    } catch (error) {
      console.log('model details error', error)
    }
  }

  const renderTour = () => {
    if (modelId) {
      return (
        <Box w='full' h='72' pt={4}>
          <Tour
            modelId={modelId}
            applicationKey={config.applicationKey}
            onSdkReady={onSdkReady}
            autoPlay
            quickStart
            hideDollHouse
            hideGuidedTour
            hideHighlightReels
            hideFloorSwitch
            disableZoom
            ignoreScrollWheel
            disablePanToNewHighligt
            loopGuidedTour={false}
            hideFloorPlan
          />
        </Box>
      )
    }
  }

  const renderAddressInput = () => {
    if (modelDetails) {
      return (
        <FormControl isRequired isInvalid={!_.isNil(errors.address)}>
          <FormLabel htmlFor='tourAddress'>Property address</FormLabel>
          <AddressAutocomplete
            value={_.isNil(address) ? '' : getAddress(address)}
            onSelect={setAddress}
            inputProps={{ onFocus: resetErrors }}
          />
          {!_.isNil(errors.address) && (
            <FormErrorMessage>{errors.address}</FormErrorMessage>
          )}
        </FormControl>
      )
    }
  }

  const renderNameInput = () => {
    if (modelDetails) {
      return (
        <FormControl isRequired isInvalid={!_.isNil(errors.model)}>
          <FormLabel htmlFor='tour name'>Tour Name</FormLabel>
          <Input
            value={name}
            placeholder='tour name'
            onChange={e => setName(e.target.value)}
          />
        </FormControl>
      )
    }
  }

  const renderModelInput = () => {
    return (
      <FormControl isRequired isInvalid={!_.isNil(errors.model)}>
        <FormLabel htmlFor='tour model'>Tour Model</FormLabel>
        <InputGroup>
          <Input
            ref={modelIdInputRef}
            value={tourModelId || ''}
            placeholder='https://my.matterport.com/show/?m=b4znJwcqE2p'
            onChange={e => setTourModelId(e.target.value)}
            onFocus={resetErrors}
            onBlur={processModelId}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                modelIdInputRef.current?.blur()
              }
            }}
          />
          <InputRightElement w='14' pr={1}>
            <Button size='sm'>Add</Button>
          </InputRightElement>
        </InputGroup>
        {!_.isNil(errors.model) ? (
          <FormErrorMessage>{errors.model}</FormErrorMessage>
        ) : (
          <FormHelperText>
            Enter the copied Matterport link or model ID of an unfurnished
            property
          </FormHelperText>
        )}
      </FormControl>
    )
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size='xl'
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottomWidth={1}>Add Tour</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} pt={8}>
              {renderModelInput()}
              {renderTour()}
              {renderAddressInput()}
              {renderNameInput()}
            </VStack>

            <Flex mb={4} pt={12} justify='flex-end'>
              {modelDetails && (
                <Button
                  colorScheme={'teal'}
                  ml={2}
                  onClick={onCreateClick}
                  isLoading={loading}
                >
                  Save
                </Button>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <UpgradeModal ref={upgradeModalRef} />
    </>
  )
}

export default forwardRef(AddTourModal)
