import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { VStack, Flex } from '@chakra-ui/react'
import { useMedia } from 'react-use'

import { appInitialized, fetchPartner } from 'controllers/init'
import { useNavigate, useSearchParams } from 'react-router-dom'
import MobilePlaceholder from 'shared/components/MobilePlaceholder'
import { fetchInvitation } from 'controllers/invitations'
import Loading from 'pages/Loading'
// import EmailConfirmationAlert from 'components/EmailConfirmationAlert'

const NavWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const appInitializedRef = useRef<boolean>(false)
  const isDesktop = useMedia('(min-width: 1280px)')
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const run = async () => {
      console.log('call appInitialized, isDesktop', isDesktop)
      if (isDesktop && !appInitializedRef.current) {
        let inv
        const invId = searchParams.get('i')
        const priceId = searchParams.get('p')
        if (invId) {
          inv = await fetchInvitation(invId)
        }
        await fetchPartner()
        appInitialized(navigate, inv, priceId)
        appInitializedRef.current = true
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
    run()
  }, [isDesktop])

  if (loading) {
    return <Loading />
  } else if (isDesktop) {
    return (
      <VStack h='full' w='full' overflow='hidden' spacing={0}>
        {/* <EmailConfirmationAlert /> */}
        <Flex h='full' w='full' overflow='hidden'>
          {children}
        </Flex>
      </VStack>
    )
  } else {
    return <MobilePlaceholder />
  }
}

export default NavWrapper
