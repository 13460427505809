import { useState, useRef, useEffect } from 'react'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
// import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import _ from 'lodash'
import { Box, Spinner, Text } from '@chakra-ui/react'

import { ModelT } from 'shared/types/model'

// const textureUrl = 'https://firebasestorage.googleapis.com/v0/b/upstager-dev.appspot.com/o/tours%2F2fXEE90NAKGuLxtTKpGK%2FCfocvqECVW1kOXqt7r72%2Flarge.png?alt=media&token=94644dc9-b90d-440a-ac16-12935c99966c'
const textureUrl =
  'https://firebasestorage.googleapis.com/v0/b/upstager-dev.appspot.com/o/properties%2F2HVP384R2bJoNRitufsP%2FAg12ejXWWm0ijyXRPpdy%2F4edn8RLWuKrZc0gGZc1V_medium?alt=media&token=23d3a24b-5331-4097-aaf1-206856a3f8ec'

function loadGLTFModel (
  scene: THREE.Scene,
  glbPath: string
): Promise<THREE.Group> {
  return new Promise((resolve, reject) => {
    const loader = new GLTFLoader()
    loader.load(
      glbPath,
      (gltf: GLTF) => {
        const obj = gltf.scene
        obj.name = 'furniture'
        obj.position.y = 0
        obj.position.x = 0
        obj.receiveShadow = true
        obj.castShadow = true
        scene.add(obj)

        obj.traverse(function (child) {
          const isMesh = _.get(child, 'isMesh')
          if (isMesh) {
            child.castShadow = true
            child.receiveShadow = true
          }
        })

        resolve(obj)
      },
      undefined,
      (error: ErrorEvent) => {
        console.log(error)
        reject(error)
      }
    )
  })
}

const ModelViewer = ({ model }: { model?: ModelT }) => {
  const refContainer = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [renderer, setRenderer] = useState<THREE.WebGLRenderer | null>(null)
  // const [lights, setLights] = useState<
  //   Array<THREE.AmbientLight | THREE.DirectionalLight>
  // >([])

  useEffect(() => {
    const { current: container } = refContainer
    if (container && !renderer && model) {
      const { glb, scale, rotation } = model
      const scW = container.clientWidth
      const scH = container.clientHeight
      const renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true
      })
      renderer.shadowMap.enabled = true
      renderer.physicallyCorrectLights = true
      renderer.outputEncoding = THREE.sRGBEncoding
      renderer.toneMapping = THREE.ACESFilmicToneMapping
      renderer.toneMappingExposure = 1.6
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(scW, scH)
      container.appendChild(renderer.domElement)
      setRenderer(renderer)

      const scene = new THREE.Scene()
      const camera = new THREE.PerspectiveCamera(
        60,
        container.clientWidth / container.clientHeight,
        0.01,
        1000
      )
      camera.position.z = 1
      const controls = new OrbitControls(camera, renderer.domElement)
      controls.autoRotate = false
      controls.screenSpacePanning = true

      // const environment = new RoomEnvironment()
      const pmremGenerator = new THREE.PMREMGenerator(renderer)
      pmremGenerator.compileEquirectangularShader()
      // scene.environment = pmremGenerator.fromScene(environment).texture
      const getCubeMapTexture = () => {
        return new Promise((resolve, reject) => {
          new THREE.TextureLoader().load(
            textureUrl,
            texture => {
              const envMap = pmremGenerator.fromEquirectangular(texture).texture
              pmremGenerator.dispose()

              resolve({ envMap })
            },
            undefined,
            reject
          )
        })
      }
      getCubeMapTexture().then((texture: unknown) => {
        const envMap = _.get(texture, 'envMap')
        scene.environment = envMap
        scene.background = envMap
        loadGLTFModel(scene, glb).then(object => {
          if (scale) {
            object.scale.set(scale, scale, scale)
          }
          if (rotation) {
            const q = new THREE.Quaternion().setFromEuler(
              new THREE.Euler(
                (rotation[0] * Math.PI) / 180,
                (rotation[1] * Math.PI) / 180,
                ((rotation[2] || 0) * Math.PI) / 180,
                'YXZ'
              )
            )
            object.quaternion.set(q.x, q.y, q.z, q.w)
          }
          const box = new THREE.Box3().setFromObject(object)
          const size = box.getSize(new THREE.Vector3()).length()
          const center = box.getCenter(new THREE.Vector3())

          controls.reset()

          object.position.x += object.position.x - center.x
          object.position.y += object.position.y - center.y
          object.position.z += object.position.z - center.z
          controls.maxDistance = size * 10
          camera.near = size / 100
          camera.far = size * 100
          camera.updateProjectionMatrix()

          camera.position.copy(center)
          camera.position.x += size / 2.0
          camera.position.y += size / 5.0
          camera.position.z += size / 1.2
          camera.lookAt(center)

          setLoading(false)
          animate()
        })
      })

      const animate = () => {
        requestAnimationFrame(animate)
        controls.update()
        renderer.render(scene, camera)
      }

      return () => {
        renderer.dispose()
        // _.forEach(lights, light => light.dispose())
      }
    }
  }, [])

  return (
    <Box
      style={{
        width: '100%',
        maxWidth: '100%',
        margin: '0 auto'
      }}
    >
      <Box
        style={{
          width: '512px',
          maxWidth: '100%',
          height: '512px',
          position: 'relative'
        }}
        ref={refContainer}
      >
        {loading && (
          <Box style={{ position: 'absolute', left: '50%', top: '50%' }}>
            <Spinner />
          </Box>
        )}
        {!model && (
          <Box style={{ position: 'absolute', left: '50%', top: '50%' }}>
            <Text>Model not found</Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ModelViewer
