import _ from 'lodash'
import { useEffect, useRef, FunctionComponent } from 'react'

import { DictT } from 'shared/types/model'
import { MpSdk, Scene } from 'shared/bundle/sdk'
import { ComponentInteractionType } from 'shared/components/SceneComponent'

type Props = {
  sdk: MpSdk
  onClick: () => void
}

const SceneClickListener: FunctionComponent<Props> = ({
  sdk,
  onClick
}: Props) => {
  const nodeRef = useRef<MpSdk.Scene.INode | null>(null)
  const subscriptionsRef = useRef<DictT<MpSdk.ISubscription>>({})

  useEffect(() => {
    const run = async () => {
      if (_.isEmpty(nodeRef.current)) {
        const sceneObjs: Array<MpSdk.Scene.IObject> = await sdk.Scene.createObjects(
          1
        )
        const sp: Scene.IObject = sceneObjs[0]
        const node = sp.addNode()
        nodeRef.current = node
        const initial = {
          eventsEnabled: true,
          userNavigationEnabled: false
        }
        const inputComponent: Scene.IComponent = node.addComponent(
          'mp.input',
          initial
        )
        inputComponent.emits = { [ComponentInteractionType.CLICK]: true }
        const evPath = sp.addPath({
          id: 'global_click',
          type: 'emit' as Scene.PathType.EMIT,
          node,
          component: inputComponent,
          property: ComponentInteractionType.CLICK
        })
        const spy = {
          path: evPath,
          onEvent: (e: any) => {
            console.log('global scene click', e)
            setTimeout(onClick, 10)
          }
        }
        const subscription = sp.spyOnEvent(spy)
        subscriptionsRef.current.spy = subscription

        node.start()
      }
    }
    run()
    return () => {
      const node = nodeRef.current
      node?.stop()
      _.forEach(subscriptionsRef.current, s => s.cancel())
    }
  }, [])

  return null
}

export default SceneClickListener
