import { MpSdk } from 'shared/bundle/sdk'

export const needShowItems = (viewMode: MpSdk.Mode.Mode) => {
  return (
    viewMode === 'mode.inside' ||
    viewMode === 'mode.floorplan' ||
    viewMode === 'mode.transitioning' ||
    viewMode === 'mode.dollhouse'
  )
}
