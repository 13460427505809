import { SceneComponent } from 'shared/components/SceneComponent'
import { MpSdk } from 'shared/bundle/sdk'
import {
  HemisphereLight,
  HemisphereLightHelper,
  Object3D,
  Vector3Tuple
} from 'three'

export interface IMHemisphereLight extends Record<string, unknown> {
  position: Vector3Tuple
  intensity: number
  debug: boolean
}

class MHemisphereLight extends SceneComponent {
  private root: Object3D | null = null
  private hemiLight: HemisphereLight | null = null
  private hemiLightHelper: HemisphereLightHelper | null = null

  inputs: IMHemisphereLight = {
    position: [0, 1, 0],
    intensity: 1,
    debug: false
  }

  onInit () {
    const THREE = this.context.three
    this.root = new THREE.Object3D()
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root
    this.hemiLight = new THREE.HemisphereLight(
      0xffffff,
      0xffffff,
      this.inputs.intensity
    )
    this.hemiLight.name = 'hemi_light'
    // this.hemiLight.color.setHSL(0.6, 1, 0.6)
    // this.hemiLight.groundColor.setHSL(0.095, 1, 0.75)

    this.hemiLightHelper = new THREE.HemisphereLightHelper(this.hemiLight, 5)
    this.hemiLight.position.set(0, 1, 0)
    this.hemiLightHelper.visible = this.inputs.debug
    this.root?.add(this.hemiLight)
    this.root?.add(this.hemiLightHelper)
  }

  onInputsUpdated (oldInputs: IMHemisphereLight) {
    if (oldInputs.position !== this.inputs.position && this.hemiLight) {
      // console.log('update hemisphere light position', this.inputs.position)
      this.hemiLight.position.set(
        this.inputs.position[0],
        this.inputs.position[1],
        this.inputs.position[2]
      )
      this.hemiLightHelper?.position.set(
        this.inputs.position[0],
        this.inputs.position[1],
        this.inputs.position[2]
      )
    }
  }

  onDestroy () {
    if (this.hemiLight) {
      this.hemiLight.dispose()
      this.root?.remove(this.hemiLight)
    }
  }
}

export const hemisphereLightType = 'mp.hemisphereLight'
export const makeHemisphereLight = (): MpSdk.Scene.IComponent => {
  return new MHemisphereLight()
}
